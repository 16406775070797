import { useState, useEffect } from "react";
import axios from "axios";
import constants from "../../../constants";
import AssessmentMode from "../components/assessmentMode";
import ESBNComponent from "../components/ESBNcomp";
import PropertyDetailsComponent from "../components/propertyDetails";
import OtherDetailsComponent from "../components/otherDetails";
import Table from "../../../components/Table";
import { formatAmount } from "../../../utils";
import Alert from "../../../components/Alert";
import SuccessModal from "../components/successModal";
import styles1 from "../../MDAs/style.module.css";
import style from "../../WHT/style.module.css";
import anssidStyles from "../../StateTIN/style.module.css";
import styles3 from "../../../Identity-management-individual/style.module.css";
import PAYEstyle from "../../PAYE/PAYE-Assessment/style.module.css";
import docsImg from "../../../assets/docxImg.svg";
import pdfImg from "../../../assets/pdfImg.svg";
import removeImg from "../../../assets/x_red.svg";
import GoBackButton from "../../../components/goBackButton";

const headers = [
	["S/N", "serialNumber"],
	["Type Of CGT ", "TypeOfCGT"],
	["Type Of Property ", "TypeOfProperty"],
	[
		" Aquisition Amount ",
		"AquisitionAmount",
		(value) => `₦${formatAmount(value)}`
	],
	[
		"Current Market Value",
		"CurrentMarketValue",
		(value) => `₦${formatAmount(value)}`
	],
	["Sales Amount", "SalesAmount", (value) => `₦${formatAmount(value)}`],
	["CGTRate", "CGTRate", (value) => `${value}%`],
	["Tax Due", "TaxDue", (value) => `₦${formatAmount(value)}`]
];

const CGTadminAssessment = () => {
	const accessToken = localStorage.getItem("access_token");
	const userInfo = JSON.parse(localStorage.getItem("user_info"));
	const initialState =
		userInfo.account_type === "corporate"
			? {
					filingType: "Unit Filling",
					filingMode: "",
					billMonth: "",
					billYear: "",
					startDate: "",
					endDate: "",
					esbn: userInfo.anssid,
					name: userInfo.fullname || userInfo.corpname,
					email: userInfo.email,
					phone: userInfo.phonenumber,
					address: "",
					sector: "",
					discountReason: "",
					outstandingReason: "",
					remark: ""
				}
			: {
					filingType: "Unit Filling",
					filingMode: "",
					billMonth: "",
					billYear: "",
					startDate: "",
					endDate: "",
					esbn: "",
					name: "",
					email: "",
					phone: "",
					address: "",
					sector: "",
					discountReason: "",
					outstandingReason: "",
					remark: ""
				};
	const [companyDetails, setCompanyDetails] = useState(initialState);
	const [sectors, setSectors] = useState([]);
	const [soldProperties, setSoldProperties] = useState([]);
	const [setupOptions, setSetupOptions] = useState([]);
	const [isEditing, setIsEditing] = useState(null);
	const [calculatedAmount, setCalculatedAmount] = useState(0.0);
	const [outstanding, setOutstanding] = useState("");
	const [discount, setDiscount] = useState("");
	const [isCalculated, setIsCalculated] = useState(false);
	const [isCalculateLoading, setIsCalculateLoading] = useState(false);
	const [isGenerateLoading, setIsGenerateLoading] = useState(false);
	const [isBillGenerated, setIsBillGenerated] = useState(false);
	const [isSuccessModal, setIsSuccessModal] = useState(false);
	const [htmlContent, setHtmlContent] = useState(null);
	const [isBillModalShowing, setIsBillModalShowing] = useState(false);
	const [checkingESBNRecord, setCheckingESBNRecord] = useState(false);
	const [typeOfAssessment, setTypeOfAssessment] = useState(true);
	const [isDescriptionRequired, setIsDescriptionRequired] = useState({
		discountReason: false,
		outstandingReason: false
	});
	const [fileLists, setFileLists] = useState([]);

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const [propertyDetails, setPropertyDetails] = useState({
		TypeOfCGT: "",
		CGTRate: "",
		AquisitionAmount: "",
		CurrentMarketValue: "",
		SalesAmount: "",
		Profit: "",
		TypeOfProperty: "",
		PropertyLocation: "",
		PropertyNature: "",
		revenueCode: ""
	});

	const [defaultCGTSetup, setDefaultCGTSetup] = useState({
		CGTRate: "",
		TypeOfCGT: ""
	});

	useEffect(() => {
		const fetchESBNdetails = async (esbn) => {
			setCheckingESBNRecord(true);
			try {
				const response = await axios.get(
					`${constants.BASE_URL_2}/users/anssid?anssid=${esbn}`
				);
				const {
					firstname,
					middlename,
					surname,
					phonenumber,
					phoneNumber,
					email,
					name,
					residentialaddress,
					headOfficeAddress,
					accounttype
				} = response.data.data;

				let fullName = "";
				let contactNumber = "";
				if (accounttype === "individual") {
					fullName = `${firstname} ${middlename} ${surname}`.trim();
					contactNumber = phonenumber;
				} else if (accounttype === "corporate") {
					fullName = name;
					contactNumber = phoneNumber;
				}
				setCompanyDetails((prevValues) => ({
					...prevValues,
					name: fullName,
					phone: contactNumber,
					email: email,
					address: residentialaddress || headOfficeAddress
				}));
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.message
					});
				}
			} finally {
				setCheckingESBNRecord(false);
			}
		};

		if (companyDetails.esbn && companyDetails.esbn.length >= 10) {
			fetchESBNdetails(companyDetails.esbn);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyDetails.esbn]);

	useEffect(() => {
		const economicSector = async () => {
			try {
				const sector = await axios
					.get(`/settings/economicsector`)
					.then((res) => res.data.data);
				setSectors(sector);
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.response?.data?.msg
					});
				}
			}
		};
		economicSector();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const getSetUpList = async () => {
			try {
				const setUpList = await axios.get(
					`${constants.BASE1_URL}/getActiveCGTSetUps`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				);
				const setUp = setUpList.data.data.data;
				setSetupOptions(setUp); // Save the options for the dropdown or other UI

				if (setUp.length > 0) {
					const defaultType = setUp[0]; // Assuming the first item is the default
					setDefaultCGTSetup({
						TypeOfCGT: defaultType?.name || "", // Use appropriate key for "name"
						CGTRate: defaultType?.rate || "",
						revenueCode: defaultType?.revenueCode || ""
					});
				}
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.response?.data?.msg
					});
				}
			}
		};
		getSetUpList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const addPropertyToTable = () => {
		const newProperty = {
			...propertyDetails,
			serialNumber: isEditing || propertyDetails.length + 1,
			CGTRate: defaultCGTSetup.CGTRate,
			TypeOfCGT: defaultCGTSetup.TypeOfCGT,
			revenueCode: defaultCGTSetup.revenueCode,
			TaxDue: calculateTaxDue(
				defaultCGTSetup.CGTRate,
				propertyDetails.SalesAmount
			)
		};
		setSoldProperties((prevValues) => {
			if (isEditing) {
				return prevValues.map((property) =>
					property.serialNumber === isEditing ? newProperty : property
				);
			}

			const updatedList = [...prevValues, newProperty];
			return updatedList.map((properties, index) => ({
				...properties,
				serialNumber: index + 1
			}));
		});

		setTimeout(() => {
			document.getElementById("table").scrollIntoView({
				behavior: "smooth",
				block: "start"
			});
		}, 100);

		setPropertyDetails({
			TypeOfCGT: "",
			CGTRate: "",
			TypeOfProperty: "",
			AquisitionAmount: "",
			CurrentMarketValue: "",
			SalesAmount: "",
			Profit: "",
			PropertyLocation: "",
			PropertyNature: ""
		});
		setIsEditing(null);
	};

	const calculateTaxDue = (rate, profit) => {
		let baseCharge = 150000;
		let taxDue = (parseFloat(rate) / 100) * parseFloat(profit);
		if (taxDue > baseCharge) {
			return taxDue;
		} else {
			return baseCharge;
		}
	};

	const currentBill = soldProperties.reduce(
		(acc, curr) => acc + Number(curr.TaxDue),
		0
	);

	const calculateCGT = async () => {
		setIsCalculateLoading(true);
		try {
			const payload = soldProperties.map((properties) => ({
				...properties,
				AquisitionAmount: Number(properties.AquisitionAmount),
				CurrentMarketValue: Number(properties.CurrentMarketValue),
				SalesAmount: Number(properties.SalesAmount),
				Profit: Number(properties.Profit),
				CGTRate: Number(properties.CGTRate),
				TaxDue: Number(properties.TaxDue)
			}));
			const taxData = payload.map(
				({ revenueCode, id, SN, ...rest }) => rest
			);

			const calculator = await axios.post(
				`${constants.BASE1_URL}/calculateCGTax`,
				{
					otherDetails: {
						outstanding: Number(outstanding),
						discount: Number(discount)
					},

					taxData
				},
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			);
			const { totalFinalTax } = calculator.data.data.summary;
			setCalculatedAmount(totalFinalTax);
			setIsCalculated(true);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setIsCalculateLoading(false);
		}
	};

	const convertFileToBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
			reader.readAsDataURL(file); // Converts file to Base64
		});
	};

	const generateCGT = async () => {
		setIsGenerateLoading(true);
		try {
			const base64Files = await Promise.all(
				fileLists.map((file) =>
					convertFileToBase64(file).then((base64) => ({
						fileName: file.name,
						fileType: file.type,
						File: base64.split(",")[1] // Stripping the metadata part
					}))
				)
			);

			const propertyDetails = soldProperties.map((property) => ({
				TypeOfCGT: property.TypeOfCGT,
				TypeOfProperty: property.TypeOfProperty,
				AquisitionAmount: parseFloat(property.AquisitionAmount),
				CurrentMarketValue: parseFloat(property.CurrentMarketValue),
				CGTRate: parseFloat(property.CGTRate),
				SalesAmount: parseFloat(property.SalesAmount),
				Profit: parseFloat(property.Profit),
				PropertyLocation: property.PropertyLocation,
				PropertyNature: property.PropertyNature,
				RevenueCode: property.revenueCode
			}));

			const response = await axios.post(
				`${constants.BASE1_URL}/createCGTCompanyInfo`,
				{
					companyInfo: {
						filingType: "Unit Assessment",
						filingMode: companyDetails.filingMode,
						billYear: companyDetails.billYear,
						billMonth: companyDetails.billMonth,
						name: companyDetails.name,
						esbn: companyDetails.esbn,
						email: companyDetails.email,
						phone: companyDetails.phone,
						address: companyDetails.address,
						sector: companyDetails.sector,
						startDate: companyDetails.startDate,
						endDate: companyDetails.endDate,
						discountReason: companyDetails.discountReason,
						outstandingReason: companyDetails.outstandingReason,
						remark: companyDetails.remark,
						discount: parseFloat(discount) || 0,
						outstanding: parseFloat(outstanding) || 0
					},
					propertyDetails,
					files: base64Files
				},
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			);
			if (response?.data) {
				const html = response.data;
				setHtmlContent(html);
			}
			setIsBillGenerated(true);
			setIsSuccessModal(true);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setIsGenerateLoading(false);
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const validateAssessmentMode = (details) => {
		const { filingMode, billMonth, billYear, startDate, endDate } = details;

		switch (filingMode) {
			case "MONTHLY":
				return billMonth && billYear;
			case "YEARLY":
				return billYear;
			case "PERIODICALLY":
				return billYear && startDate && endDate;
			default:
				return false; // No mode selected
		}
	};

	const handleFileChange = (e) => {
		const files = Array.from(e.target.files);

		// Check file sizes
		const invalidFiles = files.filter((file) => file.size > 500 * 1024); // 500KB in bytes

		if (invalidFiles.length > 0) {
			setAlert({
				...alert,
				showing: true,
				type: "error",
				message:
					"File exceed the 500KB size limit and will not be uploaded"
			});
			return;
		}
		setFileLists((prevFiles) => [...prevFiles, ...files]);
	};

	return (
		<main>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{userInfo.account_type === "corporate" ? <GoBackButton /> : ""}
			<h3 className={PAYEstyle.header} style={{ marginBottom: "16px" }}>
				Type of Assessment <span style={{ color: "red" }}>*</span>
			</h3>
			<div className={PAYEstyle.radioBtn}>
				<input
					type="radio"
					id="typeOfAssessment"
					checked={typeOfAssessment}
					onChange={(e) => setTypeOfAssessment(e.target.value)}
				/>
				<label htmlFor="typeOfAssessment" style={{ marginTop: "5px" }}>
					Unit Assessment
				</label>
			</div>
			<hr className={style.hr} />
			<AssessmentMode
				assessmentModeOptions={companyDetails}
				setAssessmentMode={setCompanyDetails}
			/>
			<ESBNComponent
				fillerDetails={companyDetails}
				setFillerDetails={setCompanyDetails}
				checkingESBNRecord={checkingESBNRecord}
				sectors={sectors}
			/>

			<PropertyDetailsComponent
				propertyDetails={propertyDetails}
				setPropertyDetails={setPropertyDetails}
				setupOptions={setupOptions}
				addPropertyToTable={addPropertyToTable}
				isEditing={isEditing}
				defaultCGTSetup={defaultCGTSetup}
				setDefaultCGTSetup={setDefaultCGTSetup}
			/>
			<div
				className={styles1.table__div}
				style={{ width: "70vw", marginTop: "48px", overflow: "hidden" }}
			>
				{soldProperties.length > 0 && (
					<>
						<div id="table" className={style.table__wrapper}>
							<Table
								headers={headers}
								data={soldProperties}
								full
								pageSize={10}
								onEdit={(property) => {
									const { serialNumber } = property;
									const editProperty = soldProperties.find(
										(item) =>
											item.serialNumber === serialNumber
									);

									if (editProperty) {
										setPropertyDetails({
											...propertyDetails,
											TypeOfCGT: editProperty.TypeOfCGT,
											CGTRate: editProperty.CGTRate,
											TypeOfProperty:
												editProperty.TypeOfProperty,
											AquisitionAmount:
												editProperty.AquisitionAmount,
											CurrentMarketValue:
												editProperty.CurrentMarketValue,
											SalesAmount:
												editProperty.SalesAmount,
											Profit: editProperty.Profit,
											PropertyLocation:
												editProperty.PropertyLocation,
											PropertyNature:
												editProperty.PropertyNature
										});
										setIsEditing(serialNumber);
									}
									setTimeout(() => {
										document
											.getElementById("propertyDetails")
											.scrollIntoView({
												behavior: "smooth",
												block: "start"
											});
									}, 100);
								}}
								onDelete={(property) => {
									const { serialNumber } = property;
									setSoldProperties((prevDetails) => {
										const updatedDetails =
											prevDetails.filter(
												(exisitngProperty) =>
													exisitngProperty.serialNumber !==
													serialNumber
											);
										return updatedDetails.map(
											(properties, index) => ({
												...properties,
												serialNumber: index + 1
											})
										);
									});
								}}
							/>
						</div>
						<div className={style.AmountWithheld}>
							<h4>Total Capital Gain Tax (CGT) Due</h4>
							<h4>{`₦${formatAmount(currentBill)}`}</h4>
						</div>
						<hr className={style.hr} />
						<OtherDetailsComponent
							companyDetails={companyDetails}
							propertyDetails={propertyDetails}
							setCompanyDetails={setCompanyDetails}
							setPropertyDetails={setPropertyDetails}
							outstanding={outstanding}
							discount={discount}
							setOutstanding={setOutstanding}
							setDiscount={setDiscount}
							isDescriptionRequired={isDescriptionRequired}
							setIsDescriptionRequired={setIsDescriptionRequired}
						/>
						{/* document upload section */}
						<section>
							<input
								type="file"
								id="fileInput"
								multiple
								style={{ display: "none" }}
								onChange={handleFileChange}
							/>
							<label
								htmlFor="fileInput"
								className={style.invisibleFileLabel}
							>
								+
								<span
									style={{
										marginLeft: "5px",
										textDecoration: "underline"
									}}
								>
									Attach document e.g. Deed of Assignment,
									Power of Attorney, or Receipts of
									Purchase/Sales (Max file size: 500KB)
								</span>
							</label>
							<div>
								{fileLists.map((file, index) => {
									const fileExtension = file.name
										.split(".")
										.pop()
										.toLowerCase();

									const logo =
										fileExtension === "pdf"
											? pdfImg
											: docsImg;

									const handleRemoveFile = (
										indexToRemove
									) => {
										const updateFileList = fileLists.filter(
											(_, index) =>
												index !== indexToRemove
										);
										setFileLists(updateFileList);
									};
									return (
										<div
											key={index}
											className={style.fileLists}
										>
											<div>
												<img
													src={logo}
													alt="Document Logo"
												/>
												<span key={index}>
													{file.name}
												</span>
											</div>
											<button
												onClick={() =>
													handleRemoveFile(index)
												}
												style={{
													cursor: "pointer",
													border: "none",
													background: "none"
												}}
											>
												<img
													src={removeImg}
													alt="Remove Logo"
												/>
											</button>
										</div>
									);
								})}
							</div>
						</section>
						<hr className={style.hr} />
						<div className={style.colationSection}>
							<h4>
								Total Capital Gain Tax (CGT) Due:&nbsp;
								{`₦${formatAmount(currentBill)}`}
							</h4>
							<h4>
								Discount Amount:&nbsp;
								{`₦${formatAmount(discount)}`}
							</h4>
							<h4>
								Outstanding Amount:&nbsp;
								{`₦${formatAmount(outstanding)}`}
							</h4>
						</div>

						<form className={styles3.columned__form}>
							<hr className={style.hr} />
							{isCalculated && (
								<h2 style={{ marginTop: "48px" }}>
									Total CGT Amount:
									{` ₦${formatAmount(calculatedAmount)}`}
								</h2>
							)}

							{!isCalculated ? (
								<div>
									<button
										type="button"
										className={`primary__btn ${anssidStyles.create__btn}`}
										style={{
											float: "right",
											marginTop: "32px",
											marginBottom: "222px"
										}}
										onClick={calculateCGT}
										disabled={
											isCalculateLoading ||
											soldProperties.length === 0 ||
											!(
												companyDetails.esbn &&
												companyDetails.name &&
												companyDetails.email &&
												companyDetails.phone &&
												companyDetails.address &&
												companyDetails.sector &&
												validateAssessmentMode(
													companyDetails
												)
											) ||
											(discount &&
												!companyDetails.discountReason) ||
											(outstanding &&
												!companyDetails.outstandingReason)
										}
									>
										{isCalculateLoading
											? "Calculating..."
											: "Calculate Tax"}
									</button>
								</div>
							) : (
								<div>
									<button
										type="button"
										className={`primary__btn ${anssidStyles.create__btn}`}
										style={{
											float: "right",
											marginTop: "32px",
											marginBottom: "222px"
										}}
										onClick={generateCGT}
										disabled={
											isBillGenerated || isGenerateLoading
										}
									>
										{isBillGenerated
											? "Generated"
											: isGenerateLoading
												? "Generating Bill..."
												: "Generate Bill"}
									</button>
								</div>
							)}
						</form>
						<SuccessModal
							isSuccessModal={isSuccessModal}
							setIsSuccessModal={setIsSuccessModal}
							isBillModalShowing={isBillModalShowing}
							setIsBillModalShowing={setIsBillModalShowing}
							htmlContent={htmlContent}
						/>
					</>
				)}
			</div>
		</main>
	);
};

export default CGTadminAssessment;
