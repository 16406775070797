import { useState, useEffect, createRef } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import constants from "../../../constants";
import {
	formatAmount,
	formatDate,
	getStatusText,
	exportExcel,
	remapObj
} from "../../../utils";
import Table from "../../../components/Table";
import Alert from "../../../components/Alert";
import filterStyles from "../../MDAs/style.module.css";

const headers = [
	[`ESBN`, "esbn"],
	["Name ", "name"],
	["Bill Number", "billNumber"],
	["Assessment Type", "filingType"],
	["Assessment Mode", "filingMode"],
	[
		"Total Aquisition Amount ",
		"totalAquisitionAmount",
		(value) => `₦${formatAmount(value)}`
	],
	[
		"Total Current Market Value",
		"totalCurrentMarketValue",
		(value) => `₦${formatAmount(value)}`
	],
	[
		"Total Sales Amount",
		"totalSalesAmount",
		(value) => `₦${formatAmount(value)}`
	],
	["Total Profit", "totalProfit", (value) => `₦${formatAmount(value)}`],
	[
		"Total Amount Billed",
		"totalAmountBilled",
		(value) => `₦${formatAmount(value)}`
	],
	["Created by", "generatedBy"],
	["Generated by", "inputter"],
	["Creation Date ", "inputterTime", formatDate],
	["Payment status", "paymentStatus"],
	["Process status", "approveFlg", getStatusText]
];
const CGTauthorisation = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [esbn, setESBN] = useState("");
	const [name, setName] = useState("");
	const [billNumber, setBillNumber] = useState("");
	const [total, setTotal] = useState(0);
	const [data, setData] = useState([]);
	const [paymentStatus, setPaymentStatus] = useState("");
	const [processStatus, setProcessStatus] = useState("");
	const [minAmount, setMinAmount] = useState("");
	const [maxAmount, setMaxAmount] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [assessmentMode, setAssessmentMode] = useState("");
	const [generatedBy, setGeneratedBy] = useState("");
	const [createdBy, setCreatedBy] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);
	const filterRef = createRef();
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const CGT =
		({ currentPage = 1, pageSize = 20, download = false } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);
			if (
				!filterRef.current?.classList.contains(filterStyles.no__display)
			) {
				filterRef.current?.classList.toggle(filterStyles.no__display);
			}

			let query = `page=${currentPage}&pageSize=${pageSize}&`;
			if (esbn) {
				query += `esbn=${esbn}&`;
			}

			if (name) {
				query += `name=${name}&`;
			}

			if (billNumber) {
				query += `billNumber=${billNumber}&`;
			}

			if (processStatus) {
				query += `processStatus=${processStatus}&`;
			}

			if (paymentStatus) {
				query += `paymentStatus=${paymentStatus}&`;
			}

			if (generatedBy) {
				query += `generatedBy=${generatedBy}&`;
			}

			if (startDate) {
				query += `startDate=${startDate}&`;
			}
			if (endDate) {
				query += `endDate=${endDate}&`;
			}
			if (minAmount) {
				query += `minAmount=${minAmount}&`;
			}
			if (maxAmount) {
				query += `maxAmount=${maxAmount}&`;
			}

			try {
				const accessToken = localStorage.getItem("access_token");
				let { data, pagination } = await axios
					.get(
						`${constants.BASE1_URL}/getCGTauthorizerView?${query}`,
						{
							headers: {
								Authorization: `Bearer ${accessToken}`
							}
						}
					)
					.then((res) => res.data || { data: {} });

				setData(data);
				setTotal(pagination.totalRecords);
				return data;
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.response.data?.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.message
					});
				}
			} finally {
				setLoading(false);
			}
		};

	useEffect(() => {
		CGT()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const clearFilters = () => {
		setESBN("");
		setName("");
		setBillNumber("");
		setGeneratedBy("");
		setPaymentStatus("");
		setProcessStatus("");
		setMinAmount("");
		setMaxAmount("");
		setStartDate("");
		setEndDate("");
		setAssessmentMode("");

		filterRef.current.classList.toggle(filterStyles.no__display);
		setFiltersCleared(true);
	};

	useEffect(() => {
		CGT()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const exportAnalytics = async () => {
		const info = await CGT({
			currentPage: 1,
			pageSize: total,
			download: true
		})();
		exportExcel(remapObj(info, headers), headers, `CGT Report.xlsx`);
	};

	return (
		<main>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<div className={filterStyles.actions__div}>
				<button
					className={[
						"secondary__btn",
						filterStyles.download__button
					].join(" ")}
					onClick={() => exportAnalytics()}
				>
					Download Report
				</button>
				<div className={filterStyles.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								filterStyles.no__display
							);
						}}
						className={filterStyles.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							filterStyles.filter__div,
							filterStyles.no__display
						].join(" ")}
					>
						<div className={filterStyles.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										filterStyles.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={filterStyles.clear__filter_fields}
							onClick={clearFilters}
							disabled={
								!esbn &&
								!name &&
								!billNumber &&
								!paymentStatus &&
								!processStatus &&
								!generatedBy &&
								!createdBy &&
								!minAmount &&
								!maxAmount &&
								!startDate &&
								!endDate &&
								!assessmentMode
							}
						>
							Clear Filter fields
						</button>
						<p>By Code</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="ESBN">ESBN</label>
								<input
									name="ESBN"
									id="ESBN"
									type="text"
									value={esbn}
									onChange={(e) => setESBN(e.target.value)}
									placeholder="Enter ESBN"
								/>
							</div>
							<div>
								<label htmlFor="BillNumber">Bill Number</label>
								<input
									name="BillNumber"
									id="BillNumber"
									type="text"
									value={billNumber}
									onChange={(e) =>
										setBillNumber(e.target.value)
									}
									placeholder="Eg.12345678"
								/>
							</div>
						</form>
						<p>By Name</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="name">Tax Payer Name</label>
								<input
									name="name"
									id="name"
									type="text"
									value={name}
									onChange={(e) => setName(e.target.value)}
									placeholder="ABC Nig. Ltd "
								/>
							</div>
							<div>
								<label htmlFor="createdBy">Created By </label>
								<input
									name="createdBy"
									id="createdBy"
									type="text"
									value={createdBy}
									onChange={(e) =>
										setCreatedBy(e.target.value)
									}
									placeholder="abc@gmail.com"
								/>
							</div>
						</form>
						<p>By Mode</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="assessmentMode">
									Assessment Mode
								</label>
								<select
									name="assessmentMode"
									id="assessmentMode"
									value={assessmentMode}
									onChange={(e) =>
										setAssessmentMode(e.target.value)
									}
								>
									<option value="">Select</option>
									<option>MONTHLY</option>
									<option>YEARLY</option>
									<option>PERIODICALLY</option>
								</select>
							</div>
							<div>
								<label htmlFor="generatedBy">
									Generated By
								</label>
								<select
									name="generatedBy"
									id="generatedBy"
									value={generatedBy}
									onChange={(e) =>
										setGeneratedBy(e.target.value)
									}
								>
									<option value="">Select</option>
									<option>admin</option>
									<option>corporate</option>
								</select>
							</div>
						</form>
						<p>By Amount Paid</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="minAmountPaid">
									Min Amount{" "}
								</label>
								<input
									name=""
									id="minAmountPaid"
									type="number"
									value={minAmount}
									onChange={(e) =>
										setMinAmount(e.target.value)
									}
									placeholder="Eg.100,000"
								/>
							</div>
							<div>
								<label htmlFor="maxAmountPaid">
									Max Amount
								</label>
								<input
									name="	"
									id="maxAmountPaid"
									type="number"
									value={maxAmount}
									onChange={(e) =>
										setMaxAmount(e.target.value)
									}
									placeholder="Eg.200,000"
								/>
							</div>
						</form>
						<p>By Creation Date</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="start_date">Start Date</label>
								<input
									name="start_date"
									id="start_date"
									type="date"
									value={startDate}
									onChange={(e) =>
										setStartDate(e.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="start_date">End Date</label>
								<input
									name="end_date"
									id="end_date"
									type="date"
									value={endDate}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
						</form>
						<button
							className={[
								filterStyles.submit__button,
								"primary__btn"
							].join(" ")}
							onClick={CGT()}
						>
							Search
						</button>
					</div>
				</div>
			</div>

			<div className={filterStyles.table__div} style={{ margin: "0px" }}>
				<Table
					headers={headers}
					data={data}
					full
					pageSize={20}
					loading={loading}
					total={total}
					onPageChanged={CGT}
					onView={(data) => {
						history.push(`/capital-gain-tax/authorisation/view`, {
							...data,
							view: true
						});
					}}
				/>
				<div></div>
			</div>
		</main>
	);
};

export default CGTauthorisation;
