import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import Alert from "../../../components/Alert";
import constants from "../../../constants";
import Modal from "../../../components/Modal/regular";
import Table from "../../../components/Table";
import PAYEstyle from "../../PAYE/PAYE-Assessment/style.module.css";
import style from "../style.module.css";
import anssidStyles from "../../StateTIN/style.module.css";
import styles3 from "../../../Identity-management-individual/style.module.css";
import styles1 from "../../MDAs/style.module.css";
import docsImg from "../../../assets/docxImg.svg";
import pdfImg from "../../../assets/pdfImg.svg";
import removeImg from "../../../assets/x_red.svg";
import successCheck from "../../../assets/Certificate/approvedCheck.svg";
import closeBlackCircleBtn from "../../../assets/x-circle-black.svg";
import { formatAmount } from "../../../utils";
import GoBackButton from "../../../components/goBackButton";

const headers = [
	["S/N", "serialNumber"],
	["Contractor Name", "Contractor_Name"],
	["Contractor ESBN", "Contractor_ESBN"],
	["Contractual Sum Agreed (#)", "Sum", (value) => `₦${formatAmount(value)}`],
	["Tariff/Rate", "Rate"],
	["Start Date", "StartDate"],
	["End Date", "EndDate"],
	["Payment Date", "PaymentDate"],
	["Tax Due (#)", "TotalAmtBilled", (value) => `₦${formatAmount(value)}`]
];

const WHTCorporateAssessment = () => {
	const [fileLists, setFileLists] = useState([]);
	const [setupOptions, setSetupOptions] = useState([]);
	const [calculatedAmount, setCalculatedAmount] = useState(0.0);
	const [isCalculated, setIsCalculated] = useState(false);
	const [calculateLoading, setCalculateLoading] = useState(false);
	const [generateLoading, setGenerateLoading] = useState(false);
	const [isSuccessModal, setIsSuccessModal] = useState(false);
	const [showingBill, setShowingBill] = useState(false);
	const [generatedBill, setGeneratedBill] = useState(null);
	const [generating, setGenerating] = useState(false);
	const [createdFile, setCreatedFile] = useState(false);
	const [sectors, setSectors] = useState([]);
	const [contractorDetails, setContractorDetails] = useState([]);
	const [isBillGenerated, setIsBillGenerated] = useState(false);
	const [editing, setEditing] = useState(null);
	const [checkingESBNRecord, setCheckingESBNRecord] = useState(false);
	const [typeOfAssessment, setTypeOfAssessment] = useState(true);
	const [isDescriptionRequired, setIsDescriptionRequired] = useState({
		Discount_Info: false,
		Outstanding_Info: false
	});
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const accessToken = localStorage.getItem("access_token");
	const userInfo = JSON.parse(localStorage.getItem("user_info"));
	const history = useHistory();

	const [formValues, setFormValues] = useState({
		Filling_Type: "unit",
		Employer_ESBN: "",
		Employer_Name: "",
		Employer_PhoneNumber: "",
		Employer_Email: "",
		Employer_Address: "",
		Contractor_ESBN: "",
		Contractor_Name: "",
		Contractor_PhoneNumber: "",
		Contractor_Email: "",
		Contractor_Address: "",
		Sum: "",
		Rate: "",
		RevenueCode: "",
		StartDate: "",
		EndDate: "",
		PaymentDate: "",
		Contract_Type: "",
		Discount_Amount: "",
		Discount_Info: "",
		Reason: "",
		Sector: "",
		Service_Type: userInfo.account_type,
		Bulk: "",
		AssessmentMode: "",
		AssessmentYear: "",
		AssessmentMonth: "",
		AssessmentStartPeriod: "",
		AssessmentEndPeriod: "",
		Documents: []
	});

	const addContractToTable = () => {
		const calculateAmountWithheld = (sum, rate) => {
			return (parseFloat(rate) / 100) * parseFloat(sum);
		};

		const newContract = {
			serialNumber: editing || contractorDetails.length + 1,
			Contractor_Name: formValues.Contractor_Name,
			Contractor_ESBN: formValues.Contractor_ESBN,
			Sum: formValues.Sum,
			Rate: formValues.Rate,
			StartDate: formValues.StartDate,
			EndDate: formValues.EndDate,
			PaymentDate: formValues.PaymentDate,
			Contract_Type: formValues.Contract_Type,
			TotalAmtBilled: calculateAmountWithheld(
				formValues.Sum,
				formValues.Rate
			),
			Contractor_Address: formValues.Contractor_Address,
			Contractor_PhoneNumber: formValues.Contractor_PhoneNumber,
			Contractor_Email: formValues.Contractor_Email,
			RevenueCode: formValues.RevenueCode
		};

		setContractorDetails((prevDetails) => {
			if (editing) {
				return prevDetails.map((contract) =>
					contract.serialNumber === editing ? newContract : contract
				);
			}

			const updatedList = [...prevDetails, newContract];
			return updatedList.map((contract, index) => ({
				...contract,
				serialNumber: index + 1
			}));
		});

		setTimeout(() => {
			document.getElementById("table").scrollIntoView({
				behavior: "smooth",
				block: "start"
			});
		});

		// Clear the form after saving
		setFormValues((prev) => ({
			...prev,
			Contractor_Name: "",
			Contractor_ESBN: "",
			Contractor_PhoneNumber: "",
			Contractor_Email: "",
			Contractor_Address: "",
			Contract_Type: "",
			Sum: "",
			Rate: "",
			StartDate: "",
			EndDate: "",
			PaymentDate: ""
		}));
		setEditing(null);
	};

	const totalBilled = contractorDetails.reduce(
		(acc, curr) => acc + Number(curr.TotalAmtBilled || 0),
		0
	);

	useEffect(() => {
		const fetchCoyESBNDetails = async () => {
			try {
				const response = await axios
					.get(
						`${constants.BASE_URL_2}/users/anssid?anssid=${userInfo.anssid}`
					)
					.then((res) => res.data.data);
				setFormValues((prevValues) => ({
					...prevValues,
					Employer_ESBN: userInfo.anssid,
					Employer_Name: response.name,
					Employer_PhoneNumber: response.phoneNumber,
					Employer_Email: response.email,
					Employer_Address: response.stateOfficeAddress
				}));
			} catch (error) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg || "An error occurred."
				});
			}
		};

		if (userInfo?.anssid) {
			fetchCoyESBNDetails();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const economicSector = async () => {
			try {
				const sector = await axios
					.get(`/settings/economicsector`)
					.then((res) => res.data.data);
				setSectors(sector);
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.response?.data?.msg
					});
				}
			}
		};
		economicSector();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const fetchESBNdetails = async (ESBN) => {
			setCheckingESBNRecord(true);
			try {
				const response = await axios.get(
					`${constants.BASE_URL_2}/users/anssid?anssid=${ESBN}`
				);
				const {
					firstname,
					middlename,
					surname,
					phonenumber,
					phoneNumber,
					email,
					name,
					residentialaddress,
					headOfficeAddress,
					accounttype
				} = response.data.data;

				let fullName = "";
				let contactNumber = "";
				if (accounttype === "individual") {
					fullName = `${firstname} ${middlename} ${surname}`.trim();
					contactNumber = phonenumber;
				} else if (accounttype === "corporate") {
					fullName = name;
					contactNumber = phoneNumber;
				}
				setFormValues((prevValues) => ({
					...prevValues,
					Contractor_Name: fullName,
					Contractor_PhoneNumber: contactNumber,
					Contractor_Email: email,
					Contractor_Address: residentialaddress || headOfficeAddress
				}));
			} catch (error) {
				console.error(
					"Error fetching ESBN details:",
					error.response || error
				);
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg || "An error occurred."
				});
			} finally {
				setCheckingESBNRecord(false);
			}
		};

		if (formValues.Contractor_ESBN.length >= 10) {
			fetchESBNdetails(formValues.Contractor_ESBN);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formValues.Contractor_ESBN]);

	useEffect(() => {
		const getSetUpList = async () => {
			try {
				const setUpList = await axios.get(
					`${constants.BASE_URL_2}/withholding/setup/list`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				);
				setSetupOptions(setUpList.data.data);
			} catch (error) {
				console.error("Error while getting the set up list", error);
			}
		};
		getSetUpList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const CalculateWHT = async () => {
		setCalculateLoading(true);
		const getRateandSum = contractorDetails.map((prev) => {
			const numericRate = prev.Rate.split("%")[0].trim();
			return {
				Sum: prev.Sum,
				Rate: numericRate
			};
		});
		try {
			const calculator = await axios.post(
				`${constants.BASE_URL_2}/withholding/calculate`,
				{
					Discount_Amount: parseFloat(
						formValues.Discount_Amount.trim() || "0"
					),
					Outstanding_Amount: 0,
					data: getRateandSum
				},
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			);
			setCalculatedAmount(calculator.data.data);
			setIsCalculated(true);
		} catch (error) {
			console.error("Error calculating WHT", error);
			setIsCalculated(false);
		} finally {
			setCalculateLoading(false);
		}
	};

	const createWHT = async () => {
		setGenerateLoading(true);
		const formData = new FormData();

		const contractors = contractorDetails.map((contractors) => {
			const { Rate } = contractors;
			const [numericRate] = Rate.split("%");
			return {
				...contractors,
				Rate: parseInt(numericRate),
				TariffDescription: Rate
			};
		});

		const sanitizedContractors = contractors.map(
			({ TotalAmtBilled, id, serialNumber, ...rest }) => rest
		);

		formData.append("Contractors", JSON.stringify(sanitizedContractors));
		// Append other general details from formValues to formData
		const generalDetails = {
			Filling_Type: "unit",
			Employer_ESBN: formValues.Employer_ESBN,
			Employer_Name: formValues.Employer_Name,
			Employer_PhoneNumber: formValues.Employer_PhoneNumber,
			Employer_Email: formValues.Employer_Email,
			Employer_Address: formValues.Employer_Address,
			Discount_Amount: parseFloat(
				formValues.Discount_Amount.trim() || "0"
			),
			Discount_Info: formValues.Discount_Info,
			Outstanding_Amount: 0,
			Outstanding_Info: "",
			Sector: formValues.Sector,
			AssessmentMode: formValues.AssessmentMode,
			...(formValues.AssessmentMode === "MONTHLY" && {
				AssessmentYear: formValues.AssessmentYear,
				AssessmentMonth: formValues.AssessmentMonth
			}),
			...(formValues.AssessmentMode === "YEARLY" && {
				AssessmentYear: formValues.AssessmentYear
			}),
			...(formValues.AssessmentMode === "PERIODICALLY" && {
				AssessmentStartPeriod: formValues.AssessmentStartPeriod,
				AssessmentEndPeriod: formValues.AssessmentEndPeriod
			})
		};

		Object.keys(generalDetails).forEach((key) => {
			formData.append(key, generalDetails[key]);
		});

		// Append each file in the Documents array
		formValues.Documents.forEach((file, index) => {
			formData.append(`Document${index + 1}`, file);
		});

		try {
			const create = await axios.post(
				`${constants.BASE_URL_2}/withholding/create`,
				formData,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			);
			setCreatedFile(create.data.data);
			setIsSuccessModal(true);
			setIsBillGenerated(true);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.data.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		} finally {
			setGenerateLoading(false);
		}
	};

	const generateBill = async () => {
		setGenerating(true);
		try {
			const bill = await axios
				.get(
					`${constants.BASE_URL_2}/withholding/receipt/${createdFile.employerInfo.id}`
				)
				.then((res) => res.data);
			setGeneratedBill(bill);
			setShowingBill(true);
		} catch (error) {
		} finally {
			setGenerating(false);
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const handleModeChange = (selectedMode) => {
		setFormValues((prev) => ({
			...prev,
			AssessmentMode: selectedMode,
			AssessmentYear: "",
			AssessmentMonth: "",
			AssessmentStartPeriod: "",
			AssessmentEndPeriod: ""
		}));
	};

	// Event handler to update form values
	const handleChange = (e) => {
		const { id, value } = e.target;
		setFormValues((prevValues) => ({
			...prevValues,
			[id]: value
		}));
		if (id === "Rate") {
			const selectedOption = setupOptions.find(
				(option) => option.Name === value
			);
			setFormValues((prevValues) => ({
				...prevValues,
				RevenueCode: selectedOption ? selectedOption.Code : ""
			}));
		}

		if (id === "Discount_Amount" || id === "Outstanding_Amount") {
			const key =
				id === "Discount_Amount" ? "Discount_Info" : "Outstanding_Info";
			setIsDescriptionRequired((prev) => ({
				...prev,
				[key]: value.trim() !== ""
			}));
		}
	};

	const handleFileChange = (e) => {
		const files = Array.from(e.target.files);
		// Check file sizes
		const invalidFiles = files.filter((file) => file.size > 500 * 1024); // 500KB in bytes

		if (invalidFiles.length > 0) {
			setAlert({
				...alert,
				showing: true,
				type: "error",
				message:
					"File exceed the 500KB size limit and will not be uploaded"
			});
			return;
		}

		setFormValues((prevValues) => ({
			...prevValues,
			Documents: [...prevValues.Documents, ...files] // Add files to Documents array
		}));
		setFileLists((prevFiles) => [...prevFiles, ...files]);
	};

	return (
		<main>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<GoBackButton />
			<h3 className={PAYEstyle.header} style={{ marginBottom: "16px" }}>
				Type of Assessment <span style={{ color: "red" }}>*</span>
			</h3>
			<div className={`${PAYEstyle.radioBtns}`}>
				<input
					type="radio"
					id="typeOfAssessment"
					checked={typeOfAssessment}
					onChange={(e) => setTypeOfAssessment(e.target.value)}
				/>
				<label htmlFor="typeOfAssessment" style={{ marginTop: "5px" }}>
					Unit Assessment
				</label>
			</div>
			<hr className={style.hr} />
			{/* ///////////Mode of assessment radioBtns////////  */}
			<h3 className={PAYEstyle.header} style={{ marginBottom: "16px" }}>
				Mode of Assessment &nbsp;
				<span style={{ color: "red" }}> * </span>
			</h3>
			<div className={`${PAYEstyle.radioBtns} ${style.whtRadio}`}>
				<div className={PAYEstyle.radioBtn}>
					<input
						type="radio"
						id="MONTHLY"
						value="MONTHLY"
						checked={formValues.AssessmentMode === "MONTHLY"}
						onChange={() => handleModeChange("MONTHLY")}
					/>
					<label htmlFor="MONTHLY" required>
						Monthly{" "}
					</label>
				</div>
				<div className={PAYEstyle.radioBtn}>
					<input
						type="radio"
						id="YEARLY"
						value="YEARLY"
						checked={formValues.AssessmentMode === "YEARLY"}
						onChange={() => handleModeChange("YEARLY")}
					/>
					<label htmlFor="YEARLY" required>
						Yearly{" "}
					</label>
				</div>
				<div className={PAYEstyle.radioBtn}>
					<input
						type="radio"
						id="PERIODICALLY"
						value="PERIODICALLY"
						checked={formValues.AssessmentMode === "PERIODICALLY"}
						onChange={() => handleModeChange("PERIODICALLY")}
					/>
					<label htmlFor="PERIODICALLY" required>
						Period{" "}
					</label>
				</div>
			</div>
			{/* ///////////Mode of assessment inputs////////  */}
			<form className={styles3.columned__form}>
				{formValues.AssessmentMode === "MONTHLY" && (
					<div
						className={`${styles3.double__inputs} ${style.assessmentInput}`}
					>
						<div>
							<label
								htmlFor="AssessmentYear"
								className={styles3.input__label}
							>
								Bill Year (Fiscal Year)
								<span style={{ color: "red" }}> * </span>
							</label>
							<select
								id="AssessmentYear"
								value={formValues.AssessmentYear}
								onChange={handleChange}
								required
								style={{
									marginBottom: "0"
								}}
							>
								<option value="">Select year</option>
								{new Array(10).fill(0).map((_, i) => {
									const year = new Date().getFullYear() - i;
									return (
										<option key={year} value={year}>
											{year}
										</option>
									);
								})}
							</select>
						</div>
						<div>
							<label
								htmlFor="AssessmentMonth"
								className={styles3.input__label}
							>
								Month of Assessment
								<span style={{ color: "red" }}> * </span>
							</label>
							<select
								id="AssessmentMonth"
								value={formValues.AssessmentMonth}
								onChange={handleChange}
								style={{
									marginBottom: "0"
								}}
							>
								<option>select Month</option>
								{[...Array(12)].map((_, index) => (
									<option
										key={index}
										value={new Intl.DateTimeFormat("en", {
											month: "long"
										}).format(new Date(2000, index, 1))}
									>
										{new Intl.DateTimeFormat("en", {
											month: "long"
										}).format(new Date(2000, index, 1))}
									</option>
								))}
							</select>
						</div>
					</div>
				)}
				{/* /////////YEARLY//////// */}
				{formValues.AssessmentMode === "YEARLY" && (
					<div
						className={`${styles3.double__inputs} ${style.assessmentInput}`}
					>
						<div>
							<label
								htmlFor="AssessmentYear"
								className={styles3.input__label}
							>
								Bill Year (Fiscal Year)
								<span style={{ color: "red" }}> * </span>
							</label>
							<select
								id="AssessmentYear"
								value={formValues.AssessmentYear}
								onChange={handleChange}
								required
								style={{
									marginBottom: "0"
								}}
							>
								<option value="">Select year</option>
								{new Array(10).fill(0).map((_, i) => {
									const year = new Date().getFullYear() - i;
									return (
										<option key={year} value={year}>
											{year}
										</option>
									);
								})}
							</select>
						</div>
					</div>
				)}
				{/* /////////PERIODICALLY//////// */}
				{formValues.AssessmentMode === "PERIODICALLY" && (
					<>
						<div
							className={`${styles3.double__inputs} ${style.assessmentInput}`}
						>
							<div>
								<label
									htmlFor="AssessmentYear"
									className={styles3.input__label}
								>
									Bill Year (Fiscal Year)
									<span style={{ color: "red" }}> * </span>
								</label>
								<select
									id="AssessmentYear"
									value={formValues.AssessmentYear}
									onChange={handleChange}
									required
								>
									<option value="">Select year</option>
									{new Array(10).fill(0).map((_, i) => {
										const year =
											new Date().getFullYear() - i;
										return (
											<option key={year} value={year}>
												{year}
											</option>
										);
									})}
								</select>
							</div>
						</div>
						<div className={`${styles3.double__inputs}`}>
							<div>
								<label
									htmlFor="AssessmentStartPeriod"
									className={styles3.input__label}
								>
									Start Month of Assessment
									<span style={{ color: "red" }}> * </span>
								</label>
								<input
									type="date"
									id="AssessmentStartPeriod"
									value={formValues.AssessmentStartPeriod}
									onChange={handleChange}
									style={{
										marginBottom: "0"
									}}
								/>
							</div>
							<div>
								<label
									htmlFor="AssessmentEndPeriod"
									className={styles3.input__label}
								>
									End Month of Assessment
									<span style={{ color: "red" }}> * </span>
								</label>
								<input
									type="date"
									id="AssessmentEndPeriod"
									value={formValues.AssessmentEndPeriod}
									onChange={handleChange}
									style={{
										marginBottom: "0"
									}}
								/>
							</div>
						</div>
					</>
				)}
			</form>
			<hr className={style.hr} />
			<form
				onSubmit={(e) => {
					e.preventDefault();
					createWHT();
				}}
				className={styles3.columned__form}
			>
				<section>
					<h3 className={style.headerInfo}>Employer’s Information</h3>
					<div className={styles3.double__inputs}>
						<div>
							<label htmlFor="Employer_ESBN">
								Employer ESBN{" "}
								<span style={{ color: "red" }}> * </span>
							</label>
							<input
								id="Employer_ESBN"
								value={formValues.Employer_ESBN}
								onChange={handleChange}
								placeholder="eg. 54826648633"
								required
								disabled
							/>
						</div>
						<div>
							<label htmlFor="Employer_Name">
								Employer Name{" "}
								<span style={{ color: "red" }}> * </span>
							</label>
							<input
								id="Employer_Name"
								type="text"
								value={formValues.Employer_Name}
								onChange={handleChange}
								placeholder="Don Joe"
								required
								disabled
							/>
						</div>
					</div>
					<div className={styles3.double__inputs}>
						<div>
							<label htmlFor="PhoneNumber">
								Phone Number{" "}
								<span style={{ color: "red" }}> * </span>
							</label>
							<input
								type="text"
								id="Employer_PhoneNumber"
								value={formValues.Employer_PhoneNumber}
								onChange={handleChange}
								placeholder="08140589302"
								required
							/>
						</div>
						<div>
							<label htmlFor="email">
								Email <span style={{ color: "red" }}> *</span>
							</label>
							<input
								type="email"
								id="Employer_Email"
								value={formValues.Employer_Email}
								onChange={handleChange}
								placeholder="chukwuebuka@gmail.com"
								required
							/>
						</div>
					</div>
					<div className={styles3.double__inputs}>
						<div>
							<label htmlFor="Sector">
								Sector <span style={{ color: "red" }}> * </span>
							</label>
							<select
								id="Sector"
								value={formValues.Sector}
								onChange={handleChange}
								style={{
									marginBottom: "0"
								}}
							>
								<option>Select sector</option>
								{sectors.map((sec) => (
									<option key={sec.id} value={sec.Sector}>
										{sec.Description}
									</option>
								))}
							</select>
						</div>
						<div>
							<label htmlFor="address">
								Address{" "}
								<span style={{ color: "red" }}> * </span>
							</label>
							<input
								id="Employer_Address"
								type="text"
								value={formValues.Employer_Address}
								onChange={handleChange}
								placeholder="Igbo akuku"
								required
								style={{
									marginBottom: "0"
								}}
							/>
						</div>
					</div>
				</section>
				<hr className={style.hr} />
				<section>
					<h3 className={style.headerInfo}>
						Contractor’s Information
					</h3>
					<div
						id="contractorsForm"
						className={styles3.double__inputs}
					>
						<div>
							<label htmlFor="Contractor_ESBN">
								Contractor ESBN
								<span style={{ color: "red" }}> *</span>
							</label>
							<input
								id="Contractor_ESBN"
								value={formValues.Contractor_ESBN}
								onChange={handleChange}
								placeholder="eg. 54826648633"
							/>
							{checkingESBNRecord && (
								<p
									style={{
										fontSize: "12px",
										margin: "-25px 0 0",
										color: "#9e9e9e"
									}}
								>
									checking record...
								</p>
							)}
						</div>
						<div>
							<label htmlFor="contractorName">
								Contractor Name{" "}
								<span style={{ color: "red" }}> * </span>
							</label>
							<input
								id="contractorName"
								type="text"
								value={formValues.Contractor_Name}
								onChange={handleChange}
								placeholder="Don Joe"
							/>
						</div>
					</div>

					<div className={styles3.double__inputs}>
						<div>
							<label htmlFor="PhoneNumber">
								Phone Number{" "}
								<span style={{ color: "red" }}> * </span>
							</label>
							<input
								type="text"
								id="Contractor_PhoneNumber"
								value={formValues.Contractor_PhoneNumber}
								onChange={handleChange}
								placeholder="0814973750"
							/>
						</div>
						<div>
							<label htmlFor="email">
								Email <span style={{ color: "red" }}> * </span>
							</label>
							<input
								type="email"
								id="Contractor_Email"
								value={formValues.Contractor_Email}
								onChange={handleChange}
								placeholder="joedon@gmail.com"
							/>
						</div>
					</div>
					<label htmlFor="Contractor_Address">
						Address <span style={{ color: "red" }}> * </span>
					</label>
					<input
						type="text"
						id="Contractor_Address"
						long="true"
						value={formValues.Contractor_Address}
						onChange={handleChange}
						placeholder="igbo-eke"
					/>
					{/* individual contractor  information calc section */}
					<section>
						<div className={styles3.double__inputs}>
							<div className={style.inputContainer}>
								<label htmlFor="contractualSum">
									Contractual Sum agreed
									<span style={{ color: "red" }}> * </span>
								</label>
								<input
									id="Sum"
									value={formValues.Sum}
									onChange={handleChange}
									placeholder="enter amount"
								/>
							</div>
							<div>
								<label htmlFor="tariffRate">
									Tariff/ Rate{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="Rate"
									value={formValues.Rate}
									onChange={handleChange}
								>
									<option>Select Tariff Type</option>
									{setupOptions.map((option) => (
										<option
											key={option.id}
											value={option.Name}
										>
											{option.Name}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className={styles3.double__inputs}>
							<div>
								<label htmlFor="startDate">
									Contract Start Date
									<span style={{ color: "red" }}> * </span>
								</label>
								<input
									type="date"
									id="StartDate"
									value={formValues.StartDate}
									onChange={handleChange}
								/>
							</div>
							<div>
								<label htmlFor="endDate">
									Contract End Date
									<span style={{ color: "red" }}> * </span>
								</label>
								<input
									type="date"
									id="EndDate"
									value={formValues.EndDate}
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className={styles3.double__inputs}>
							<div>
								<label htmlFor="contractType">
									Type of contract
									<span style={{ color: "red" }}> * </span>
								</label>
								<input
									type="text"
									long="true"
									id="Contract_Type"
									value={formValues.Contract_Type}
									onChange={handleChange}
									placeholder="enter the nature of the contract"
								/>
							</div>
							<div>
								<label htmlFor="PaymentDate">
									Payment Date
									<span style={{ color: "red" }}> * </span>
								</label>
								<input
									type="date"
									id="PaymentDate"
									value={formValues.PaymentDate}
									onChange={handleChange}
								/>
							</div>
						</div>

						<button
							type="button"
							className={`secondary__btn ${anssidStyles.create__btn}`}
							style={{
								float: "right",
								marginTop: "4px"
							}}
							onClick={addContractToTable}
							disabled={
								!formValues.Contractor_Name ||
								!formValues.Contractor_ESBN ||
								!formValues.Contractor_Address ||
								!formValues.Contractor_PhoneNumber ||
								!formValues.Contractor_Email ||
								!formValues.Sum ||
								!formValues.Rate ||
								!formValues.StartDate ||
								!formValues.EndDate ||
								!formValues.PaymentDate ||
								!formValues.Contract_Type
							}
						>
							{editing !== null ? "Update Edit" : "Add Contract"}
						</button>
					</section>
				</section>
				<div
					className={styles1.table__div}
					style={{
						width: "70vw",
						marginTop: "48px",
						overflow: "hidden"
					}}
				>
					{contractorDetails.length > 0 && (
						<>
							<div id="table" className={style.table__wrapper}>
								<Table
									headers={headers}
									data={contractorDetails}
									full
									pageSize={10}
									onEdit={(contract) => {
										const { serialNumber } = contract;
										const contractToEdit =
											contractorDetails.find(
												(existingContract) =>
													existingContract.serialNumber ===
													serialNumber
											);

										if (contractToEdit) {
											setFormValues({
												...formValues,
												Contractor_ESBN:
													contractToEdit.Contractor_ESBN,
												Contractor_Name:
													contractToEdit.Contractor_Name,
												Contractor_PhoneNumber:
													contractToEdit.Contractor_PhoneNumber,
												Contractor_Email:
													contractToEdit.Contractor_Email,
												Contractor_Address:
													contractToEdit.Contractor_Address,
												Sum: contractToEdit.Sum,
												Rate: contractToEdit.Rate,
												RevenueCode:
													contractToEdit.RevenueCode,
												StartDate:
													contractToEdit.StartDate,
												EndDate: contractToEdit.EndDate,
												PaymentDate:
													contractToEdit.PaymentDate,
												Contract_Type:
													contractToEdit.Contract_Type
											});
											setEditing(serialNumber);
										}
										setTimeout(() => {
											document
												.getElementById(
													"contractorsForm"
												)
												.scrollIntoView({
													behavior: "smooth",
													block: "start"
												});
										}, 100);
									}}
									onDelete={(contract) => {
										const { serialNumber } = contract;
										setContractorDetails((prevDetails) => {
											const updatedDetails =
												prevDetails.filter(
													(existingContract) =>
														existingContract.serialNumber !==
														serialNumber
												);
											return updatedDetails.map(
												(contract, index) => ({
													...contract,
													serialNumber: index + 1
												})
											);
										});
									}}
								/>
							</div>
							<div className={style.AmountWithheld}>
								<p>Total Withholding Tax Due</p>
								<h4>{`₦${formatAmount(totalBilled)}`}</h4>
							</div>
							{/* other details section  */}
							<hr className={style.hr} />
							<section className={style.otherDetails}>
								<h3 className={style.headerInfo}>
									Other Details
								</h3>
								<div className={styles3.double__inputs}>
									<div className={style.inputContainer}>
										<label htmlFor="Discount_Amount">
											Discount / Rebate Amount
										</label>
										<input
											id="Discount_Amount"
											value={formValues.Discount_Amount}
											onChange={handleChange}
											placeholder="enter discount or rebate amount"
										/>
									</div>
									<div>
										<label htmlFor="Discount_Info">
											Discount / Rebate Description{" "}
											{isDescriptionRequired.Discount_Info && (
												<span style={{ color: "red" }}>
													*
												</span>
											)}
										</label>
										<input
											type="text"
											id="Discount_Info"
											value={formValues.Discount_Info}
											onChange={handleChange}
											placeholder="enter discount description"
											required={
												isDescriptionRequired.Discount_Info
											}
										/>
									</div>
								</div>
							</section>
							<hr className={style.hr} />
							{/* document upload section */}
							<section>
								<input
									type="file"
									id="fileInput"
									multiple
									style={{ display: "none" }}
									onChange={handleFileChange}
								/>
								<label
									htmlFor="fileInput"
									className={style.invisibleFileLabel}
								>
									<p>
										+
										<span
											style={{
												marginLeft: "5px",
												textDecoration: "underline"
											}}
										>
											Attach contract document (Max file
											size: 500KB)
										</span>
									</p>
								</label>
							</section>
							<div>
								{fileLists.map((file, index) => {
									const fileExtension = file.name
										.split(".")
										.pop()
										.toLowerCase();

									const logo =
										fileExtension === "pdf"
											? pdfImg
											: docsImg;

									const handleRemoveFile = (
										indexToRemove
									) => {
										const updateFileList = fileLists.filter(
											(_, index) =>
												index !== indexToRemove
										);
										setFileLists(updateFileList);
									};
									return (
										<div
											key={index}
											className={style.fileLists}
										>
											<div>
												<img
													src={logo}
													alt="Document Logo"
												/>
												<span key={index}>
													{file.name}
												</span>
											</div>
											<button
												className={style.billButton}
												onClick={() =>
													handleRemoveFile(index)
												}
											>
												<img
													src={removeImg}
													alt="Remove Logo"
												/>
											</button>
										</div>
									);
								})}
							</div>
							<hr className={style.hr} />
							<div className={style.colationSection}>
								<h4>
									Total Withholding Tax Due:&nbsp;
									{`₦${formatAmount(totalBilled)}`}{" "}
								</h4>
								<h4>
									Discount Amount:&nbsp;
									{`₦${formatAmount(
										formValues.Discount_Amount
									)}`}{" "}
								</h4>
							</div>
							<hr className={style.hr} />
							<div className={style.otherDetails}>
								{isCalculated && (
									<h2 style={{ marginTop: "48px" }}>
										Total W/Tax Amount:{" "}
										{`₦${formatAmount(calculatedAmount)}`}
									</h2>
								)}
								{!isCalculated && (
									<button
										type="button"
										onClick={CalculateWHT}
										className={`primary__btn ${anssidStyles.create__btn}`}
										style={{
											float: "right",
											marginTop: "32px",
											marginBottom: "222px"
										}}
										disabled={
											calculateLoading ||
											contractorDetails.length === 0 ||
											!(
												formValues.Employer_ESBN &&
												formValues.Employer_Name &&
												formValues.Employer_Email &&
												formValues.Employer_Address &&
												formValues.Employer_PhoneNumber &&
												formValues.Sector &&
												formValues.AssessmentMode
											) ||
											(formValues.Discount_Amount &&
												!formValues.Discount_Info)
										}
									>
										{calculateLoading
											? "Calculating..."
											: "Calculate Tax"}
									</button>
								)}
								{isCalculated && (
									<button
										type="submit"
										className={`primary__btn ${anssidStyles.create__btn}`}
										style={{
											float: "right",
											marginTop: "32px",
											marginBottom: "222px"
										}}
										disabled={
											isBillGenerated || generateLoading
										}
									>
										{isBillGenerated
											? "Generated"
											: generateLoading
												? "Generating Bill..."
												: "Generate Bill"}{" "}
									</button>
								)}
							</div>
						</>
					)}
				</div>
			</form>
			{isSuccessModal && (
				<Modal showing={isSuccessModal}>
					<div className={PAYEstyle.approvalSuccess__badge}>
						<div className={PAYEstyle.successSection}>
							<img
								src={successCheck}
								alt="circle with a tick check inside"
							/>
							<h3>SUCCESS!</h3>
						</div>
						<button
							style={{
								background: "none",
								border: "none",
								padding: 0,
								cursor: "pointer"
							}}
							aria-label="Close"
						>
							<img
								src={closeBlackCircleBtn}
								alt="Close button Icon"
							/>
						</button>
					</div>
					<div className={PAYEstyle.modalContents}>
						<p className={PAYEstyle.success_message}>
							You have at this point, successfully submitted
							<br /> your WHT Assessment which will be <br />
							pending till it is approved or denied by the <br />
							Assessment HOD and would be sent via email.
						</p>

						<div className={PAYEstyle.modalBtn}>
							<button
								type="button"
								className={`primary__btn ${PAYEstyle.continue__btn}`}
								onClick={() => {
									setIsSuccessModal(false);
									generateBill();
								}}
							>
								{generating
									? "Generating Bill..."
									: "View Generated Bill"}
							</button>
						</div>
					</div>
				</Modal>
			)}
			{showingBill && (
				<Modal showing={showingBill}>
					<div className={style.generateBill}>
						<div className={style.billCloseButton}>
							<button
								className={style.billButton}
								onClick={() =>
									history.push("/withholding/records")
								}
							>
								<img
									src={closeBlackCircleBtn}
									alt="close button"
								/>
							</button>
						</div>
						<div
							dangerouslySetInnerHTML={{
								__html: generatedBill
							}}
						/>
					</div>
					<button
						style={{ marginTop: "24px", float: "inline-end" }}
						className={`primary__btn ${anssidStyles.create__btn}`}
						onClick={() => history.push("/withholding/records")}
					>
						Finish
					</button>
				</Modal>
			)}
		</main>
	);
};

export default WHTCorporateAssessment;
