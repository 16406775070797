import React, { createRef, useEffect, useState } from "react";
import axios from "axios";
import Alert from "../../components/Alert";
import { useLocation } from "react-router-dom";
import styles from "../../Identity-management-individual/style.module.css";
import style from "./style.module.css";
import Spinner from "../../components/spinner";

export const EditEmployeeDetails = () => {
	const [loading, setLoading] = useState(false);
	const [esbnPhoto, setESBNPhoto] = useState(null);
	const { state } = useLocation();
	const [userData, setUserData] = useState({
		id: state?.employeeStateData?.id || "",
		eSBN: state?.employeeStateData?.eSBN || "",
		firstname: state?.employeeStateData?.firstName || "",
		lastname: state?.employeeStateData?.lastName || "",
		gender: state?.employeeStateData?.gender || "",
		status: state?.employeeStateData?.status || "",
		salary: state?.employeeStateData?.salary || "",
		allowance: state?.employeeStateData?.allowance || "",
		nationality: state?.employeeStateData?.nationality || "",
		enugulocation: state?.employeeStateData?.enuguLocation || "",
		monthsinservice: state?.employeeStateData?.monthsInService || "",
		annualgrosssalary: state?.employeeStateData?.annualGrossSalary || "",
		annualbasicsalary: state?.employeeStateData?.annualBasicSalary || "",
		annualleaveallowance:
			state?.employeeStateData?.annualLeaveAllowance || "",
		annualtransportation:
			state?.employeeStateData?.annualTransportation || "",
		annualrent: state?.employeeStateData?.annualRent || "",
		thirteenthmonthsalary:
			state?.employeeStateData?.thirteenthMonthSalary || "",
		otherincome: state?.employeeStateData?.otherIncome || "",
		annualpension: state?.employeeStateData?.annualPension || "",
		annualnhis: state?.employeeStateData?.annualNHIS || "",
		annualnhf: state?.employeeStateData?.annualNHF || "",
		annuallifeassurance:
			state?.employeeStateData?.annualLifeAssurance || "",
		annuallifetaxpaid: state?.employeeStateData?.annualLifeTaxPaid || "",
		annualcalctotalrelief:
			state?.employeeStateData?.annualCalcTotalRelief || "",
		annualcalctaxdue: state?.employeeStateData?.annualCalcTaxDue || "",
		filingYear: state?.employeeStateData?.filingYear || ""
	});

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const params = {
		corporateAnnualReturnInfoId:
			state?.employeeStateData?.corporateAnnualReturnInfoId,
		id: state?.employeeStateData?.id
	};

	const filterRef = createRef();

	const handleProfileInArrayChange = (e) => {
		e.preventDefault();
		const value = e.target.value;
		const name = e.target.name;

		setUserData((prevState) => ({ ...prevState, [name]: value }));
	};

	const fetchESBNImage = async (ownersesbn) => {
		try {
			const res = await axios
				.get(`users/anssid?anssid=${ownersesbn}`)
				.then((res) => res.data);
			if (res?.data?.biometricPhoto) {
				setESBNPhoto(res?.data?.biometricPhoto);
				setAlert({
					showing: true,
					type: "success",
					message: res?.message ?? "Biometric Photo Found"
				});
			} else {
				setAlert({
					showing: true,
					type: "error",
					message: "Biometric Photo Not Found"
				});
			}
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message:
					error?.response?.data?.msg ||
					"Taxpayer not found with the ESBN"
			});
		}
	};

	// const fetchESBNImage = async (ownersesbn) => {
	// 	try {
	// 		const res = await axios
	// 			.get(`users/anssid?anssid=${ownersesbn}`)
	// 			.then((res) => res.data);
	// 		if (res?.data?.biometricPhoto) {
	// 			setESBNPhoto(res?.data?.biometricPhoto);
	// 			setAlert({
	// 				showing: true,
	// 				type: 'success',
	// 				message: res?.message ?? 'Biometric Photo Found'
	// 			})
	// 		} else {
	// 			setAlert({
	// 				showing: true,
	// 				type: 'error',
	// 				message: 'Biometric Photo Not Found'
	// 			})
	// 		}
	// 	} catch (error) {
	// 		setAlert({
	// 			showing: true,
	// 			type: 'error',
	// 			message: error?.response?.data?.msg || 'Taxpayer not found with the ESBN'
	// 		})
	// 	}
	// }

	const updateEmployeeDetails = async (e) => {
		e.preventDefault();

		setLoading(true);
		if (!filterRef?.current?.classList?.contains(styles.no__display)) {
			filterRef?.current?.classList?.toggle(styles.no__display);
		}

		try {
			const accessToken = localStorage.getItem("access_token");
			let data = await axios
				.put(
					`coy/annual-returns/editStaff/${params?.id}/${params?.corporateAnnualReturnInfoId}`,
					{
						...userData
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				.then((res) => {
					setAlert({
						showing: true,
						type: "success",
						message:
							res?.message ?? "Employee data updated successfully"
					});
					return res?.data || {};
				});

			if (!data || !data?.data) {
				return null;
			}
			setUserData(data?.data?.EmployeeDetails || []);
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message:
					error?.response?.data?.message ??
					"Employee data updated successfully"
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (userData?.eSBN?.length > 10) {
			fetchESBNImage(userData.eSBN);
		}
	}, [userData?.eSBN]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<form className={styles.form} onSubmit={updateEmployeeDetails}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}

			<header>
				<h2>Employee Details {userData?.filingYear || ""}</h2>
			</header>
			<hr className={styles.divider} />

			<div className={styles.double__inputs}>
				<div>
					<br />
					<label className={styles.input__label} htmlFor="firstname">
						First Name
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						name="firstname"
						id="firstname"
						placeholder=""
						value={userData?.firstname}
					/>
				</div>
				<div>
					<br />
					<label className={styles.input__label} htmlFor="lastname">
						Last Name
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						id="lastname"
						name="lastname"
						placeholder=""
						value={userData?.lastname}
					/>
				</div>
			</div>
			<br />
			<br />
			<div className={styles.double__inputs}>
				<div>
					<br />
					<label className={styles.input__label} htmlFor="gender">
						Gender
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						name="gender"
						id="gender"
						placeholder=""
						value={userData?.gender}
					/>
				</div>
				<div>
					<br />
					<label className={styles.input__label} htmlFor="status">
						Status
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						id="status"
						name="status"
						placeholder=""
						value={userData?.status}
					/>
				</div>
			</div>
			<br />
			<br />
			<div className={styles.double__inputs}>
				<div>
					<br />
					<label className={styles.input__label} htmlFor="eSBN">
						ESBN
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						name="eSBN"
						id="eSBN"
						placeholder=""
						value={userData?.eSBN}
					/>
				</div>
				<div>
					<br />
					<br />
					{esbnPhoto && (
						<img
							className={style.esbnImage}
							src={esbnPhoto}
							alt="ESBN Holder"
						/>
					)}
				</div>
			</div>
			<br />
			<hr className={styles.divider} />
			<div className={styles.double__inputs}>
				<div>
					<br />
					<label className={styles.input__label} htmlFor="salary">
						Salary
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						id="salary"
						name="salary"
						placeholder=""
						value={userData?.salary}
					/>
				</div>
				<div>
					<br />
					<label className={styles.input__label} htmlFor="allowance">
						Allowance
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						id="allowance"
						name="allowance"
						placeholder=""
						value={userData?.allowance}
					/>
				</div>
			</div>
			<br />
			<div className={styles.double__inputs}>
				<div>
					<br />
					<label
						className={styles.input__label}
						htmlFor="nationality"
					>
						Nationality
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						id="nationality"
						name="nationality"
						placeholder=""
						value={userData?.nationality}
					/>
				</div>
				<div>
					<br />
					<label
						className={styles.input__label}
						htmlFor="enugulocation"
					>
						Address
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						id="enugulocation"
						name="enugulocation"
						placeholder=""
						value={userData?.enugulocation}
					/>
				</div>
			</div>
			<br />
			<hr className={styles.divider} />
			<div className={styles.double__inputs}>
				<div>
					<br />
					<label
						className={styles.input__label}
						htmlFor="monthsinservice"
					>
						Months In Service
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						id="monthsinservice"
						name="monthsinservice"
						placeholder=""
						value={userData?.monthsinservice}
					/>
				</div>
				<div>
					<br />
					<label
						className={styles.input__label}
						htmlFor="annualgrosssalary"
					>
						Annual Gross Salary
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						id="annualgrosssalary"
						name="annualgrosssalary"
						placeholder=""
						value={userData?.annualgrosssalary}
					/>
				</div>
			</div>
			<hr className={styles.divider} />
			<div className={styles.double__inputs}>
				<div>
					<br />
					<label
						className={styles.input__label}
						htmlFor="annualbasicsalary"
					>
						Annual Basic Salary
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						id="annualbasicsalary"
						name="annualbasicsalary"
						placeholder=""
						value={userData?.annualbasicsalary}
					/>
				</div>
				<div>
					<br />
					<label
						className={styles.input__label}
						htmlFor="annualleaveallowance"
					>
						Annual Leave Allowance
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						id="annualleaveallowance"
						name="annualleaveallowance"
						placeholder=""
						value={userData?.annualleaveallowance}
					/>
				</div>
			</div>
			<hr className={styles.divider} />
			<br />
			<div className={styles.double__inputs}>
				<div>
					<br />
					<label
						className={styles.input__label}
						htmlFor="annualbasicsalary"
					>
						Annual Basic Salary
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						id="annualbasicsalary"
						name="annualbasicsalary"
						placeholder=""
						value={userData?.annualbasicsalary}
					/>
				</div>
				<div>
					<br />
					<label
						className={styles.input__label}
						htmlFor="annualtransportation"
					>
						Annual Transportation Allowance
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						id="annualtransportation"
						name="annualtransportation"
						placeholder=""
						value={userData?.annualtransportation}
					/>
				</div>
			</div>
			<br />
			<div className={styles.double__inputs}>
				<div>
					<br />
					<label className={styles.input__label} htmlFor="annualrent">
						Annual Rent
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						id="annualrent"
						name="annualrent"
						placeholder=""
						value={userData?.annualrent}
					/>
				</div>
				<div>
					<br />
					<label
						className={styles.input__label}
						htmlFor="thirteenthmonthsalary"
					>
						Thirteenth Month Salary
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						id="thirteenthmonthsalary"
						name="thirteenthmonthsalary"
						placeholder=""
						value={userData?.thirteenthmonthsalary}
					/>
				</div>
			</div>
			<br />
			<div className={styles.double__inputs}>
				<div>
					<br />
					<label
						className={styles.input__label}
						htmlFor="otherincome"
					>
						Other Income
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						id="otherincome"
						name="otherincome"
						placeholder=""
						value={userData?.otherincome}
					/>
				</div>
				<div>
					<br />
					<label
						className={styles.input__label}
						htmlFor="annualpension"
					>
						Annual Pension
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						id="annualpension"
						name="annualpension"
						placeholder=""
						value={userData?.annualpension}
					/>
				</div>
			</div>
			<br />
			<div className={styles.double__inputs}>
				<div>
					<br />
					<label className={styles.input__label} htmlFor="annualnhis">
						Annual NHIS
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						id="annualnhis"
						name="annualnhis"
						placeholder=""
						value={userData?.annualnhis}
					/>
				</div>
				<div>
					<br />
					<label className={styles.input__label} htmlFor="annualnhf">
						Annual NHF
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						id="annualnhf"
						name="annualnhf"
						placeholder=""
						value={userData?.annualnhf}
					/>
				</div>
			</div>
			<br />
			<div className={styles.double__inputs}>
				<div>
					<br />
					<label
						className={styles.input__label}
						htmlFor="annuallifeassurance"
					>
						Annual Life Assurance
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						id="annuallifeassurance"
						name="annuallifeassurance"
						placeholder=""
						value={userData?.annuallifeassurance}
					/>
				</div>
				<div>
					<br />
					<label
						className={styles.input__label}
						htmlFor="annuallifetaxpaid"
					>
						Annual Life Tax Paid
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						id="annuallifetaxpaid"
						name="annuallifetaxpaid"
						placeholder=""
						value={userData?.annuallifetaxpaid}
					/>
				</div>
			</div>
			<br />
			<div className={styles.double__inputs}>
				<div>
					<br />
					<label
						className={styles.input__label}
						htmlFor="annualcalctotalrelief"
					>
						Annual Total Relief
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						id="annualcalctotalrelief"
						name="annualcalctotalrelief"
						placeholder=""
						value={userData?.annualcalctotalrelief}
					/>
				</div>
				<div>
					<br />
					<label
						className={styles.input__label}
						htmlFor="annualcalctaxdue"
					>
						Annual Calc Tax Due
					</label>
					<input
						type="text"
						onChange={(e) => handleProfileInArrayChange(e)}
						id="annualcalctaxdue"
						name="annualcalctaxdue"
						placeholder=""
						value={userData?.annualcalctaxdue}
					/>
				</div>
			</div>

			<br />
			<br />
			<footer className={styles.form__footer}>
				<button
					disabled={loading}
					type="submit"
					className={[
						"primary__btn",
						styles.primary__button,
						loading ? styles.loading__button : ""
					].join(" ")}
				>
					Save
					{loading && <Spinner />}
				</button>
			</footer>
		</form>
	);
};
