import styles3 from "../../../Identity-management-individual/style.module.css";
import WHTstyles from "../../WHT/style.module.css";
import anssidStyles from "../../StateTIN/style.module.css";

const EditPropertyDetails = ({
	propertyDetails,
	setPropertyDetails,
	editTable,
	defaultCGTSetup,
	setDefaultCGTSetup
}) => {
	const handleInputChange = (e) => {
		const { id, value } = e.target;
		setPropertyDetails((prev) => ({
			...prev,
			[id]: value
		}));
	};

	return (
		<form id="propertyDetails" className={styles3.columned__form}>
			<h3 className={WHTstyles.headerInfo}>Property Details</h3>
			<div className={styles3.double__inputs}>
				<div className={WHTstyles.inputContainer}>
					<label htmlFor="TypeOfCGT">
						Type of CGT <span style={{ color: "red" }}>*</span>
					</label>
					<input
						id="TypeOfCGT"
						value={defaultCGTSetup.TypeOfCGT}
						onChange={(e) => setDefaultCGTSetup(e.target.value)}
						readOnly
					/>
				</div>
				<div className={WHTstyles.inputContainer}>
					<label htmlFor="AquisitionAmount">
						Acquisition Amount (₦){" "}
						<span style={{ color: "red" }}> *</span>
					</label>
					<input
						id="AquisitionAmount"
						value={propertyDetails.AquisitionAmount}
						onChange={handleInputChange}
						placeholder="enter acquisition amount "
					/>
				</div>
			</div>
			<div className={styles3.double__inputs}>
				<div className={WHTstyles.inputContainer}>
					<label htmlFor="CurrentMarketValue">
						Current Market Value (₦){" "}
						<span style={{ color: "red" }}> *</span>
					</label>
					<input
						id="CurrentMarketValue"
						value={propertyDetails.CurrentMarketValue}
						onChange={handleInputChange}
						placeholder="enter current market value "
					/>
				</div>
				<div className={WHTstyles.inputContainer}>
					<label htmlFor="SalesAmount">
						Sales Amount (₦){" "}
						<span style={{ color: "red" }}> *</span>
					</label>
					<input
						id="SalesAmount"
						value={propertyDetails.SalesAmount}
						onChange={handleInputChange}
						placeholder="enter amount"
					/>
				</div>
			</div>
			<div className={styles3.double__inputs}>
				<div className={WHTstyles.inputContainer}>
					<label htmlFor="Profit">
						Chargeable gain (₦){" "}
						<span style={{ color: "red" }}> *</span>
					</label>
					<input
						id="Profit"
						value={propertyDetails.Profit}
						onChange={handleInputChange}
						placeholder="enter amount"
					/>
				</div>
				<div className={WHTstyles.inputContainer}>
					<label htmlFor="CGTRate">
						CGT Rate <span style={{ color: "red" }}> *</span>
					</label>
					<input
						id="CGTRate"
						value={defaultCGTSetup.CGTRate}
						onChange={(e) => setDefaultCGTSetup(e.target.value)}
						readOnly
					/>
					<span className={WHTstyles.currencySymbol}>%</span>
				</div>
			</div>
			<div className={styles3.double__inputs}>
				<div>
					<label htmlFor="TypeOfProperty">
						Description of Property{" "}
						<span style={{ color: "red" }}> *</span>
					</label>
					<input
						id="TypeOfProperty"
						value={propertyDetails.TypeOfProperty}
						onChange={handleInputChange}
						placeholder="enter type of property"
					/>
				</div>
				<div>
					<label htmlFor="PropertyLocation">
						Location of Property
						<span style={{ color: "red" }}> *</span>
					</label>
					<input
						type="text"
						id="PropertyLocation"
						value={propertyDetails.PropertyLocation}
						onChange={handleInputChange}
						placeholder="enter the location of the property"
					/>
				</div>
			</div>
			<div className={styles3.double__inputs}>
				<div>
					<label htmlFor="PropertyNature">
						Nature of Property{" "}
						<span style={{ color: "red" }}> *</span>
					</label>
					<input
						type="text"
						id="PropertyNature"
						value={propertyDetails.PropertyNature}
						onChange={handleInputChange}
						placeholder="enter the nature of the contract"
					/>
				</div>
			</div>
			<div>
				<button
					type="button"
					className={`secondary__btn ${anssidStyles.create__btn}`}
					style={{
						float: "right",
						marginTop: "4px",
						marginBottom: "48px"
					}}
					onClick={editTable}
					disabled={
						!propertyDetails.AquisitionAmount ||
						!propertyDetails.CurrentMarketValue ||
						!propertyDetails.SalesAmount ||
						!propertyDetails.TypeOfProperty ||
						!propertyDetails.PropertyLocation ||
						!propertyDetails.PropertyNature
					}
				>
					Update Edit
				</button>
			</div>
		</form>
	);
};

export default EditPropertyDetails;
