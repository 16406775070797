import PAYEstyle from "../../PAYE/PAYE-Assessment/style.module.css";
import WHTstyles from "../../WHT/style.module.css";
import styles3 from "../../../Identity-management-individual/style.module.css";

const ASSESSMENT_MODES = {
	MONTHLY: "MONTHLY",
	YEARLY: "YEARLY",
	PERIODICALLY: "PERIODICALLY"
};

const AssessmentMode = ({ assessmentModeOptions, setAssessmentMode }) => {
	const handleAssessmentModeChange = (mode) => {
		setAssessmentMode((prev) => ({
			...prev,
			filingMode: mode,
			billMonth: "",
			billYear: "",
			startDate: "",
			endDate: ""
		}));
	};

	const handleInputChange = (e) => {
		const { id, value } = e.target;
		setAssessmentMode((prev) => ({
			...prev,
			[id]: value
		}));
	};
	return (
		<>
			<h3 className={PAYEstyle.header} style={{ marginBottom: "24px" }}>
				Mode of Assessment
				<span style={{ color: "red" }}> * </span>
			</h3>
			<div className={`${PAYEstyle.radioBtns} ${WHTstyles.whtRadio}`}>
				{Object.entries(ASSESSMENT_MODES).map(([key, value]) => (
					<div className={PAYEstyle.radioBtn} key={key}>
						<input
							type="radio"
							id={value}
							value={value}
							checked={assessmentModeOptions.filingMode === value}
							onChange={() => handleAssessmentModeChange(value)}
						/>
						<label htmlFor={value} required>
							{value.charAt(0) + value.slice(1).toLowerCase()}
						</label>
					</div>
				))}
			</div>

			<form className={styles3.columned__form}>
				{assessmentModeOptions.filingMode ===
					ASSESSMENT_MODES.MONTHLY && (
					<div
						className={`${styles3.double__inputs} ${WHTstyles.assessmentInput}`}
					>
						<div>
							<label
								htmlFor="billYear"
								className={styles3.input__label}
							>
								Bill Year (Fiscal Year)
								<span style={{ color: "red" }}> *</span>
							</label>
							<select
								id="billYear"
								value={assessmentModeOptions.billYear}
								onChange={handleInputChange}
								required
							>
								<option value="">Select year</option>
								{new Array(10).fill(0).map((_, i) => {
									const year = new Date().getFullYear() - i;
									return (
										<option key={year} value={year}>
											{year}
										</option>
									);
								})}
							</select>
						</div>
						<div>
							<label
								htmlFor="billMonth"
								className={styles3.input__label}
							>
								Month of Assessment
								<span style={{ color: "red" }}> *</span>
							</label>
							<select
								id="billMonth"
								value={assessmentModeOptions.billMonth}
								onChange={handleInputChange}
							>
								<option>select Month</option>
								{[...Array(12)].map((_, index) => (
									<option
										key={index}
										value={new Intl.DateTimeFormat("en", {
											month: "long"
										}).format(new Date(2000, index, 1))}
									>
										{new Intl.DateTimeFormat("en", {
											month: "long"
										}).format(new Date(2000, index, 1))}
									</option>
								))}
							</select>
						</div>
					</div>
				)}
				{assessmentModeOptions.filingMode ===
					ASSESSMENT_MODES.YEARLY && (
					<div
						className={`${styles3.double__inputs} ${WHTstyles.assessmentInput}`}
					>
						<div>
							<label
								htmlFor="billYear"
								className={styles3.input__label}
							>
								Bill Year (Fiscal Year)
								<span style={{ color: "red" }}> *</span>
							</label>
							<select
								id="billYear"
								value={assessmentModeOptions.billYear}
								onChange={handleInputChange}
								required
							>
								<option value="">Select year</option>
								{new Array(10).fill(0).map((_, i) => {
									const year = new Date().getFullYear() - i;
									return (
										<option key={year} value={year}>
											{year}
										</option>
									);
								})}
							</select>
						</div>
					</div>
				)}
				{assessmentModeOptions.filingMode ===
					ASSESSMENT_MODES.PERIODICALLY && (
					<>
						<div
							className={`${styles3.double__inputs} ${WHTstyles.assessmentInput}`}
						>
							<div>
								<label
									htmlFor="billYear"
									className={styles3.input__label}
								>
									Bill Year (Fiscal Year)
									<span style={{ color: "red" }}> *</span>
								</label>
								<select
									id="billYear"
									value={assessmentModeOptions.billYear}
									onChange={handleInputChange}
									required
								>
									<option value="">Select year</option>
									{new Array(10).fill(0).map((_, i) => {
										const year =
											new Date().getFullYear() - i;
										return (
											<option key={year} value={year}>
												{year}
											</option>
										);
									})}
								</select>
							</div>
						</div>
						<div className={`${styles3.double__inputs}`}>
							<div>
								<label
									htmlFor="startDate"
									className={styles3.input__label}
								>
									Start Month of Assessment
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									type="date"
									id="startDate"
									value={assessmentModeOptions.startDate}
									onChange={handleInputChange}
								/>
							</div>
							<div>
								<label
									htmlFor="endDate"
									className={styles3.input__label}
								>
									End Month of Assessment
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									type="date"
									id="endDate"
									value={assessmentModeOptions.endDate}
									onChange={handleInputChange}
								/>
							</div>
						</div>
					</>
				)}
			</form>
			<hr className={WHTstyles.hr} />
		</>
	);
};

export default AssessmentMode;
