import styles3 from "../../../Identity-management-individual/style.module.css";
import WHTstyles from "../../WHT/style.module.css";

const OtherDetailsComponent = ({
	companyDetails,
	setCompanyDetails,
	setPropertyDetails,
	isDescriptionRequired
}) => {
	const handleInputChange = (e) => {
		const { id, value } = e.target;
		setCompanyDetails((prev) => ({
			...prev,
			[id]: value
		}));
		setPropertyDetails((prev) => ({
			...prev,
			[id]: value
		}));
	};

	return (
		<form className={styles3.columned__form}>
			<h3 className={WHTstyles.headerInfo}>Other Details</h3>
			<div className={styles3.double__inputs}>
				<div className={WHTstyles.inputContainer}>
					<label htmlFor="discount">Discount Amount</label>
					<input
						id="discount"
						value={companyDetails.discount}
						onChange={handleInputChange}
						placeholder="enter discount amount"
						required={isDescriptionRequired.discount}
					/>
				</div>
				<div>
					<label htmlFor="discountReason">
						Discount Description
						{isDescriptionRequired.discountReason && (
							<span style={{ color: "red" }}> * </span>
						)}
					</label>
					<input
						type="text"
						id="discountReason"
						value={companyDetails.discountReason}
						onChange={handleInputChange}
						placeholder="enter discount description"
						required={isDescriptionRequired.outstanding}
					/>
				</div>
			</div>
			<div className={styles3.double__inputs}>
				<div className={WHTstyles.inputContainer}>
					<label htmlFor="outstanding">Outstanding Amount</label>
					<input
						id="outstanding"
						value={companyDetails.outstanding}
						onChange={handleInputChange}
						placeholder="enter outstanding amount"
					/>
				</div>
				<div>
					<label htmlFor="outstandingReason">
						Outstanding Description
						{isDescriptionRequired.outstandingReason && (
							<span style={{ color: "red" }}> * </span>
						)}
					</label>
					<input
						type="text"
						id="outstandingReason"
						value={companyDetails.outstandingReason}
						onChange={handleInputChange}
						placeholder="enter outstanding description"
					/>
				</div>
			</div>
			<div className={styles3.double__inputs}>
				<div>
					<label htmlFor="remark">Remark</label>
					<input
						type="text"
						id="remark"
						value={companyDetails.remark}
						onChange={handleInputChange}
						placeholder="enter possible circumstance of sales"
					/>
				</div>
			</div>
			<hr className={WHTstyles.hr} />
		</form>
	);
};

export default OtherDetailsComponent;
