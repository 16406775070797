import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import style from "./style.module.css";
import constants from "../../constants";
import styles from "../../Identity-management-individual/style.module.css";
import GoBackButton from "../../components/goBackButton";
import Alert from "../../components/Alert";
import { useHistory } from "react-router-dom";

const EditCategories = () => {
	const { id } = useParams();
	const location = useLocation();
	const history = useHistory();
	const getCats = location.state;
	console.log("location", getCats);
	const [revenuecode, setRevenueCode] = useState([]);
	const [revenue, setRevenue] = useState(getCats?.category.Revenue);
	const [getcategories, setgetcategories] = useState([]);
	const [category, setCategory] = useState(getCats?.category.Category);
	const [units, setUnits] = useState(getCats?.category.Units);
	const [bLocation, setBillLocation] = useState(getCats?.category.Address);
	const [faces, setFaces] = useState(getCats?.category.Faces);
	const [subcategories, setSubcategories] = useState([]);
	const [code, setCode] = useState();
	const [subcategory, setSubCategory] = useState(
		getCats?.category.SubCategory
	);
	const [rate, setRate] = useState(0);
	const [branchname, setBranchName] = useState(getCats?.category.Branch);
	const [branchaddress, setBranchAddress] = useState(
		getCats?.category.Address
	);
	const [type, setType] = useState(" ");
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const [fixedFees, setFixedFees] = useState(null); // State for enssaaFixedFees
	const [loading, setLoading] = useState(false);

	const getRevenueCode = async () => {
		const resp = await axios.get(`/settings/mdarevenue`);
		const data = resp?.data?.data;

		// Keep only the specific codes
		const filteredData = data.filter((revenue) =>
			[
				"35001003/12040036",
				"35001003/12040457",
				"35001003/12040456"
			].includes(revenue.Code)
		);

		console.log("resp => ", resp);
		console.log("filteredData => ", filteredData);

		setRevenueCode(filteredData);
	};

	useEffect(() => {
		getRevenueCode();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleDropdownChange = (e) => {
		const selectedType = e.target.value; // Get the selected value from the dropdown
		setType(selectedType); // Update the type state

		// Access the appropriate fee based on the selected type
		if (selectedType === "Renewal" && fixedFees) {
			const renewFee = fixedFees.billboard_renew_fee;
			console.log("Renewal Fee:", renewFee);
		}
		if (selectedType === "Registration" && fixedFees) {
			const regFee = fixedFees.billboard_reg_fee;
			console.log("Registration Fee:", regFee);
		}
		if (selectedType === "Inspection" && fixedFees) {
			const inspectionFee = fixedFees.billboard_inspection_reg_fee;
			console.log("Inspection Fee:", inspectionFee);
		}
		if (selectedType === "Application" && fixedFees) {
			const appFee = fixedFees.billboard_appl_fee;
			console.log("Application Fee:", appFee);
		}

		// Update newSubCats with the appropriate fees
		setType(selectedType);
	};

	//update function for edit category
	const updateCategory = async (e) => {
		e.preventDefault();
		setLoading(true);
		const payload = {
			Code: code,
			Category: category,
			SubCategory: subcategory,
			ApplicationFee:
				type === "Registration"
					? fixedFees?.billboard_appl_fee ||
						getCats?.category.ApplicationFee
					: type === "Renewal"
						? fixedFees?.billboard_appl_fee ||
							getCats?.category.ApplicationFee
						: type === "Application"
							? fixedFees?.billboard_appl_fee ||
								getCats?.category.ApplicationFee
							: getCats?.category.ApplicationFee,
			InspectionFee:
				type === "Registration"
					? fixedFees?.billboard_inspection_reg_fee ||
						getCats?.category.InspectionFee
					: type === "Renewal"
						? fixedFees?.billboard_inspection_renew_fee ||
							getCats?.category.InspectionFee
						: getCats?.category.InspectionFee,
			RegistrationFee:
				type === "Registration"
					? fixedFees?.billboard_reg_fee ||
						getCats?.category.RegistrationFee
					: type === "Renewal"
						? fixedFees?.billboard_renew_fee ||
							getCats?.category.RegistrationFee
						: getCats?.category.RegistrationFee,
			Revenue: revenue,
			Faces: faces,
			Units: units,
			Rate: rate,
			Branch: branchname,
			Address: branchaddress || bLocation || "N/A",
			Amount: 0,
			Total: 0,
			SRN: getCats?.category.SRN
		};
		try {
			const res = await axios.post(
				`${constants.BASE_URL_2}/enssaa/updatecategory/${id}`,
				payload
			);
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res.data.message
			});
			setTimeout(() => {
				history.goBack(); // Go back to the previous page
			}, 3000);
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message:
					error?.response.data?.message ||
					error.response.data?.errors?.[0]?.error ||
					error.response.data?.errors?.details[0]?.message
			});
		} finally {
			setLoading(false);
		}
	};

	const getCategoryInfo = async () => {
		const res = await axios.get(`/settings/profilecategorys/12`);
		if (res) {
			const data = res.data?.data;
			const enssaaFixedFees = res.data?.enssaaFixedFees;
			// Filter out the categories with Code 001, 010, 011
			const filteredData = data.filter(
				(category) =>
					!["004", "011", "006"].includes(category.Code.toUpperCase())
			);
			setgetcategories(filteredData);
			setFixedFees(enssaaFixedFees);
			console.log("filteredData", filteredData);
		}
	};
	useEffect(() => {
		getCategoryInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//subcategory
	useEffect(() => {
		const getSubCategory = async () => {
			try {
				const res = await axios.get(
					`${constants.BASE_URL}/categorytarrifcommerces/12/${category}`
				);
				if (res) {
					const data = res.data?.data || [];
					setSubcategories(data);
					console.log("data", data);

					// Calculate the total FixedFee
					const selectedItem = data.find(
						(item) => item.Description === subcategory
					);
					if (selectedItem) {
						setSubCategory(selectedItem.Description);
					}
					const totalFixedFee = selectedItem?.FixedFee || 0;
					const findCode = selectedItem?.Code || "";

					setRate(totalFixedFee);
					setCode(findCode);

					console.log("hello test", totalFixedFee);
					console.log("gett good", findCode);
				}
			} catch (error) {
				console.error("Error fetching subcategories:", error);
				setSubcategories([]);

				setCode(0);
			}
		};

		if (category) {
			getSubCategory();
		}
	}, [category, subcategory]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);
	return (
		<div className={styles.columned__form}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<GoBackButton />
			<h1 className={style.h1}>Edit Category</h1>
			<div className={styles.double__inputs}>
				<div>
					<label className={styles.input__label} htmlFor="Category">
						Category
						<span style={{ color: "red" }}> *</span>
					</label>
					<select
						name="Category"
						value={category}
						onChange={(e) => setCategory(e.target.value)} // Update ensaaInfo.sector on selection
						required
					>
						<option value="">
							{getcategories
								? "--Select Category--"
								: "...Loading"}
						</option>
						{getcategories &&
							getcategories.map((getcategories) => (
								<option
									key={getcategories.Code}
									value={getcategories.Code}
								>
									{getcategories.Description}
								</option>
							))}
					</select>
				</div>
				<div>
					<label className={styles.input__label} htmlFor="Revenue">
						Revenue
						<span style={{ color: "red" }}> *</span>
					</label>
					<select
						id="revenue"
						name={revenue}
						onChange={(e) => {
							setRevenue(e.target.value);
						}}
						value={revenue}
						required
					>
						<option>--Select Revenue--</option>
						{revenuecode.map((rev) => (
							<option value={rev.Code} key={rev.Code}>
								{rev.Description}
							</option>
						))}
					</select>
				</div>
			</div>
			<div className={styles.double__inputs}>
				<div>
					<label className={styles.input__label} htmlFor="subcat">
						Sub Category
						<span style={{ color: "red" }}>*</span>
					</label>
					<select
						name="subcat"
						value={subcategory}
						onChange={(e) => setSubCategory(e.target.value)}
					>
						<option>
							{subcategories
								? "--Select --Sub Category"
								: "...Loading"}
						</option>
						{subcategories &&
							subcategories.map((subcategories) => (
								<option
									// key={subcategories.Code}
									value={subcategories.Description}
								>
									{subcategories.Description}
								</option>
							))}
					</select>
				</div>
				<div>
					<label className={styles.input__label} htmlFor="type">
						Type
						<span style={{ color: "red" }}> *</span>
					</label>
					<select value={type} onChange={handleDropdownChange}>
						<option value="">Select an option</option>
						<option value="Registration">Registration</option>
						<option value="Renewal">Renewal</option>
					</select>
				</div>
			</div>
			<div className={styles.double__inputs}>
				<div>
					<label className={styles.input__label} htmlFor="units">
						Units
						<span style={{ color: "red" }}> *</span>
					</label>
					<input
						id="units"
						name="units"
						placeholder="Enter Units"
						value={units}
						onChange={(e) => setUnits(e.target.value)}
					/>
				</div>
				<div>
					<label className={styles.input__label} htmlFor="Rate">
						Rate (₦)
						<span style={{ color: "red" }}> *</span>
					</label>
					<input
						id="Rate"
						name="Rate"
						placeholder="Enter rate"
						value={rate}
						onChange={(e) => setRate(e.target.value)}
					/>
				</div>
			</div>
			<div className={styles.double__inputs}>
				<div>
					<label
						className={styles.input__label}
						htmlFor="branchaddress"
					>
						Branch Address
						<span style={{ color: "red" }}> *</span>
					</label>
					<input
						id="branchaddress"
						name="branchaddress"
						placeholder="Enter branch address"
						value={branchaddress}
						onChange={(e) => setBranchAddress(e.target.value)}
					/>
				</div>
				<div>
					<label className={styles.input__label} htmlFor="type">
						Branch Name
						<span style={{ color: "red" }}> *</span>
					</label>
					<input
						id="branchname"
						name="branchname"
						placeholder="Enter branch name"
						value={branchname}
						onChange={(e) => setBranchName(e.target.value)}
					/>
				</div>
			</div>
			<div className={styles.double__inputs}>
				<div>
					<label
						className={styles.input__label}
						htmlFor="billlocation"
					>
						Bill Location
						<span style={{ color: "red" }}> *</span>
					</label>
					<input
						id="billlocation"
						name="billlocation"
						placeholder="Enter Bill Address"
						value={bLocation}
						onChange={(e) => setBillLocation(e.target.value)}
					/>
				</div>
				<div>
					<label className={styles.input__label} htmlFor="faces">
						Faces
						<span style={{ color: "red" }}> *</span>
					</label>
					<input
						id="faces"
						name="faces"
						placeholder="Enter branch name"
						value={faces}
						onChange={(e) => setFaces(e.target.value)}
					/>
				</div>
			</div>
			<div className={style.buttonContainer}>
				<button
					className={["primary__btn", style.stylebtn2].join(" ")}
					onClick={updateCategory}
				>
					{loading ? "loading..." : "Update"}
				</button>
			</div>
		</div>
	);
};

export default EditCategories;
