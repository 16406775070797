import styles3 from "../../../Identity-management-individual/style.module.css";
import WHTstyles from "../../WHT/style.module.css";

const ESBNComponent = ({
	fillerDetails,
	setFillerDetails,
	checkingESBNRecord,
	sectors
}) => {
	const handleInputChange = (e) => {
		const { id, value } = e.target;
		setFillerDetails((prev) => ({
			...prev,
			[id]: value
		}));
	};
	return (
		<form className={styles3.columned__form}>
			<h3 className={WHTstyles.headerInfo}>Tax Payer Information</h3>
			<div className={styles3.double__inputs}>
				<div>
					<label htmlFor="esbn">
						ESBN <span style={{ color: "red" }}> *</span>
					</label>
					<input
						id="esbn"
						value={fillerDetails.esbn}
						onChange={handleInputChange}
						placeholder="eg. 54826648633"
						required
					/>
					{checkingESBNRecord && (
						<p
							style={{
								fontSize: "12px",
								margin: "-25px 0 0",
								color: "#9e9e9e"
							}}
						>
							checking record...
						</p>
					)}
				</div>
				<div>
					<label htmlFor="name">
						Name <span style={{ color: "red" }}> *</span>
					</label>
					<input
						id="name"
						type="text"
						value={fillerDetails.name}
						onChange={handleInputChange}
						placeholder="Don Joe"
						required
						disabled
					/>
				</div>
			</div>
			<div className={styles3.double__inputs}>
				<div>
					<label htmlFor="phone">
						Phone Number <span style={{ color: "red" }}> *</span>
					</label>
					<input
						type="text"
						id="phone"
						value={fillerDetails.phone}
						onChange={handleInputChange}
						placeholder="08140589302"
						required
					/>
				</div>
				<div>
					<label htmlFor="email">
						Email <span style={{ color: "red" }}> *</span>
					</label>
					<input
						type="email"
						id="email"
						value={fillerDetails.email}
						onChange={handleInputChange}
						placeholder="chukwuebuka@gmail.com"
						required
					/>
				</div>
			</div>
			<div className={styles3.double__inputs}>
				<div>
					<label htmlFor="sector">
						Sector <span style={{ color: "red" }}> *</span>
					</label>
					<select
						id="sector"
						value={fillerDetails.sector}
						onChange={handleInputChange}
					>
						<option>Select sector</option>
						{sectors.map((sec) => (
							<option key={sec.id} value={sec.sector}>
								{sec.Description}
							</option>
						))}
					</select>
				</div>
				<div>
					<label htmlFor="address">
						Address <span style={{ color: "red" }}> *</span>
					</label>
					<input
						id="address"
						type="text"
						value={fillerDetails.address}
						onChange={handleInputChange}
						placeholder="Igbo akuku"
						required
					/>
				</div>
			</div>
			<hr className={WHTstyles.hr} />
		</form>
	);
};

export default ESBNComponent;
