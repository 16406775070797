import React, { createRef, useEffect, useState } from "react";
import { useHistory } from "react-router";
import constants from "../../../constants";
import { formatDate, getStatusText } from "../../../utils";
import axios from "axios";
import styles from "../../../Dashboard/MDAs/style.module.css";
import Alert from "../../../components/Alert";
import Table from "../../../components/Table";
import style from "../../style.module.css";
import PaymentModal from "../../../components/Modal/payment.modal";

const PT_selfRecord = () => {
	const filterRef = createRef();
	const corporateESBN = JSON.parse(localStorage.getItem("user_info")).anssid;
	const history = useHistory();
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [data, setData] = useState([]);
	const [paymentStats, setPaymentStat] = useState("");
	const [minAmount, setMinAmount] = useState(0);
	const [maxAmount, setMaxAmount] = useState(0);
	const [filtersCleared, setFiltersCleared] = useState(false);
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);
	const [billNumber, setBillNumber] = useState("");
	const [showPaymentModal, setShowPaymentModal] = useState(false);
	const [esbn, setEsbn] = useState("");
	const [name, setName] = useState("");
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const header = [
		["Bill Number", "Bill_Number"],
		["Sector", "Sector"],
		["Total Purchase Amount", "Total_Purchases_Amount"],
		["Total P/Tax Amount", "TotalAmountBilled"],
		["PT Assessment", "File_Type"],
		["Generated By", "generatedBy"],
		["Payment Status", "PaymentStatus"],
		["Process Status", "ApproveFlg", getStatusText],
		["Date Issued", "InputterTime", formatDate]
	];

	const getAllPTSearch =
		({ currentPage = 1, pageSize = 20, download = false } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			if (!filterRef.current.classList.contains(styles.no__display)) {
				filterRef.current.classList.toggle(styles.no__display);
			}
			let query = `page=${currentPage}&limit=${pageSize}&`;
			if (paymentStats) {
				query += `PaymentStatus=${paymentStats}&`;
			}
			if (maxAmount) {
				query += `maxAmount=${maxAmount}&`;
			}
			if (minAmount) {
				query += `minAmount=${minAmount}&`;
			}

			if (startDate) {
				query += `startDate=${startDate}&`; // Adds startDate parameter to query string if startDate is set
			}
			if (endDate) {
				query += `endDate=${endDate}&`; // Adds endDate parameter to query string if endDate is set
			}
			if (name) {
				query += `name=${name}&`;
			}
			if (billNumber) {
				query += `billNumber=${billNumber}&`;
			}
			if (esbn) {
				query += `esbn=${esbn}&`;
			}

			try {
				const info = await axios
					.get(
						`${constants.BASE1_URL}/purchasingTax/getRecordsByEsbn?${query}&esbn=${corporateESBN}`
					)
					.then((res) => res.data);
				console.log("get", info);
				if (info) {
					if (!download) setData(info.companyRecord.rows);
					setTotal(info.pagination.totalRecords);
				}
				return info;
			} catch (err) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						err.response.data?.msg ||
						err.response.data?.errors?.[0]?.message ||
						err.response.data?.errors?.details[0]?.message
				});
				console.error(err);
			} finally {
				setLoading(false);
			}
		};

	useEffect(() => {
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	const clearFilters = () => {
		setPaymentStat("");
		setMinAmount("");
		setMaxAmount("");
		setStartDate("");
		setEndDate("");
		setName("");
		setBillNumber("");
		setEsbn("");

		filterRef.current.classList.toggle(styles.no__display);
		setFiltersCleared(true);
	};

	useEffect(() => {
		getAllPTSearch()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		getAllPTSearch()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<div>
			<div>
				{alert.showing && (
					<Alert text={alert.message} type={alert.type} />
				)}
				<div className={styles.actions__div}>
					<button
						className={[
							"primary__btn",
							styles.download__button
						].join(" ")}
						onClick={() => history.push(`/pt/pt-assessment`)}
					>
						Create New
					</button>

					<div className={styles.filter}>
						<button
							onClick={() => {
								filterRef.current.classList.toggle(
									styles.no__display
								);
							}}
							className={styles.filter__button}
						>
							Filter
						</button>
						<div
							ref={filterRef}
							className={[
								styles.filter__div,
								styles.no__display
							].join(" ")}
						>
							<div className={styles.filter__header}>
								<h6>Filter</h6>
								<button
									aria-labelledby="close filter button"
									onClick={() => {
										filterRef.current.classList.toggle(
											styles.no__display
										);
									}}
								>
									X
								</button>
							</div>
							<button
								className={styles.clear__filter_fields}
								onClick={clearFilters}
								disabled={
									!minAmount &&
									!maxAmount &&
									!startDate &&
									!endDate &&
									!paymentStats
								}
							>
								Clear Filter fields
							</button>
							<p>Payment Status</p>
							<form>
								<div>
									<label htmlFor="paymentStats">
										payment Status
									</label>
									<input
										name="paymentStats"
										id="paymentStats"
										type="text"
										value={paymentStats}
										onChange={(e) =>
											setPaymentStat(e.target.value)
										}
									/>
								</div>
								<div>
									<label htmlFor="esbn">ESBN</label>
									<input
										name="esbn"
										id="esbn"
										type="text"
										value={esbn}
										onChange={(e) =>
											setEsbn(e.target.value)
										}
									/>
								</div>
							</form>
							<p>By Name</p>
							<form>
								<div>
									<label htmlFor="name">Name</label>
									<input
										name="name"
										id="name"
										type="text"
										value={name}
										onChange={(e) =>
											setName(e.target.value)
										}
										placeholder="john doe"
									/>
								</div>
								<div>
									<label htmlFor="billNumber">
										Bill Number
									</label>
									<input
										name="billNumber"
										id="billNumber"
										type="text"
										value={billNumber}
										onChange={(e) =>
											setBillNumber(e.target.value)
										}
										placeholder="AB57474784393EN"
									/>
								</div>
							</form>
							<p>By P/Tax Amount</p>
							<form>
								<div>
									<label htmlFor="minAmount">
										Min Amount
									</label>
									<input
										name="minAmount"
										id="minAmount"
										type="text"
										value={minAmount}
										onChange={(e) =>
											setMinAmount(e.target.value)
										}
										placeholder="50"
									/>
								</div>
								<div>
									<label htmlFor="maxAmount">
										Max Amount
									</label>
									<input
										name="maxAmount"
										id="maxAmount"
										type="text"
										value={maxAmount}
										onChange={(e) =>
											setMaxAmount(e.target.value)
										}
										placeholder="50000000"
									/>
								</div>
							</form>
							<p>By Creation Date</p>
							<form>
								<div>
									<label htmlFor="start_date">
										Start Date
									</label>
									<input
										name="start_date"
										id="start_date"
										type="date"
										value={startDate}
										onChange={(e) =>
											setStartDate(e.target.value)
										}
									/>
								</div>
								<div>
									<label htmlFor="start_date">End Date</label>
									<input
										name="end_date"
										id="end_date"
										type="date"
										value={endDate}
										onChange={(e) =>
											setEndDate(e.target.value)
										}
									/>
								</div>
							</form>
							<button
								className={[
									styles.submit__button,
									"primary__btn"
								].join(" ")}
								onClick={getAllPTSearch()}
							>
								Search
							</button>
						</div>
					</div>
				</div>

				{loading && !data ? (
					<p style={{ textAlign: "center" }}>Loading...</p>
				) : (
					<div className={style.table_container}>
						{console.log("data", data)}
						<Table
							headers={header}
							data={data}
							total={total}
							pageSize={20}
							full
							onPageChanged={getAllPTSearch}
							onView={(data) => {
								history.push(`/pt/pt-records/view/${data.id}`);
							}}
							onEdit={(data) => {
								history.push(
									`/pt/pt-records/pt-edit/${data.id}`
								);
							}}
							onPay={(data) => {
								if (data.ApproveFlg !== "2") {
									setBillNumber(data.Bill_Number);
									setShowPaymentModal(true);
								}
							}}
						/>
					</div>
				)}
			</div>
			<section>
				{showPaymentModal && (
					<PaymentModal
						showing={showPaymentModal}
						toggle={setShowPaymentModal}
						billNumber={billNumber}
					/>
				)}
			</section>
		</div>
	);
};

export default PT_selfRecord;
