import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Modal from "../../../components/Modal/regular";
import modalStyles from "../../PAYE/PAYE-Assessment/style.module.css";
import anssidStyles from "../../StateTIN/style.module.css";
import successCheck from "../../../assets/Certificate/approvedCheck.svg";
import closeBlackCircleBtn from "../../../assets/x-circle-black.svg";
import style from "../../WHT/style.module.css";

const SuccessModal = ({
	isSuccessModal,
	setIsSuccessModal,
	isBillModalShowing,
	setIsBillModalShowing,
	htmlContent
}) => {
	const history = useHistory();
	const userInfo = JSON.parse(localStorage.getItem("user_info"));
	return (
		<div>
			{isSuccessModal && (
				<Modal showing={isSuccessModal}>
					<div className={modalStyles.approvalSuccess__badge}>
						<div className={modalStyles.successSection}>
							<img
								src={successCheck}
								alt="circle with a tick check inside"
							/>
							<h3>SUCCESS!</h3>
						</div>
						<button
							style={{
								background: "none",
								border: "none",
								padding: 0,
								cursor: "pointer"
							}}
							aria-label="Close"
						>
							<img
								src={closeBlackCircleBtn}
								alt="Close button Icon"
							/>
						</button>
					</div>
					<div className={modalStyles.modalContents}>
						<p className={modalStyles.success_message}>
							You have at this point, successfully generated
							<br /> bill for "Capital Gain Tax Assessment" an
							email <br />
							would be sent to the applicant after <br />{" "}
							confirmation from the HOD.
						</p>

						<div className={modalStyles.modalBtn}>
							<button
								type="button"
								className={`primary__btn ${modalStyles.continue__btn}`}
								onClick={() => {
									setIsSuccessModal(false);
									setIsBillModalShowing(true);
								}}
							>
								Continue
							</button>
						</div>
					</div>
				</Modal>
			)}
			{isBillModalShowing && (
				<Modal showing={isBillModalShowing}>
					<div className={style.billCloseButton}>
						<button
							className={style.billButton}
							onClick={() =>
								history.push(
									userInfo.account_type === "corporate"
										? "/capital-gain-tax/self-service/records"
										: "/capital-gain-tax/records"
								)
							}
						>
							<img src={closeBlackCircleBtn} alt="close button" />
						</button>
					</div>
					<div
						dangerouslySetInnerHTML={{
							__html: htmlContent
						}}
						style={{
							padding: "20px"
						}}
					></div>
					<button
						style={{ marginTop: "24px", float: "inline-end" }}
						className={`primary__btn ${anssidStyles.create__btn}`}
						onClick={() =>
							history.push(
								userInfo.account_type === "corporate"
									? "/capital-gain-tax/self-service/records"
									: "/capital-gain-tax/records"
							)
						}
					>
						Finish
					</button>
				</Modal>
			)}
		</div>
	);
};

export default SuccessModal;
