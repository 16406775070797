import React, { useEffect, useState } from "react";
import constants from "../../constants";
import axios from "axios";
import { useParams } from "react-router-dom";
import Table from "../../components/Table";
import GoBackButton from "../../components/goBackButton";
import styles from "./style.module.css";
import Alert from "../../components/Alert";
import { useHistory } from "react-router-dom";
import ApproveRejectWidget from "../../components/ApproveRejectWidget";
import Modal from "./modal/modal";
import { formatDate } from "../../utils";

const PTReviewerView = () => {
	const history = useHistory();

	const header = [
		["Category", "Category"],
		["Product", "Product"],
		["Tariff Rate", "PT_Rate"],
		["Purchase Amount", "Purchases_Amount"],
		["Transaction Date", "Transaction_Date", formatDate],
		["Amount as PT", "Bill_Amount"]
	];

	const [getdata, setData] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [files, setFiles] = useState([]);
	const [rejectReason, setRejectReason] = useState("");
	const [rejecting, setRejecting] = useState(false);
	const [approving, setApproving] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const token = localStorage.getItem("access_token");
	const { id } = useParams();

	const getProcessStatus = (code) => {
		switch (code) {
			case "0":
				return "Pending";
			case "1":
				return "Cancel";
			case "2":
				return "Declined";
			case "3":
				return "Approve";
			case "4":
				return "Revoke";
			case "5":
				return "Pending Approval/Request for Approval";
			case "6":
				return "Delete";
			case "7":
				return "Review/Audit";
			default:
				return "Unknown Status";
		}
	};

	const getAllPTCompanyInfo = async () => {
		setLoading(true);
		try {
			const res = await axios.get(
				`${constants.BASE1_URL}/purchasingTax/getCompanyInfoRecord/${id}`,
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			);
			const data = res.data.data;
			setData(data);
			setTableData(data?.PurchaseTaxCategoryInfo);
			setFiles(data?.PurchaseTaxCompanyFile);
			console.log("data that", data);
		} catch (error) {
			console.error("Error fetching company info:", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getAllPTCompanyInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Extract file names
	const fileNames = files.map((file) => file.File);

	// Example of rendering in HTML or displaying in the UI
	fileNames.forEach((fileName) => {});

	const convertBase64ToBlob = (base64String, mimeType) => {
		let base64 = base64String;
		if (base64String.includes(",")) {
			base64 = base64String.split(",")[1];
		}
		const byteCharacters = atob(base64);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		return new Blob([byteArray], { type: mimeType });
	};

	// Function to handle downloading the file
	// "Download all" button will trigger individual downloads for each file.
	const handleDownloadAll = () => {
		files.forEach((file) => {
			const blob = convertBase64ToBlob(file.File, file.fileType);
			const url = URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;
			link.download = file.FileName;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(url);
		});
	};

	const approve = async () => {
		try {
			setApproving(true);

			const req = await axios
				.put(`/globalprocesswithnobill`, {
					globId: "2",
					approve: "5",
					reason4Reject: "",
					recId: getdata?.id
				})
				.then((res) => res.data || {});

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: req.msg
			});

			setTimeout(() => history.goBack(), 3000);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setApproving(false);
		}
	};
	const reject = async (e) => {
		e.preventDefault();

		try {
			setRejecting(true);

			const req = await axios
				.put(`/globalprocesswithnobill`, {
					globId: "2",
					approve: "2",
					reason4Reject: rejectReason,
					recId: getdata?.id
				})
				.then((res) => res.data || {});

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: req.msg
			});

			setTimeout(() => history.goBack(), 3000);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setRejecting(false);
		}
	};

	const revoke = async (e) => {
		e.preventDefault();

		try {
			setRejecting(true);

			const req = await axios
				.put(`/globalprocesswithnobill`, {
					globId: "2",
					approve: "4",
					reason4Reject: rejectReason,
					recId: getdata?.id
				})
				.then((res) => res.data || {});

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: req.msg
			});

			setTimeout(() => history.goBack(), 3000);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setRejecting(false);
		}
	};

	return (
		<div>
			{loading ? (
				<p style={{ textAlign: "center" }}>Loading...</p>
			) : (
				<div>
					<GoBackButton />
					<div className={styles.container}>
						{alert.showing && (
							<Alert text={alert.message} type={alert.type} />
						)}
						<div className={styles.infoSection}>
							<h2>Company Name</h2>
							<p>
								Total P/Tax Amount:{" "}
								<strong>{getdata?.TotalAmountBilled}</strong>
							</p>
							<p>
								Bill Number:{" "}
								<strong>{getdata?.Bill_Number}</strong>
							</p>
							<p>
								Review Status: <strong></strong>
							</p>
							<p>
								Approval Status: <strong></strong>
							</p>
							<p>
								Payment Status:{" "}
								<strong>{getdata?.PaymentStatus}</strong>
							</p>
							<p>
								Process Status:{" "}
								<strong>
									{getProcessStatus(getdata?.ProcessStatus)}
								</strong>
							</p>
							<p>
								Type Of Assessment:{" "}
								<strong>{getdata?.File_Type}</strong>
							</p>
							<p>
								Total Purchases Amount:{" "}
								<strong>
									{getdata?.Total_Purchases_Amount}
								</strong>
							</p>
							<p>
								Generated By:{" "}
								<strong>{getdata?.GeneratedBy}</strong>
							</p>
							<p>
								Fiscal Year:{" "}
								<strong>{getdata?.Bill_Year}</strong>
							</p>
							<p>
								Start Date:{" "}
								<strong>{getdata?.Start_Date}</strong>
							</p>
							<p>
								End Date: <strong>{getdata?.End_Date}</strong>
							</p>
							<p>
								Outstanding:{" "}
								<strong>{getdata?.Outstanding}</strong>
							</p>
							<p>
								Outstanding Description:{" "}
								<strong>{getdata?.outstandingReason}</strong>
							</p>
							<p>
								Discount: <strong>{getdata?.Discount}</strong>
							</p>
							<p>
								Discount Description:{" "}
								<strong>{getdata?.DiscountReason}</strong>
							</p>
							<hr />
							<p>
								Employer's ESBN:{" "}
								<strong>{getdata?.ESBN}</strong>
							</p>
							<p>
								Sector: <strong>{getdata?.Sector}</strong>
							</p>
							<p>
								Company Name: <strong></strong>
							</p>
							<p>
								Address: <strong>{getdata?.Address}</strong>
							</p>
							<p>
								Phone Number: <strong>{getdata?.Phone}</strong>
							</p>
							<p>
								Email: <strong>{getdata?.Email}</strong>
							</p>
						</div>

						<div className={styles.infoSection}>
							<div className={styles.buttonContainer}>
								<button
									className={styles.viewButton}
									onClick={() => setShowModal(true)}
								>
									View Bill
								</button>
								{showModal && (
									<Modal
										show={showModal}
										onClose={() => setShowModal(false)}
									>
										<div
											style={{
												display: "flex",
												justifyContent: "flex-end",
												padding: "10px"
											}}
										>
											<button
												onClick={() => {
													const w = window.open(
														`${constants.BASE1_URL}/purchasingTax/getPTReceipt/${id}`
													);
													w.onload = () => {
														w.focus();
														w.print();
													};
													w.onafterprint = () => {
														w.close();
													};
												}}
												className={styles.downloadAll}
												style={{ marginBottom: "10px" }}
											>
												Print Bill
											</button>
										</div>
										<iframe
											src={`${constants.BASE1_URL}/purchasingTax/getPTReceipt/${id}`}
											style={{
												width: "100%",
												height: "100%",
												minHeight: "600px",
												border: "none",
												overflow: "auto"
											}}
											title="PT Receipt"
										/>
									</Modal>
								)}
							</div>
							<div className={styles.attachmentHeader}>
								<h3>Invoice Attachments</h3>
								<button
									onClick={handleDownloadAll}
									className={styles.downloadAll}
								>
									Download all
								</button>
							</div>

							<ul className={styles.attachmentList}>
								{files?.map((file, index) => (
									<li
										key={file.id}
										className={styles.attachmentItem}
									>
										<span className={styles.fileIcon}>
											📄
										</span>
										<span className={styles.fileName}>
											{file.FileName}
										</span>
									</li>
								))}
							</ul>
						</div>
					</div>
					<Table headers={header} data={tableData} full />
					{(getdata.ApproveFlg === "0" ||
						getdata.ApproveFlg === "3") && (
						<ApproveRejectWidget
							type={+getdata.ApproveFlg}
							positiveIdiotProofQuestion={`${getdata?.Bill_Number} serial number`}
							processingPositive={approving}
							positiveFunction={approve}
							processingNegative={rejecting}
							negativeIdiotProofQuestion={`${getdata?.Bill_Number} serial number`}
							negativeReasonMessage={`You will be ${
								getdata.ApproveFlg === "0"
									? "rejecting"
									: "revoking"
							} “${getdata?.Bill_Number}
                            ” Batch number, an email would be sent to notify them accordingly.`}
							negativeReason={rejectReason}
							setNegativeReason={setRejectReason}
							negativeFunction={
								getdata.ApproveFlg === "0" ? reject : revoke
							}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default PTReviewerView;
