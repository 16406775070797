import PAYEstyle from "../../PAYE/PAYE-Assessment/style.module.css";
import styles from "../style.module.css";
import styles3 from "../../../Identity-management-individual/style.module.css";

const ASSESSMENT_MODES = {
	MONTHLY: "MONTHLY",
	YEARLY: "YEARLY",
	PERIODICALLY: "PERIODICALLY"
};

export const EmployerAssessmentModeComponent = ({
	setEmployerAssementMode,
	employerAssessmentModeData
}) => {
	const handleAssementModeChange = (assessmentMode) => {
		// explicit defnition of employer assessment mode object with initialized empty data
		const assessmentModeObjForUpdate = {
			AssessmentMode: assessmentMode,
			AssessmentYear: "",
			AssessmentMonth: "",
			AssessmentStartPeriod: "",
			AssessmentEndPeriod: ""
		};
		setEmployerAssementMode((prev) => ({
			...prev,
			...assessmentModeObjForUpdate
		}));
	};

	const handleOnchange = (e) => {
		setEmployerAssementMode((prev) => ({
			...prev,
			[e.target.id]: e.target.value
		}));
	};

	return (
		<>
			<div className={`${PAYEstyle.radioBtns} ${styles.whtRadio}`}>
				<div className={PAYEstyle.radioBtn}>
					<input
						type="radio"
						id={ASSESSMENT_MODES.MONTHLY}
						value={ASSESSMENT_MODES.MONTHLY}
						checked={
							employerAssessmentModeData.AssessmentMode ===
							ASSESSMENT_MODES.MONTHLY
						}
						onChange={() =>
							handleAssementModeChange(ASSESSMENT_MODES.MONTHLY)
						}
					/>
					<label htmlFor="MONTHLY" required>
						Monthly
					</label>
				</div>
				<div className={PAYEstyle.radioBtn}>
					<input
						type="radio"
						id="YEARLY"
						value={ASSESSMENT_MODES.YEARLY}
						checked={
							employerAssessmentModeData.AssessmentMode ===
							ASSESSMENT_MODES.YEARLY
						}
						onChange={() =>
							handleAssementModeChange(ASSESSMENT_MODES.YEARLY)
						}
					/>
					<label htmlFor="YEARLY" required>
						Yearly
					</label>
				</div>
				<div className={PAYEstyle.radioBtn}>
					<input
						type="radio"
						id="PERIODICALLY"
						value={ASSESSMENT_MODES.PERIODICALLY}
						checked={
							employerAssessmentModeData.AssessmentMode ===
							ASSESSMENT_MODES.PERIODICALLY
						}
						onChange={() =>
							handleAssementModeChange(
								ASSESSMENT_MODES.PERIODICALLY
							)
						}
					/>
					<label htmlFor="PERIODICALLY" required>
						Period
					</label>
				</div>
			</div>

			<div
				className={`${styles3.form} ${PAYEstyle.fillingForm}`}
				style={{ marginBottom: "10px" }}
			>
				{employerAssessmentModeData.AssessmentMode ===
					ASSESSMENT_MODES.MONTHLY && (
					<div className={`${styles3.double__inputs}`}>
						<div>
							<label
								htmlFor="AssessmentYear"
								className={styles3.input__label}
							>
								Bill Year (Fiscal Year)
								<span style={{ color: "red" }}> *</span>
							</label>
							<select
								id="AssessmentYear"
								value={
									employerAssessmentModeData.AssessmentYear
								}
								onChange={handleOnchange}
								required
							>
								<option value="">Select year</option>
								{new Array(10).fill(0).map((_, i) => {
									const year = new Date().getFullYear() - i;
									return (
										<option key={year} value={year}>
											{year}
										</option>
									);
								})}
							</select>
						</div>
						<div>
							<label
								htmlFor="AssessmentMonth"
								className={styles3.input__label}
							>
								Month of Assessment
								<span style={{ color: "red" }}> *</span>
							</label>
							<select
								id="AssessmentMonth"
								value={
									employerAssessmentModeData.AssessmentMonth
								}
								onChange={handleOnchange}
							>
								<option>select Month</option>
								{[...Array(12)].map((_, index) => (
									<option
										key={index}
										value={new Intl.DateTimeFormat("en", {
											month: "long"
										}).format(new Date(2000, index, 1))}
									>
										{new Intl.DateTimeFormat("en", {
											month: "long"
										}).format(new Date(2000, index, 1))}
									</option>
								))}
							</select>
						</div>
					</div>
				)}
				{employerAssessmentModeData.AssessmentMode ===
					ASSESSMENT_MODES.YEARLY && (
					<div className={`${styles3.double__inputs}`}>
						<div>
							<label
								htmlFor="AssessmentYear"
								className={styles3.input__label}
							>
								Bill Year (Fiscal Year)
								<span style={{ color: "red" }}> *</span>
							</label>
							<select
								id="AssessmentYear"
								value={
									employerAssessmentModeData.AssessmentYear
								}
								onChange={handleOnchange}
								required
							>
								<option value="">Select year</option>
								{new Array(10).fill(0).map((_, i) => {
									const year = new Date().getFullYear() - i;
									return (
										<option key={year} value={year}>
											{year}
										</option>
									);
								})}
							</select>
						</div>
					</div>
				)}
				{employerAssessmentModeData.AssessmentMode ===
					ASSESSMENT_MODES.PERIODICALLY && (
					<>
						<div className={`${styles3.double__inputs}`}>
							<div>
								<label
									htmlFor="AssessmentYear"
									className={styles3.input__label}
								>
									Bill Year (Fiscal Year)
									<span style={{ color: "red" }}> *</span>
								</label>
								<select
									id="AssessmentYear"
									value={
										employerAssessmentModeData.AssessmentYear
									}
									onChange={handleOnchange}
									required
								>
									<option value="">Select year</option>
									{new Array(10).fill(0).map((_, i) => {
										const year =
											new Date().getFullYear() - i;
										return (
											<option key={year} value={year}>
												{year}
											</option>
										);
									})}
								</select>
							</div>
						</div>
						<div className={`${styles3.double__inputs}`}>
							<div>
								<label
									htmlFor="AssessmentStartPeriod"
									className={styles3.input__label}
								>
									Start Month of Assessment
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									type="date"
									id="AssessmentStartPeriod"
									value={
										employerAssessmentModeData.AssessmentStartPeriod
									}
									onChange={handleOnchange}
								/>
							</div>
							<div>
								<label
									htmlFor="AssessmentEndPeriod"
									className={styles3.input__label}
								>
									End Month of Assessment
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									type="date"
									id="AssessmentEndPeriod"
									value={
										employerAssessmentModeData.AssessmentEndPeriod
									}
									onChange={handleOnchange}
								/>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
};
