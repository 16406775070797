import { useState, useEffect, createRef } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import Table from "../../../components/Table";
import styles from "../../MDAs/style.module.css";
import styles1 from "../../MDAs/style.module.css";
import anssidStyles from "./style.module.css";
import MDAstyles from "../../../Dashboard/MDAs/style.module.css";
import { exportExcel, remapObj } from "../../../utils";
import Alert from "../../../components/Alert";

const coy_ANSSID = process.env.REACT_APP_ANSSID;

const Consolidated = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [anssid, setAnssid] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);
	const [total, setTotal] = useState(0);
	const [staffName, setStaffName] = useState("");
	const [billNumber, setBillNumber] = useState("");
	const [fiscalYear, setFiscalYear] = useState("");
	const [taxOffice, setTaxOffice] = useState("");
	const [fiscalMonth, setFiscalMonth] = useState("");
	const [ProcessStatus, setProcessStatus] = useState("");
	const [PaymentStatus, setPaymentStatus] = useState("");
	const [Inputter, setInputter] = useState("");
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const filterRef = createRef();
	const headers = [
		[`Name`, "Name"],
		["Bill Number", "BillNumber"],
		["ESBN_PID", "ESBN_PID"],
		["Fiscal Month", "FiscalMonth"],
		["Fiscal Year", "FiscalYear"],
		["Tax Office", "TaxOffice"],
		["Process status", "ProcessStatus"],
		["Payment status", "PaymentStatus"],
		["Grand Total", "GrandTotal"]
	];

	const getAssessmentRecords =
		({ currentPage = 1, pageSize = 20, download = false } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);

			if (!filterRef.current?.classList.contains(styles1.no__display)) {
				filterRef.current?.classList.toggle(styles1.no__display);
			}

			let query = `page=${currentPage}&pageSize=${pageSize}&`;
			if (staffName) {
				query += `Name=${staffName}&`;
			}

			if (anssid) {
				query += `ESBN=${anssid}&`;
			}

			if (billNumber) {
				query += `BillNo=${billNumber}&`;
			}

			if (taxOffice) {
				query += `taxoffice=${taxOffice}&`;
			}
			if (fiscalYear) {
				query += `year=${fiscalYear}&`;
			}
			if (fiscalMonth) {
				query += `mth=${fiscalMonth}&`;
			}
			if (PaymentStatus) {
				query += `pymtStat=${PaymentStatus}&`;
			}
			if (ProcessStatus) {
				query += `procStat=${ProcessStatus}`;
			}
			if (Inputter) {
				query += `Inputter=${Inputter}&`;
			}
			if (startDate) {
				query += `startdate=${startDate}&`;
			}

			if (endDate) {
				query += `enddate=${endDate}&`;
			}
			try {
				const accessToken = localStorage.getItem("access_token");

				let { data, total } = await axios
					.get(`/mdareports/consolbilldisplay?${query}`, {
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					})
					.then((res) => res.data || { data: {} });

				if (!data) {
					// handle errors
					return null;
				}

				if (!download) setData(data);
				setTotal(total);

				return data;
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.message
					});
				}
			} finally {
				setLoading(false);
			}
		};

	useEffect(() => {
		getAssessmentRecords()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		getAssessmentRecords()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const clearFilters = () => {
		setStaffName("");
		setAnssid("");
		setBillNumber("");
		setTaxOffice("");
		setFiscalYear("");
		setFiscalMonth("");
		setProcessStatus("");
		setPaymentStatus("");
		setStartDate("");
		setEndDate("");
		setInputter("");

		filterRef.current.classList.toggle(styles.no__display);
		setFiltersCleared(true);
	};

	const exportAnalytics = async () => {
		const info = await getAssessmentRecords({
			currentPage: 1,
			pageSize: total,
			download: true
		})();
		if (!info)
			exportExcel(remapObj(info, headers), headers, `CDN Report.xlsx`);
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<div className={styles.MDA__page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<div className={styles.actions__div}>
				<button
					className={["primary__btn", anssidStyles.create__btn].join(
						" "
					)}
					onClick={() => {
						history.push(
							"/demand-notice/generate-consoldemandnotice",
							{
								taxpayers: true,
								fromIndividual: true,
								ssActive: "1"
							}
						);
					}}
				>
					Create CDN
				</button>
				<button
					className={[
						"secondary__btn",
						MDAstyles.download__button
					].join(" ")}
					onClick={() => exportAnalytics()}
					// disabled={loading}
				>
					Download Report
				</button>
				<div className={styles.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles.no__display
							);
						}}
						className={styles.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles.filter__div,
							styles.no__display
						].join(" ")}
					>
						<div className={styles.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles.clear__filter_fields}
							onClick={clearFilters}
							disabled={
								!staffName &&
								!anssid &&
								!startDate &&
								!endDate &&
								!billNumber &&
								!fiscalYear &&
								!fiscalMonth &&
								!ProcessStatus &&
								!PaymentStatus &&
								!Inputter &&
								!taxOffice
							}
						>
							Clear Filter fields
						</button>
						<p>By Code</p>
						<form>
							<div>
								<label htmlFor="anssid">{coy_ANSSID}</label>
								<input
									name="anssid"
									id="anssid"
									type="text"
									value={anssid}
									onChange={(e) => setAnssid(e.target.value)}
									placeholder={coy_ANSSID}
								/>
							</div>
						</form>
						<p>By Identifier </p>
						<form>
							<div>
								<label htmlFor="staffName"> Name</label>
								<input
									name="staffName"
									id="staffName"
									type="text"
									value={staffName}
									onChange={(e) =>
										setStaffName(e.target.value)
									}
									placeholder="Eg Odogwu Okeke"
								/>
							</div>
							<div>
								<label htmlFor="billNumber">Bill Number</label>
								<input
									name="billNumber"
									id="billNumber"
									type="text"
									value={billNumber}
									onChange={(e) =>
										setBillNumber(e.target.value)
									}
									placeholder="Enter Bill Number"
								/>
							</div>
							<div>
								<label htmlFor="taxOffice"> Tax Office</label>
								<input
									name="taxOffice"
									id="taxOffice"
									type="text"
									value={taxOffice}
									onChange={(e) =>
										setTaxOffice(e.target.value)
									}
									placeholder="Enter Tax Office"
								/>
							</div>
						</form>
						<p>By Status</p>
						<form>
							<div>
								<label htmlFor="PaymentStatus">
									Payment status
								</label>
								<select
									name="PaymentStatus"
									id="PaymentStatus"
									type="text"
									value={PaymentStatus}
									onChange={(e) =>
										setPaymentStatus(e.target.value)
									}
									placeholder="Payment Status"
								>
									<option value="">
										{" "}
										--select payment status--
									</option>
									<option>Paid</option>
									<option>Pending</option>
									<option>Revoked</option>
								</select>
							</div>
							<div>
								<label htmlFor="ProcessStatus">
									Process Status
								</label>
								<select
									name="ProcessStatus"
									id="ProcessStatus"
									type="text"
									value={ProcessStatus}
									onChange={(e) =>
										setProcessStatus(e.target.value)
									}
									placeholder="Process Status"
								>
									<option value="">
										--select process status--
									</option>
									<option>Approved</option>
									<option>Pending</option>
									<option>Revoked</option>
								</select>
							</div>
						</form>
						<p>By Period</p>
						<form>
							<div>
								<label htmlFor="fiscalYear">Fiscal Year</label>
								<input
									name="fiscalYear"
									id="fiscalYear"
									type="text"
									value={fiscalYear}
									onChange={(e) =>
										setFiscalYear(e.target.value)
									}
									placeholder="fiscalYear"
								/>
							</div>
							<div>
								<label htmlFor="fiscalMonth">
									Fiscal Month
								</label>
								<input
									name="fiscalMonth"
									id="fiscalMonth"
									type="text"
									value={fiscalMonth}
									onChange={(e) =>
										setFiscalMonth(e.target.value)
									}
									placeholder="fiscalMonth"
								/>
							</div>
						</form>
						<p>By Inputter</p>
						<form>
							<div>
								<label htmlFor="Inputter">Inputter</label>
								<input
									name="Inputter"
									id="Inputter"
									type="email"
									value={Inputter}
									onChange={(e) =>
										setInputter(e.target.value)
									}
									placeholder="Eg. ada@example.com"
								/>
							</div>
						</form>

						<p>By Creation Date</p>
						<form>
							<div>
								<label htmlFor="start_date">Start Date</label>
								<input
									name="start_date"
									id="start_date"
									type="date"
									value={startDate}
									onChange={(e) =>
										setStartDate(e.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="start_date">End Date</label>
								<input
									name="end_date"
									id="end_date"
									type="date"
									value={endDate}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
						</form>
						<button
							className={[
								styles.submit__button,
								"primary__btn"
							].join(" ")}
							onClick={getAssessmentRecords()}
						>
							Search
						</button>
					</div>
				</div>
			</div>
			<div className={styles.table__div}>
				<Table
					headers={headers}
					loading={loading}
					data={data}
					full
					pageSize={20}
					total={total}
					onPageChanged={getAssessmentRecords}
					onEdit={(data) => {
						history.push(
							`/demand-notice/generate-consoldemandnotice`,
							{
								...data,
								billno: data.BillNumber,
								edit: true
							}
						);
					}}
					onView={(data) => {
						history.push(`/demand-notice/ConsolidatedView`, {
							...data
							// edit: true
						});
					}}
				/>
			</div>
		</div>
	);
};

export default Consolidated;
