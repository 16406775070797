import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import styles from "../../Identity-management-individual/style.module.css";
import styles2 from "../../Direct-assessment/Create-direct-assessment/style.module.css";
import axios from "axios";
import Table from "../../components/Table";
import constants from "../../constants";
import Modal from "./modal/modal";
import Receipt from "../Receipt/receipt";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Alert from "../../components/Alert";

const ENSSAAPrepareBil = () => {
	const history = useHistory();
	//header
	const headers = [
		["S/N", "SN"],
		["Sub-category", "SubCategory"],
		["Bill Location/Signage", "Address"],
		["Branch Name", "Branch"],
		["Branch Address", "Address"],
		["Faces", "Faces"],
		["units", "Units"],
		["Application Fee", "ApplicationFee"],
		["Inspection Fee", "InspectionFee"],
		["Registration/Renewal Fee", "RegistrationFee"],
		["Rate", "Rate"]
	];

	const header = [
		["S/N", "SN"],
		["Receipt no.", "EReceiptNo"],
		["Bank Name", "BankName"],
		["issued to", "CustomerName"],
		["amount Paid (₦)", "Amount"],
		["Balance (₦)", "Amount"],
		["Payment Date", "PaymentDate"],
		["Revenue Source", "RevenueCode"]
	];
	const [ensaaInfo, setEnsaaInfo] = useState({
		Year: "",
		Month: " ",
		ESBN: "",
		Name: "",
		PhoneNumber: "",
		Email: "",
		Address: " ",
		ModeOfBill: "",
		Discount: 0,
		DiscountReason: "",
		Sector: ""
	});
	const [fetchingESBNDetails, setFetchingESBNDetails] = useState(false);
	const [sectors, setSectors] = useState([]);
	const [categories, setCategories] = useState([]);
	const [revenue, setRevenue] = useState("");
	const [category, setCategory] = useState("");
	const [type, setType] = useState(" ");
	const [subcategory, setSubCategory] = useState("");
	const [rate, setRate] = useState(0);
	const [branchname, setBranchName] = useState("");
	const [branchaddress, setBranchAddress] = useState("");
	const [receiptNo, setReceiptNo] = useState("");
	const [getprepay, setGetPrePay] = useState("");
	const [units, setUnits] = useState(0);
	const [bLocation, setBillLocation] = useState("");
	const [faces, setFaces] = useState();
	const [subcategories, setSubcategories] = useState([]);
	const [code, setCode] = useState();
	const [print, setPrint] = useState("");
	const [getcategories, setgetcategories] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [imageData, setImageData] = useState("");
	const [billType, setBillType] = useState(" ");
	const [getprepay2, setGetPrePay2] = useState([]);
	const [revenuecode, setRevenueCode] = useState([]);
	const [fixedFees, setFixedFees] = useState(null); // State for enssaaFixedFees
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const recieptopenModal = () => setShowModal(true);
	const recieptcloseModal = () => setShowModal(false);

	// Handle input change for company info
	const handleensaaInfoChange = (e) => {
		const { name, value } = e.target;

		// Ensure that discount and outstanding are stored as numbers
		if (name === "discount" || name === "outstanding") {
			// Allow only numeric input and handle empty input
			if (value === "" || (!isNaN(value) && Number(value) >= 0)) {
				setEnsaaInfo((prevState) => ({
					...prevState,
					[name]: value === "" ? "" : Number(value) // Convert to number if not empty
				}));
			}
		} else {
			setEnsaaInfo((prevState) => ({
				...prevState,
				[name]: value
			}));
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const printReceipt = (e) => {
		e.preventDefault();

		const w = window.open();
		w.document.write(print);
		w.document.close();
		w.onload = () => {
			w.focus();
			w.print();
		};

		// calculationResult("");

		w.onafterprint = () => {
			w.close();
		};
	};

	//getting sector
	useEffect(() => {
		const getSector = async () => {
			try {
				const res = await axios.get(`/settings/economicsector`);
				setSectors(res.data?.data); // Assuming res.data is an array of sectors
			} catch (error) {
				console.error("Error fetching sectors:", error);
			}
		};
		getSector();
	}, []);

	// Fetch ESBN for customer data (email, phone, address)
	useEffect(() => {
		const getESBN = async () => {
			setFetchingESBNDetails(true);
			try {
				const res = await axios
					.get(`users/anssid?anssid=${ensaaInfo.ESBN}`)
					.then((res) => (res.data || {}).data);

				// Update companyInfo state with fetched data
				setEnsaaInfo((prevState) => ({
					...prevState,
					Email: res.email || "", // Update email
					PhoneNumber: res.phonenumber || res.phoneNumber || "", // Update phone
					Address:
						res.residentialaddress || res.stateOfficeAddress || "", // Update address
					Name:
						res.name ||
						`${res.firstname || ""} ${res.surname || ""}`.trim() // Prefer res.name or fallback to constructed name
				}));
			} catch (error) {
				console.error("Error fetching ESBN data:", error);
			} finally {
				setFetchingESBNDetails(false);
			}
		};

		// Trigger fetch if ESBN is 10 or more characters
		if (ensaaInfo.ESBN?.length >= 7) getESBN();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ensaaInfo.ESBN]);

	//get prepayment
	useEffect(() => {
		const getPrepayment = async () => {
			try {
				const res = await axios.get(
					`${constants.BASE_URL}/gettmspayref/${receiptNo}`
				);
				setGetPrePay(res.data.data);
				setGetPrePay2((prev) => [...prev, res.data.data]);
				console.log("getprepay", res.data.data);
			} catch (error) {
				setAlert({
					showing: true,
					type: "error",
					message:
						error?.response.data?.msg ||
						error.response.data?.errors?.[0]?.error ||
						error.response.data?.errors?.details[0]?.message
				});
			}
		};
		if (receiptNo?.length >= 6) getPrepayment();
	}, [receiptNo]);

	// Handle radio button change
	const handleRadioChange = (e) => {
		const selectedType = e.target.value;
		setType(selectedType);

		// If "Renewal" is selected, access the billboard_renew_fee
		if (selectedType === "Renewal" && fixedFees) {
			const renewFee = fixedFees.billboard_renew_fee;
			console.log("Renewal Fee:", renewFee);
		}
		if (selectedType === "Registration" && fixedFees) {
			const renewFee = fixedFees.billboard_reg_fee;
			console.log("Registration Fee:", renewFee);
		}

		if (selectedType === "Inspection" && fixedFees) {
			const renewFee = fixedFees.billboard_inspection_reg_fee;
			console.log("Inspection Fee:", renewFee);
		}
		if (selectedType === "Application" && fixedFees) {
			const renewFee = fixedFees.billboard_appl_fee;
			console.log("Registration Fee:", renewFee);
		}

		// Update newSubCats with the appropriate fees
		setType(selectedType);
		// updateNewSubCats(selectedType);
	};

	const isRateDisabled = type !== ""; // Check if type is not empty

	const handleAddSubcats = (e) => {
		e.preventDefault();
		const newSubCats = {
			SN: categories.length + 1,
			Code: code,
			Category: category,
			SubCategory: subcategory,
			ApplicationFee:
				type === "Registration"
					? fixedFees?.billboard_appl_fee || 0
					: type === "Renewal"
						? fixedFees?.billboard_appl_fee || 0
						: type === "Application"
							? fixedFees?.billboard_appl_fee || 0
							: 0,
			InspectionFee:
				type === "Registration"
					? fixedFees?.billboard_inspection_reg_fee || 0
					: type === "Renewal"
						? fixedFees?.billboard_inspection_renew_fee || 0
						: 0,
			RegistrationFee:
				type === "Registration"
					? fixedFees?.billboard_reg_fee || 0
					: type === "Renewal"
						? fixedFees?.billboard_renew_fee || 0
						: 0,

			Revenue: revenue,
			Faces: faces || 0,
			Units: units || 0,
			Rate: rate,
			Branch: branchname || "N/A",
			Address: branchaddress || bLocation || "N/A",
			Amount: isRateDisabled ? 0 : rate,
			Total: 0
		};

		console.log("categories", newSubCats);
		setCategories([...categories, newSubCats]); // Add the new category to the categories array
		// Clear input fields after adding
		setSubCategory("");
		setType("");
		setRate("");
		setBranchName("");
		setBranchAddress("");
		setRate("");
		setBillLocation("");
		setFaces("");
		setUnits("");
	};

	//delete goods function
	const handleDeleteCategory = (tr) => {
		const updatedCategories = categories.filter(
			(prod) => prod.SN !== tr.SN
		);
		setCategories(updatedCategories);
	};

	//delete functions for prepayment
	const onDeletePrePay = (index) => {
		const updatedPrePay = getprepay2.filter((item) => item.id !== index.id);
		setGetPrePay(updatedPrePay);
	};

	const getCategoryInfo = async () => {
		const res = await axios.get(`/settings/profilecategorys/12`);
		if (res) {
			const data = res.data?.data;
			const enssaaFixedFees = res.data?.enssaaFixedFees;
			// Filter out the categories with Code 001, 010, 011
			const filteredData = data.filter(
				(category) =>
					!["004", "011", "006"].includes(category.Code.toUpperCase())
			);
			setgetcategories(filteredData);
			setFixedFees(enssaaFixedFees);

			console.log("filteredData", filteredData);
		}
	};
	useEffect(() => {
		getCategoryInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const options = [
		{ id: 1, name: "Registration", type: "REGISTRATION" },
		{ id: 2, name: "Renewal", type: "RENEWAL" }
	];

	//subcategory
	useEffect(() => {
		const getSubCategory = async () => {
			try {
				const res = await axios.get(
					`${constants.BASE_URL}/categorytarrifcommerces/12/${category}`
				);
				if (res) {
					const data = res.data?.data || [];
					setSubcategories(data);
					console.log("data", data);

					// Calculate the total FixedFee
					const selectedItem = data.find(
						(item) => item.Description === subcategory
					);
					if (selectedItem) {
						setSubCategory(selectedItem.Description);
					}
					const totalFixedFee = selectedItem?.FixedFee || 0;
					const findCode = selectedItem?.Code || "";
					// setRegistrationFee(totalFixedFee);
					setRate(totalFixedFee);
					setCode(findCode);

					console.log("hello test", totalFixedFee);
					console.log("gett good", findCode);
				}
			} catch (error) {
				console.error("Error fetching subcategories:", error);
				setSubcategories([]);
				// setRegistrationFee(0);
				setCode(0);
			}
		};

		if (category) {
			getSubCategory();
		}
	}, [category, subcategory]);

	const handleAddCategory = async (e) => {
		e.preventDefault();

		const payload = {
			...ensaaInfo,
			Category: billType,
			PrePayments: [getprepay],
			Categories: [...categories]
			// Type: type
		};
		try {
			const response = await axios.post(
				`${constants.BASE_URL_2}/enssaa/create`,
				payload
			);
			if (response.status === 200) {
				const applicationId = response.data?.data?.SRN;
				try {
					const receiptResponse = await axios.get(
						`${constants.BASE_URL_2}/enssaa/receipt/${applicationId}`
					);
					setImageData(receiptResponse.data);
					setPrint(receiptResponse.data);
					if (receiptResponse.status === 200) {
						recieptopenModal(); // Open the modal upon successful response
					}
				} catch (error) {
					console.error("Error fetching receipt:", error);
				}
			}
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message:
					error?.response.data?.message ||
					error.response.data?.errors?.[0]?.error ||
					error.response.data?.errors?.details[0]?.message
			});
		}
	};

	const getRevenueCode = async () => {
		const resp = await axios.get(`/settings/mdarevenue`);
		const data = resp?.data?.data;

		// Keep only the specific codes
		const filteredData = data.filter((revenue) =>
			[
				"35001003/12040036",
				"35001003/12040457",
				"35001003/12040456"
			].includes(revenue.Code)
		);

		console.log("resp => ", resp);
		console.log("filteredData => ", filteredData);

		setRevenueCode(filteredData);
	};

	useEffect(() => {
		getRevenueCode();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<form className={styles.columned__form}>
				{alert.showing && (
					<Alert text={alert.message} type={alert.type} />
				)}
				<h1 className={style.h1}>ENSSAA Prepare Bill</h1>
				<hr />
				<h2 className={styles.input__label}>
					Mode Of Bill <span style={{ color: "red" }}>*</span>{" "}
				</h2>
				<div className={styles2.radio__button_div}>
					<div>
						<div>
							<input
								id="YEARLY"
								type="radio"
								value="YEARLY"
								checked={ensaaInfo.ModeOfBill === "YEARLY"}
								onChange={handleensaaInfoChange}
								name="ModeOfBill" // Ensure the name matches
							/>
							<label className={styles.input__label}>
								Yearly
							</label>
						</div>
						<div>
							<input
								id="MONTHLY"
								type="radio"
								value="MONTHLY"
								checked={ensaaInfo.ModeOfBill === "MONTHLY"}
								onChange={handleensaaInfoChange}
								name="ModeOfBill" // Ensure the name matches
							/>
							<label className={styles.input__label}>
								Monthly
							</label>
						</div>
					</div>
				</div>
				{ensaaInfo.ModeOfBill === "YEARLY" && (
					<div className={styles.double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="Year"
							>
								Year (Fiscal Year)
								<span style={{ color: "red" }}> *</span>
							</label>
							<select
								name="Year"
								value={ensaaInfo.Year}
								onChange={handleensaaInfoChange}
								required
							>
								<option value="">Select a year</option>
								{Array.from({ length: 100 }, (_, i) => {
									const year =
										new Date().getFullYear() + 1 - i;
									return (
										<option key={year} value={year}>
											{year}
										</option>
									);
								})}
							</select>
						</div>
					</div>
				)}
				{ensaaInfo.ModeOfBill === "MONTHLY" && (
					<div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="Year"
								>
									Bill Year (Fiscal Year)
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									name="Year"
									value={ensaaInfo.Year}
									onChange={handleensaaInfoChange}
									required
								>
									<option value="">Select a year</option>
									{Array.from({ length: 100 }, (_, i) => {
										const year =
											new Date().getFullYear() + 1 - i;
										return (
											<option key={year} value={year}>
												{year}
											</option>
										);
									})}
								</select>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="Month"
								>
									Month of Assessment
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									name="Month"
									value={ensaaInfo.Month}
									onChange={handleensaaInfoChange}
									required
								>
									<option value="">Select a month</option>
									{[
										"January",
										"February",
										"March",
										"April",
										"May",
										"June",
										"July",
										"August",
										"September",
										"October",
										"November",
										"December"
									].map((month) => (
										<option key={month} value={month}>
											{month}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
				)}
				<hr />
				<div className={styles.double__inputs}>
					<div>
						<label className={styles.input__label} htmlFor="ESBN">
							Applicant ESBN
							<span style={{ color: "red" }}> *</span>
						</label>
						<input
							type="text"
							id="ESBN"
							name="ESBN"
							placeholder="Enter ESBN"
							value={ensaaInfo.ESBN}
							onChange={handleensaaInfoChange}
						/>
						{/* Loading Indicator */}
						{fetchingESBNDetails && (
							<span
								style={{
									fontSize: "12px",
									margin: "-19px 0 0",
									color: "#9e9e9e"
								}}
							>
								Fetching details...
							</span>
						)}
					</div>
					<div>
						<label className={styles.input__label} htmlFor="Name">
							Name
							<span style={{ color: "red" }}> *</span>
						</label>
						<input
							type="text"
							s
							id="Name"
							name="Name"
							placeholder="Enter Name"
							value={ensaaInfo.Name}
							onChange={handleensaaInfoChange}
						/>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="PhoneNumber"
						>
							Phone Number
							<span style={{ color: "red" }}> *</span>
						</label>
						<input
							type="text"
							name="PhoneNumber"
							id="PhoneNumber"
							placeholder="Enter your phone Number"
							value={ensaaInfo.PhoneNumber}
							onChange={handleensaaInfoChange}
						/>
					</div>
					<div>
						<label className={styles.input__label} htmlFor="Email">
							Email
							<span style={{ color: "red" }}> *</span>
						</label>
						<input
							type="text"
							name="Email"
							id="Email"
							placeholder="Enter Email"
							value={ensaaInfo.Email}
							onChange={handleensaaInfoChange}
						/>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<label className={styles.input__label} htmlFor="Sector">
							Sector
							<span style={{ color: "red" }}> *</span>
						</label>
						<select
							name="Sector"
							value={ensaaInfo.Sector}
							onChange={handleensaaInfoChange} // Update ensaaInfo.sector on selection
							required
						>
							<option value="">Select a sector</option>
							{sectors &&
								sectors.map((sector) => (
									<option
										key={sector.Code}
										value={sector.Code}
									>
										{" "}
										{/* Add key here */}
										{sector.Description}
									</option>
								))}
						</select>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="Address"
						>
							Address
							<span style={{ color: "red" }}> *</span>
						</label>
						<input
							id="Address"
							name="Address"
							placeholder="Enter Address"
							value={ensaaInfo.Address}
							onChange={handleensaaInfoChange}
						/>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="billType"
						>
							Bill Type
							<span style={{ color: "red" }}> *</span>
						</label>
						<select
							onChange={(e) => setBillType(e.target.value)}
							value={billType}
						>
							<option value="">Select Bill Type</option>
							<option value="CORPORATE">CORPORATE</option>
							<option value="LAMPOST">LAMPOST</option>
							<option value="A-SIGN">A-SIGN</option>
							<option value="SIGNAGE">SIGNAGE</option>
							<option value="BILLBOARD">BILLBOARD</option>
							<option value="ROADSHOW">ROADSHOW</option>
							<option value="OTHERS">OTHERS</option>
							<option value="BRANDED_VEHICLE">
								BRANDED VEHICLE
							</option>
						</select>
					</div>
				</div>
				<hr />
				<h3 className={style.title}>Category Detail</h3>
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="Category"
						>
							Category
							<span style={{ color: "red" }}> *</span>
						</label>
						<select
							name="Category"
							value={category}
							onChange={(e) => setCategory(e.target.value)} // Update ensaaInfo.sector on selection
							required
						>
							<option value="">
								{getcategories
									? "--Select Category--"
									: "...Loading"}
							</option>
							{getcategories &&
								getcategories.map((getcategories) => (
									<option
										key={getcategories.Code}
										value={getcategories.Code}
									>
										{getcategories.Description}
									</option>
								))}
						</select>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="Revenue"
						>
							Revenue
							<span style={{ color: "red" }}> *</span>
						</label>
						<select
							id="revenue"
							name={revenue}
							onChange={(e) => {
								setRevenue(e.target.value);
							}}
							value={revenue}
							required
						>
							<option>--Select Revenue--</option>
							{revenuecode.map((rev) => (
								<option value={rev.Code} key={rev.Code}>
									{rev.Description}
								</option>
							))}
						</select>
					</div>
				</div>
				<hr />
				{/* Lampost Signs (Monthly Billing) */}
				{category === "007" && (
					<div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="subcat"
								>
									Sub Category
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									name="subcat"
									value={subcategory}
									onChange={(e) =>
										setSubCategory(e.target.value)
									}
								>
									<option>
										{subcategories
											? "--Select --Sub Category"
											: "...Loading"}
									</option>
									{subcategories &&
										subcategories.map((subcategories) => (
											<option
												// key={subcategories.Code}
												value={
													subcategories.Description
												}
											>
												{subcategories.Description}
											</option>
										))}
								</select>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="Rate"
								>
									Rate (₦)
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="Rate"
									name="Rate"
									placeholder="Enter rate"
									value={rate}
									onChange={(e) => setRate(e.target.value)}
								/>
							</div>
						</div>

						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="units"
								>
									Units
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="units"
									name="units"
									placeholder="Enter Units"
									value={units}
									onChange={(e) => setUnits(e.target.value)}
								/>
							</div>
						</div>
					</div>
				)}
				{/* Asign (Daily Billing) */}
				{category === "001" && (
					<div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="subcat"
								>
									Sub Category
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									name="subcat"
									value={subcategory}
									onChange={(e) =>
										setSubCategory(e.target.value)
									}
								>
									<option>
										{subcategories
											? "--Select --Sub Category"
											: "...Loading"}
									</option>
									{subcategories &&
										subcategories.map((subcategories) => (
											<option
												// key={subcategories.Code}
												value={
													subcategories.Description
												}
											>
												{subcategories.Description}
											</option>
										))}
								</select>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="Rate"
								>
									Rate (₦)
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="Rate"
									name="Rate"
									placeholder="Enter rate"
									value={rate}
									onChange={(e) => setRate(e.target.value)}
								/>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="Rate"
								>
									Unit
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="unit"
									name="unit"
									placeholder="Enter unit"
									value={units}
									onChange={(e) => setUnits(e.target.value)}
								/>
							</div>
						</div>
					</div>
				)}
				{/* others */}
				{category === "009" && (
					<div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="subcat"
								>
									Sub Category
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									name="subcat"
									value={subcategory}
									onChange={(e) =>
										setSubCategory(e.target.value)
									}
								>
									<option>
										{subcategories
											? "--Select --Sub Category"
											: "...Loading"}
									</option>
									{subcategories &&
										subcategories.map((subcategories) => (
											<option
												// key={subcategories.Code}
												value={
													subcategories.Description
												}
											>
												{subcategories.Description}
											</option>
										))}
								</select>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="Rate"
								>
									Rate (₦)
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="Rate"
									name="Rate"
									placeholder="Enter rate"
									value={rate}
									onChange={(e) => setRate(e.target.value)}
								/>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="Rate"
								>
									Unit
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="unit"
									name="unit"
									placeholder="Enter unit"
									value={units}
									onChange={(e) => setUnits(e.target.value)}
								/>
							</div>
						</div>
					</div>
				)}
				{/* Corporate */}
				{category === "005" && (
					<div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="subcat"
								>
									Sub Category
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									name="subcat"
									value={subcategory}
									onChange={(e) =>
										setSubCategory(e.target.value)
									}
								>
									<option>
										{subcategories
											? "--Select --Sub Category"
											: "...Loading"}
									</option>
									{subcategories &&
										subcategories.map((subcategories) => (
											<option
												value={
													subcategories.Description
												}
											>
												{subcategories.Description}
											</option>
										))}
								</select>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="type"
								>
									Type
									<span style={{ color: "red" }}> *</span>
								</label>
								<select
									className="border p-2 rounded"
									value={type}
									onChange={(e) => setType(e.target.value)}
								>
									<option value="">Select Type</option>
									{options.map((item) => (
										<option key={item.id} value={item.type}>
											{item.name}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="Rate"
								>
									Rate (₦)
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="Rate"
									name="Rate"
									placeholder="Enter rate"
									value={rate}
									onChange={(e) => setRate(e.target.value)}
								/>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="type"
								>
									Branch Name
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="branchname"
									name="branchname"
									placeholder="Enter branch name"
									value={branchname}
									onChange={(e) =>
										setBranchName(e.target.value)
									}
								/>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="branchaddress"
								>
									Branch Address
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="branchaddress"
									name="branchaddress"
									placeholder="Enter branch address"
									value={branchaddress}
									onChange={(e) =>
										setBranchAddress(e.target.value)
									}
								/>
							</div>
						</div>
					</div>
				)}
				{/* Billboard (The Annual Rates Is By Face) */}
				{category === "002" && (
					<div>
						<div>
							<h3>Sub-Category</h3>
							<p style={{ color: "red" }}>
								Fill in all the fields. For example, if you
								select "Application," ensure you include all the
								addresses you’re paying the application fees to,
								and so on.
							</p>

							<div className={styles2.radio__button_div}>
								<div>
									<div>
										<input
											id="Registration"
											type="radio"
											value="Registration"
											checked={type === "Registration"}
											onChange={handleRadioChange}
											name="type"
										/>
										<label className={styles.input__label}>
											Registration
										</label>
									</div>
									<div>
										<input
											id="Renewal"
											type="radio"
											value="Renewal"
											checked={type === "Renewal"}
											onChange={handleRadioChange}
											name="type"
										/>
										<label className={styles.input__label}>
											Renewal
										</label>
									</div>
								</div>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="subcat"
								>
									Sub Category
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									name="subcat"
									value={subcategory}
									onChange={(e) =>
										setSubCategory(e.target.value)
									}
								>
									<option>
										{subcategories
											? "--Select --Sub Category"
											: "...Loading"}
									</option>
									{subcategories &&
										subcategories.map((subcategories) => (
											<option
												// key={subcategories.Code}
												value={
													subcategories.Description
												}
											>
												{subcategories.Description}
											</option>
										))}
								</select>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="Rate"
								>
									Rate (₦)
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="Rate"
									name="Rate"
									placeholder="Enter rate"
									value={rate}
									disabled={type !== ""}
									onChange={(e) => setRate(e.target.value)}
								/>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="billlocation"
								>
									Bill Location
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="billlocation"
									name="billlocation"
									placeholder="Enter Bill Address"
									value={bLocation}
									onChange={(e) =>
										setBillLocation(e.target.value)
									}
								/>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="faces"
								>
									Faces
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="faces"
									name="faces"
									placeholder="Enter branch name"
									value={faces}
									onChange={(e) => setFaces(e.target.value)}
								/>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="units"
								>
									Units
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="units"
									name="units"
									placeholder="Enter units"
									value={units}
									onChange={(e) => setUnits(e.target.value)}
								/>
							</div>
						</div>
					</div>
				)}
				{/* Branded Vehicles */}
				{category === "003" && (
					<div>
						<div className={style.radio__button_div}>
							<div>
								<div>
									<input
										id="Application"
										type="radio"
										value="Application"
										checked={type === "Application"}
										onChange={handleRadioChange}
										name="type"
									/>
									<label className={styles.input__label}>
										Application
									</label>
								</div>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="subcat"
								>
									Sub Category
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									name="subcat"
									value={subcategory}
									onChange={(e) =>
										setSubCategory(e.target.value)
									}
								>
									<option>
										{subcategories
											? "--Select --Sub Category"
											: "...Loading"}
									</option>
									{subcategories &&
										subcategories.map((subcategories) => (
											<option
												// key={subcategories.Code}
												value={
													subcategories.Description
												}
											>
												{subcategories.Description}
											</option>
										))}
								</select>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="Rate"
								>
									Rate (₦)
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="Rate"
									name="Rate"
									placeholder="Enter rate"
									value={rate}
									onChange={(e) => setRate(e.target.value)}
								/>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="Rate"
								>
									Unit
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="unit"
									name="unit"
									placeholder="Enter unit"
									value={units}
									onChange={(e) => setUnits(e.target.value)}
								/>
							</div>

							<div>
								<label
									className={styles.input__label}
									htmlFor="faces"
								>
									Faces
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="faces"
									name="faces"
									placeholder="Enter branch name"
									value={faces}
									onChange={(e) => setFaces(e.target.value)}
								/>
							</div>
						</div>
					</div>
				)}
				{/* Signange */}
				{category === "008" && (
					<div>
						<div className={style.radio__button_div}>
							<div>
								<div>
									<input
										id="Application"
										type="radio"
										value="Application"
										checked={type === "Application"}
										onChange={handleRadioChange}
										name="type"
									/>
									<label className={styles.input__label}>
										Application
									</label>
								</div>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="subcat"
								>
									Sub Category
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									name="subcat"
									value={subcategory}
									onChange={(e) =>
										setSubCategory(e.target.value)
									}
								>
									<option>
										{subcategories
											? "--Select --Sub Category"
											: "...Loading"}
									</option>
									{subcategories &&
										subcategories.map((subcategories) => (
											<option
												// key={subcategories.Code}
												value={
													subcategories.Description
												}
											>
												{subcategories.Description}
											</option>
										))}
								</select>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="Rate"
								>
									Rate (₦)
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="Rate"
									name="Rate"
									placeholder="Enter rate"
									value={rate}
									onChange={(e) => setRate(e.target.value)}
								/>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="billlocation"
								>
									Signange Location
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="billlocation"
									name="billlocation"
									placeholder="Enter Bill Address"
									value={bLocation}
									onChange={(e) =>
										setBillLocation(e.target.value)
									}
								/>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="faces"
								>
									Faces
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="faces"
									name="faces"
									placeholder="Enter branch name"
									value={faces}
									onChange={(e) => setFaces(e.target.value)}
								/>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="units"
								>
									Units
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="units"
									name="units"
									placeholder="Enter units"
									value={units}
									onChange={(e) => setUnits(e.target.value)}
								/>
							</div>
						</div>
					</div>
				)}
				{/* ROAD SHOW */}
				{category === "010" && (
					<div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="subcat"
								>
									Sub Category
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									name="subcat"
									value={subcategory}
									onChange={(e) =>
										setSubCategory(e.target.value)
									}
								>
									<option>
										{subcategories
											? "--Select --Sub Category"
											: "...Loading"}
									</option>
									{subcategories &&
										subcategories.map((subcategories) => (
											<option
												// key={subcategories.Code}
												value={
													subcategories.Description
												}
											>
												{subcategories.Description}
											</option>
										))}
								</select>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="Rate"
								>
									Rate (₦)
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="Rate"
									name="Rate"
									placeholder="Enter rate"
									value={rate}
									onChange={(e) => setRate(e.target.value)}
								/>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="Rate"
								>
									Unit
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="unit"
									name="unit"
									placeholder="Enter unit"
									value={units}
									onChange={(e) => setUnits(e.target.value)}
								/>
							</div>
						</div>
					</div>
				)}

				<div className={style.buttonContainer}>
					<button
						className={["secondary__btn", style.stylebtn].join(" ")}
						onClick={handleAddSubcats}
					>
						+ Add New Sub Category
					</button>
				</div>
			</form>

			{/* table */}

			{categories.length > 0 && (
				<Table
					headers={headers}
					data={categories}
					full
					onDelete={handleDeleteCategory}
				/>
			)}
			<hr />
			<div className={styles.columned__form}>
				<h3 className={style.title}>Prepayments</h3>
				<div className={styles.double__inputs}>
					<div>
						<input
							id="receiptNo"
							name="receiptNo"
							placeholder="Enter Receipt Number"
							value={receiptNo}
							onChange={(e) => setReceiptNo(e.target.value)}
						/>
					</div>
				</div>
			</div>
			{(getprepay2 || []).length > 0 && (
				<Table
					headers={header}
					data={getprepay2}
					onDelete={onDeletePrePay}
					full
				/>
			)}

			{/* other details */}
			<form className={styles.columned__form}>
				<h3 className={style.title}>Other Details</h3>

				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="Discount"
						>
							Discount Amount (₦)
							<span style={{ color: "red" }}> *</span>
						</label>
						<input
							id="Discount"
							name="Discount"
							placeholder="Enter Discount Amount"
							value={ensaaInfo.Discount}
							onChange={handleensaaInfoChange}
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="DiscountReason"
						>
							Discount Description
							<span style={{ color: "red" }}> *</span>
						</label>
						<input
							id="DiscountReason"
							name="DiscountReason"
							placeholder="Enter Discount Description"
							value={ensaaInfo.DiscountReason}
							onChange={handleensaaInfoChange}
						/>
					</div>
				</div>
				{/* <div className={style.buttonContainer}>
					<button
						className={["primary__btn", style.stylebtn2].join(" ")}
						onClick={calculateBill}
					>
						calculate bill
					</button>
				</div> */}
				<div className={style.buttonContainer}>
					<button
						className={["primary__btn", style.stylebtn2].join(" ")}
						onClick={handleAddCategory}
					>
						generate bill
					</button>
				</div>
			</form>
			<hr />
			<Modal
				show={showModal}
				onClose={() => {
					recieptcloseModal();
					history.push("/ENNSAA/records");
				}}
			>
				{print && (
					<button
						className={["secondary__btn", style.btn].join(" ")}
						onClick={printReceipt}
					>
						Print
					</button>
				)}
				<Receipt data={imageData} />
			</Modal>
		</div>
	);
};

export default ENSSAAPrepareBil;
