import React, { useEffect, useState } from "react";
import styles from "../../../Identity-management-individual/style.module.css";
import styles2 from "../../../Direct-assessment/Create-direct-assessment/style.module.css";
import Alert from "../../../components/Alert";
import style from "../style.module.css";
import axios from "axios";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import constants from "../../../constants";
import Table from "../../../components/Table";

import { formatDate } from "../../../utils";
const SelfRecordsEdit = () => {
	const { id } = useParams();
	const token = localStorage.getItem("access_token");
	const history = useHistory();

	const header = [
		["S/N", "SN"],
		["Category", "Category"],
		["Product", "Product"],
		["Tariff Rate", "PT_Rate"],
		["Purchase Amount", "Purchases_Amount"],
		["Transaction Date", "Transaction_Date", formatDate],
		["Amount as PT", "Bill_Amount"]
	];

	const [tableData, setTableData] = useState([]);
	// State for unit filing company info
	const [companyInfo, setCompanyInfo] = useState({
		ESBN: "",
		Email: "",
		Name: "",
		Phone: "",
		Sector: "",
		Address: "",
		filingMode: "",
		Bill_Year: "",
		Bill_Month: "",
		Discount: 0,
		DiscountReason: "",
		Outstanding: 0,
		outstandingReason: "",
		StartDate: null,
		EndDate: null,
		fileType: "Unit Assessment" // Retains file type
	});
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const [sectors, setSectors] = useState([]);
	const [loading, setLoading] = useState(false);
	const [files, setFiles] = useState([]);
	const [getFiles, setGetfiles] = useState([]);
	const [convertedFiles, setConvertedFiles] = useState([]);

	const getAllPTCompanyInfo = async () => {
		const res = await axios.get(
			`${constants.BASE1_URL}/purchasingTax/getCompanyInfoRecord/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);
		const data = res.data.data;
		setCompanyInfo(data);
		setTableData(data?.PurchaseTaxCategoryInfo);
		setGetfiles(data?.PurchaseTaxCompanyFile);
		console.log("data that", data);
	};
	useEffect(() => {
		getAllPTCompanyInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Handle input change for company info
	const handleCompanyInfoChange = (e) => {
		const { name, value } = e.target;

		// Ensure that discount and outstanding are stored as numbers
		if (name === "discount" || name === "outstanding") {
			// Allow only numeric input and handle empty input
			if (value === "" || (!isNaN(value) && Number(value) >= 0)) {
				setCompanyInfo((prevState) => ({
					...prevState,
					[name]: value === "" ? "" : Number(value) // Convert to number if not empty
				}));
			}
		} else {
			setCompanyInfo((prevState) => ({
				...prevState,
				[name]: value
			}));
		}
	};

	useEffect(() => {
		const getSector = async () => {
			try {
				const res = await axios.get(`/settings/economicsector`);
				setSectors(res.data?.data); // Assuming res.data is an array of sectors
			} catch (error) {
				console.error("Error fetching sectors:", error);
			}
		};
		getSector();
	}, []);

	const updateCompanyInfo = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const res = await axios.put(
				`${constants.BASE1_URL}/purchasingTax/updateCompanyInfo/${id}`,
				{
					companyInfo: {
						esbn: companyInfo.ESBN,
						email: companyInfo.Email,
						filingMode: companyInfo.filingMode,
						billYear: companyInfo.Bill_Year,
						name: companyInfo.Name,
						phone: companyInfo.Phone,
						sector: companyInfo.Sector,
						address: companyInfo.Address,
						discount: companyInfo.Discount,
						discountReason: companyInfo.DiscountReason,
						outstanding: companyInfo.Outstanding,
						outstandingReason: companyInfo.outstandingReason,
						fileType: companyInfo.fileType,
						startDate: companyInfo.StartDate,
						endDate: companyInfo.EndDate
					},
					files: [...files]
				}
			);
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res.data.message
			});
			history.push("/pt/pt-search");
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message:
					error?.response.data?.message ||
					error.response.data?.errors?.[0]?.error ||
					error.response.data?.errors?.details[0]?.message
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const handleFileUpload = async (event) => {
		const uploadedFiles = Array.from(event.target.files);

		// Filter files to only those under 500KB
		const validFiles = uploadedFiles.filter(
			(file) => file.size <= 500 * 1024
		);

		if (validFiles.length < uploadedFiles.length) {
			setAlert({
				showing: true,
				type: "error",
				message:
					"Some files were not uploaded because they exceed 500KB."
			});
		}

		// Convert each valid file to Base64 and store the name with Base64 string
		const base64Files = await Promise.all(
			validFiles.map(async (file) => ({
				fileName: file.name,
				fileType: file.type,
				File: await convertToBase64(file)
			}))
		);

		setFiles((prevFiles) => [...prevFiles, ...base64Files]);
	};

	const convertToBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	};

	const handleRemoveFile = (index) => {
		setFiles(files.filter((_, i) => i !== index));
		setConvertedFiles(convertedFiles.filter((_, i) => i !== index));
	};

	return (
		<form className={styles.columned__form}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}

			{/* radio section */}
			<div className={styles2.radio__button_div}>
				<div>
					<div>
						<input
							id="UnitAssessment"
							type="radio"
							value="Unit Assessment"
							onChange={handleCompanyInfoChange}
							name="fileType" // Ensure the name matches
							checked={companyInfo.fileType === "Unit Assessment"}
						/>
						<label className={styles.input__label}>
							Unit Assessment
						</label>
					</div>
				</div>
			</div>
			{/* company information */}
			<div>
				{/* mode of assessment */}

				<div>
					<div className={style.radio__button_div}>
						<div>
							<div>
								<input
									id="Yearly"
									type="radio"
									value="Yearly"
									checked={
										companyInfo.filingMode === "Yearly"
									}
									onChange={handleCompanyInfoChange}
									name="filingMode" // Ensure the name matches
								/>
								<label className={styles.input__label}>
									Yearly
								</label>
							</div>
							<div>
								<input
									id="Monthly"
									type="radio"
									value="Monthly"
									checked={
										companyInfo.filingMode === "Monthly"
									}
									onChange={handleCompanyInfoChange}
									name="filingMode" // Ensure the name matches
								/>
								<label className={styles.input__label}>
									Monthly
								</label>
							</div>
							<div>
								<input
									id="Period"
									type="radio"
									value="Period"
									checked={
										companyInfo.filingMode === "Period"
									}
									onChange={handleCompanyInfoChange}
									name="filingMode" // Ensure the name matches
								/>
								<label className={styles.input__label}>
									Period
								</label>
							</div>
						</div>
					</div>
					{companyInfo.filingMode === "Yearly" && (
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="billYear"
								>
									Bill Year (Fiscal Year)
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									name="billYear"
									value={companyInfo.Bill_Year}
									onChange={handleCompanyInfoChange}
									required
								>
									<option value="">Select a year</option>
									{Array.from({ length: 100 }, (_, i) => {
										const year =
											new Date().getFullYear() + 1 - i;
										return (
											<option key={year} value={year}>
												{year}
											</option>
										);
									})}
								</select>
							</div>
						</div>
					)}
					{companyInfo.filingMode === "Monthly" && (
						<div>
							<div className={styles.double__inputs}>
								<div>
									<label
										className={styles.input__label}
										htmlFor="billYear"
									>
										Bill Year (Fiscal Year)
										<span style={{ color: "red" }}>*</span>
									</label>
									<select
										name="billYear"
										value={companyInfo.Bill_Year}
										onChange={handleCompanyInfoChange}
										required
									>
										<option value="">Select a year</option>
										{Array.from({ length: 100 }, (_, i) => {
											const year =
												new Date().getFullYear() +
												1 -
												i;
											return (
												<option key={year} value={year}>
													{year}
												</option>
											);
										})}
									</select>
								</div>
								<div>
									<label
										className={styles.input__label}
										htmlFor="billMonth"
									>
										Month of Assessment
										<span style={{ color: "red" }}>*</span>
									</label>
									<select
										name="billMonth"
										value={companyInfo.Bill_Month}
										onChange={handleCompanyInfoChange}
										required
									>
										<option value="">Select a month</option>
										{[
											"January",
											"February",
											"March",
											"April",
											"May",
											"June",
											"July",
											"August",
											"September",
											"October",
											"November",
											"December"
										].map((month) => (
											<option key={month} value={month}>
												{month}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>
					)}
					{companyInfo.filingMode === "Period" && (
						<div>
							<div className={styles.double__inputs}>
								<div>
									<label
										className={styles.input__label}
										htmlFor="billYear"
									>
										Bill Year (Fiscal Year)
										<span style={{ color: "red" }}>*</span>
									</label>
									<select
										name="billYear"
										value={companyInfo.billYear}
										onChange={handleCompanyInfoChange}
										required
									>
										<option value="">Select a year</option>
										{Array.from({ length: 100 }, (_, i) => {
											const year =
												new Date().getFullYear() +
												1 -
												i;
											return (
												<option key={year} value={year}>
													{year}
												</option>
											);
										})}
									</select>
								</div>
							</div>
							<div className={styles.double__inputs}>
								{/* Period under consideration Start Date */}
								<div>
									<label>
										Period under consideration Start Date
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type="date"
										name="startDate"
										value={companyInfo.StartDate}
										onChange={handleCompanyInfoChange}
										placeholder="Select date"
										required
										disabled={
											companyInfo.fileType ===
											"Bulk Assessment"
										}
									/>
								</div>

								{/* Period under consideration End Date */}
								<div>
									<label>
										Period under consideration End Date
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type="date"
										name="endDate"
										value={companyInfo.EndDate}
										onChange={handleCompanyInfoChange}
										placeholder="Select date"
										required
										disabled={
											companyInfo.fileType ===
											"Bulk Assessment"
										}
									/>
								</div>
							</div>
						</div>
					)}
					<hr />
					<h3>Company’s Information</h3>
					<div className={styles.double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="code"
							>
								ESBN
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								id="esbn"
								name="esbn"
								placeholder="Enter ESBN"
								value={companyInfo.ESBN}
								onChange={handleCompanyInfoChange}
								disabled={
									companyInfo.fileType === "Bulk Assessment"
								}
							/>
						</div>
						<div>
							<label
								className={styles.input__label}
								htmlFor="Sector"
							>
								Sector
								<span style={{ color: "red" }}>*</span>
							</label>
							<select
								name="Sector"
								value={companyInfo.Sector}
								onChange={handleCompanyInfoChange} // Update companyInfo.sector on selection
								required
							>
								<option value="">Select a sector</option>
								{sectors &&
									sectors.map((sector) => (
										<option
											key={sector.Code}
											value={sector.Code}
										>
											{" "}
											{/* Add key here */}
											{sector.Description}
										</option>
									))}
							</select>
						</div>
					</div>
					<div className={styles.double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="Name"
							>
								Name
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								id="Name"
								name="Name"
								placeholder="Enter Name"
								value={companyInfo.Name}
								onChange={handleCompanyInfoChange}
								disabled={
									companyInfo.fileType === "Bulk Assessment"
								}
							/>
						</div>
						<div>
							<label
								className={styles.input__label}
								htmlFor="Email"
							>
								Email
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								name="Email"
								id="Email"
								placeholder="Enter Email"
								value={companyInfo.Email}
								onChange={handleCompanyInfoChange}
								disabled={
									companyInfo.fileType === "Bulk Assessment"
								}
							/>
						</div>
					</div>

					<div className={styles.double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="Phone"
							>
								Phone Number
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								name="Phone"
								id="Phone"
								placeholder="Enter your phone Number"
								value={companyInfo.Phone}
								onChange={handleCompanyInfoChange}
								disabled={
									companyInfo.fileType === "Bulk Assessment"
								}
							/>
						</div>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="Address"
						>
							Address
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							className={style.input__width}
							id="Address"
							name="Address"
							placeholder="Enter Address"
							value={companyInfo.Address}
							onChange={handleCompanyInfoChange}
							disabled={
								companyInfo.fileType === "Bulk Assessment"
							}
						/>
					</div>

					<hr />

					{/* good's information */}
					<div>
						<h3 className={style.space}>Goods Information</h3>
					</div>

					<>
						<Table
							headers={header}
							data={tableData}
							onEdit={(data) => {
								history.push(
									`/pt/pt-records/pt-category/${data.id}`
								);
							}}
							full
						/>
						{/* <div>
										Total Amout : {totalPurchasesAmount}
									</div> */}

						<hr />
						{/* Other details */}
						<div>
							<div className={styles.double__inputs}>
								<div>
									<label
										className={styles.input__label}
										htmlFor="Discount"
									>
										Discount Amount
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type="number"
										name="Discount"
										id="Discount"
										placeholder="Enter Discount Amount"
										value={companyInfo.Discount}
										onChange={handleCompanyInfoChange}
										disabled={
											companyInfo.fileType ===
											"Bulk Assessment"
										}
									/>
								</div>
								<div>
									<label
										className={styles.input__label}
										htmlFor="DiscountReason"
									>
										Discount description
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type="text"
										name="DiscountReason"
										id="DiscountReason"
										placeholder="Discount Description"
										value={companyInfo.DiscountReason}
										onChange={handleCompanyInfoChange}
										disabled={
											companyInfo.fileType ===
											"Bulk Assessment"
										}
									/>
								</div>
							</div>
							<div className={styles.double__inputs}>
								<div>
									<label
										className={styles.input__label}
										htmlFor="Outstanding"
									>
										Outstanding Amount
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type="number"
										name="Outstanding"
										id="Outstanding"
										placeholder="Enter Outstanding Amount"
										value={companyInfo.Outstanding}
										onChange={handleCompanyInfoChange}
										disabled={
											companyInfo.fileType ===
											"Bulk Assessment"
										}
									/>
								</div>
								<div>
									<label
										className={styles.input__label}
										htmlFor="outstandingReason"
									>
										Outstanding Description
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type="text"
										name="outstandingReason"
										id="outstandingReason"
										placeholder="select outstanding Description"
										value={companyInfo.outstandingReason}
										onChange={handleCompanyInfoChange}
										disabled={
											companyInfo.fileType ===
											"Bulk Assessment"
										}
									/>
								</div>
							</div>
						</div>
					</>

					<ul className={styles.attachmentList}>
						{getFiles?.map((file, index) => (
							<li key={file.id} className={styles.attachmentItem}>
								<span className={styles.fileIcon}>📄</span>
								<span className={styles.fileName}>
									{file.FileName}
								</span>
							</li>
						))}
					</ul>
					<div>
						<label
							style={{
								cursor: "pointer",
								color: "#0066cc",
								textDecoration: "underline",
								marginTop: "28px",
								marginBottom: "72px"
							}}
						>
							+ Attach Invoice Documents
							<input
								type="file"
								accept=".pdf, image/*"
								style={{ display: "none" }}
								onChange={handleFileUpload}
							/>
						</label>

						<ul>
							{files.map((file, index) => (
								<li
									key={index}
									style={{
										color: "green",
										marginBottom: "8px"
									}}
								>
									<span>{file.fileName}</span>{" "}
									{/* Display file name */}
									<button
										onClick={() => handleRemoveFile(index)}
										style={{
											backgroundColor: "transparent",
											color: "red",
											border: "red",
											padding: "4px 8px",
											marginLeft: "12px",
											gap: "15px",
											cursor: "pointer"
										}}
									>
										X
									</button>
								</li>
							))}
						</ul>
					</div>

					<div>
						<div className={style.buttonContainer}>
							<button
								className={["primary__btn", style.btn].join(
									" "
								)}
								onClick={updateCompanyInfo}
							>
								{loading ? "Updating..." : "Update"}
							</button>
						</div>
						{/* Modal that pops up based on the API response */}
					</div>
				</div>
			</div>
		</form>
	);
};

export default SelfRecordsEdit;
