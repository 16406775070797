import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { formatDate } from "../../../utils";
import Table from "../../../components/Table";
import Alert from "../../../components/Alert";
import constants from "../../../constants";
import Modal from "../../../components/Modal/regular";
import newsreelStyles from "../style.module.css";
import CloseCircle from "../../../assets/NewsReel/CloseCircle.svg";

const NewsReelGenerationTable = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [filtersCleared, setFiltersCleared] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const [newsModalShowing, setNewsModalShowing] = useState(false);
	const [deleteNewsModalShowing, setDeleteNewsModalShowing] = useState(false);
	const [selectedNews, setSelectedNews] = useState("");
	const [selectedId, setSelectedId] = useState(null);
	const [isUpdating, setIsUpdating] = useState(false);
	const [processingDelete, setProcessingDelete] = useState(false);

	const headers = [
		["News Description", "NewsHeadline"],
		["Generated By", "GeneratedBy"],
		["Date Created", "createdAt", formatDate]
	];

	const getNewsHeadlines = () => async (e) => {
		e?.preventDefault?.();

		setLoading(true);
		try {
			let res = await axios
				.get(`${constants.BASE_URL_3}/newsheadline`)
				.then((res) => res.data.data);
			setData(res);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getNewsHeadlines()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getNewsHeadlines()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const updateNewsHeadline = async () => {
		if (!selectedNews.trim()) {
			return;
		}
		setIsUpdating(true);
		try {
			const res = await axios.patch(
				`${constants.BASE_URL_3}/update-newsheadline/${selectedId}`,
				{
					newsHeadline: selectedNews
				}
			);

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res?.data?.message
			});
			setNewsModalShowing(false);
			getNewsHeadlines()();
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setIsUpdating(false);
		}
	};

	const confirmDeleteNews = (id) => {
		setSelectedId(id);
		setDeleteNewsModalShowing(true);
	};

	const deleteNewsHeadline = async () => {
		setDeleteNewsModalShowing(false);
		setProcessingDelete(true);
		try {
			const res = await axios.delete(
				`${constants.BASE_URL_3}/delete-headline/${selectedId}`
			);
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res?.data?.message
			});

			getNewsHeadlines()();
			setProcessingDelete(false);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	return (
		<section>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<div className={newsreelStyles.actions__div}>
				<button
					className="primary__btn"
					onClick={() => {
						history.push("/news-reel/publish");
					}}
					style={{ padding: "0 16px" }}
				>
					Add New Weekly News
				</button>
			</div>

			<Table
				headers={headers}
				loading={loading}
				data={data}
				noDataMessage={"No news headlines"}
				full
				onPageChanged={getNewsHeadlines}
				onEdit={(data) => {
					setSelectedNews(data.NewsHeadline);
					setSelectedId(data.id);
					setNewsModalShowing(true);
				}}
				onDelete={(row) => confirmDeleteNews(row.id)}
			/>

			{/* Edit Modal */}
			{newsModalShowing && (
				<Modal toggle={setNewsModalShowing} showing={newsModalShowing}>
					<form
						className={newsreelStyles.form_edit}
						onSubmit={(e) => {
							e.preventDefault();
							updateNewsHeadline();
						}}
					>
						<div className={newsreelStyles.edit__Header_Container}>
							<h3 className={newsreelStyles.editHeader}>
								Edit Newsletter
							</h3>
							<button
								className={newsreelStyles.edit__Header_btnI}
								onClick={() => setNewsModalShowing(false)}
							>
								<img
									src={CloseCircle}
									alt="close icon"
									height={24}
									width={24}
								/>
							</button>
						</div>

						<label
							htmlFor="newsDescription"
							className={newsreelStyles.edit__news_label}
						>
							News Description{" "}
							<span style={{ color: "red" }}>*</span>
						</label>

						<textarea
							id="newsDescription"
							className={newsreelStyles.input}
							value={selectedNews}
							onChange={(e) => setSelectedNews(e.target.value)}
						/>

						<div className={newsreelStyles.editHeaderbtn}>
							<button
								type="submit"
								className={[
									"primary__btn",
									newsreelStyles.update__btn
								].join(" ")}
								disabled={isUpdating}
								style={{ padding: "0 16px" }}
							>
								{isUpdating ? "Updating..." : "Update Headline"}
							</button>
						</div>
					</form>
				</Modal>
			)}

			{/* Delete Confirmation Modal */}
			{deleteNewsModalShowing && (
				<Modal
					toggle={setDeleteNewsModalShowing}
					showing={deleteNewsModalShowing}
				>
					<div className={newsreelStyles.delete__modal_Cont}>
						<p className={newsreelStyles.delete__content}>
							Are you sure you want to delete this news Headline?
						</p>
						<div className={newsreelStyles.delmodalbtn}>
							<button
								className={[newsreelStyles.delctn__btn].join(
									" "
								)}
								onClick={() => setDeleteNewsModalShowing(false)}
							>
								No
							</button>
							<button
								className={[
									newsreelStyles.delctn__btn_yes
								].join(" ")}
								onClick={deleteNewsHeadline}
								style={{ padding: "0 16px" }}
							>
								{processingDelete ? "Deleting..." : "Yes"}
							</button>
						</div>
					</div>
				</Modal>
			)}
		</section>
	);
};

export default NewsReelGenerationTable;
