import React, { useState } from "react";
import { useHistory } from "react-router";
import styles from "../../MDAs/style.module.css";
import RegularModal from "../../../components/Modal/regular";

export const ApprovalModal = ({
	showing = false,
	closeModal = () => {},
	rejectionAction,
	approvalState = "denied",
	enterprise = ""
}) => {
	const [reason, setReason] = useState("");

	const history = useHistory();
	return (
		<RegularModal showing={showing} toggle={(val) => closeModal(val)}>
			{approvalState === "approved" && (
				<p className={styles.revoke__warning}>
					You have successfully approved <strong>{enterprise}</strong>
					's annual returns filing, an email would be sent to notify
					them accordingly.
				</p>
			)}

			{approvalState === "denied" && (
				<p className={styles.revoke__warning}>
					You are about to deny <strong>{enterprise}</strong>'s annual
					returns. Please, state your reason.
				</p>
			)}

			{approvalState === "denied" && (
				<>
					<div>
						<br />

						<label className={styles.input__label} htmlFor="reason">
							Reason for denying{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<textarea
							onChange={(e) => setReason(e.target.value)}
							id="reason"
							placeholder="write the reason for denying this annual filing"
							rows={2}
							value={reason}
						/>
					</div>
				</>
			)}

			<div className={styles.revoke__btns}>
				<button
					className="btn btn-danger"
					onClick={() => {
						closeModal(!showing);
					}}
				>
					Close modal
				</button>
				{approvalState === "denied" && (
					<button
						onClick={() => {
							approvalState === "denied"
								? rejectionAction(reason)
								: closeModal(!showing);
							setTimeout(() => {
								history.goBack();
							}, 1500);
						}}
						className="primary__btn"
						disabled={approvalState === "denied" ? !reason : false}
					>
						Continue
					</button>
				)}
			</div>
		</RegularModal>
	);
};
