import axios from "axios";
import React, { useState, useEffect } from "react";
import GoBackButton from "../../components/goBackButton";
import styles2 from "../../Direct-assessment/Create-direct-assessment/style.module.css";
import styles from "../../Identity-management-individual/style.module.css";
import style from "./style.module.css";
import constants from "../../constants";
import Alert from "../../components/Alert";

axios.defaults.base1URL = constants.BASE1_URL;
const PT_Add_setup = () => {
	const [setup, setSetup] = useState({
		type: "",
		name: "",
		code: "",
		rate: 0
	});
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const [Isloading, setIsLoading] = useState(false);

	const handleCompanyInfoChange = (e) => {
		const { name, value } = e.target;
		setSetup((prevState) => ({
			...prevState,
			[name]: value
		}));
	};

	//method for handling the api call
	const createSetUp = async () => {
		setIsLoading(true);
		try {
			const res = await axios.post(
				`${constants.BASE1_URL}/purchasingTax/createPurchaseTaxSetUp`,
				{
					type: setup.type,
					name: setup.name,
					code: setup.code,
					rate: setup.rate
				}
			);
			setAlert({
				showing: true,
				type: "success",
				message: res.data?.message
			});
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message:
					error?.response.data?.message ||
					error.response.data?.errors?.[0]?.error ||
					error.response.data?.errors?.details[0]?.message
			});
		} finally {
			setIsLoading(false);
		}
	};
	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);
	return (
		<div>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<GoBackButton />
			<div className={styles.columned__form}>
				<h4>
					Type of Assessment
					<span style={{ color: "red" }}> *</span>
				</h4>
				{/* radio section */}
				<div className={styles2.radio__button_div}>
					<div>
						<div>
							<input
								id="goods"
								type="radio"
								value="goods"
								checked={setup.type === "goods"}
								onChange={handleCompanyInfoChange}
								name="type" // Ensure the name matches
							/>
							<label className={styles.input__label}>goods</label>
						</div>
						<div>
							<input
								id="BulkAssessment"
								type="radio"
								value="services"
								checked={setup.type === "services"}
								onChange={handleCompanyInfoChange}
								name="type" // Ensure the name matches
							/>
							<label className={styles.input__label}>
								Services
							</label>
						</div>
					</div>
				</div>
				{setup.type === "goods" && (
					<>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="name"
								>
									Name of Category
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									type="text"
									name="name"
									id="name"
									placeholder="enter Category"
									value={setup.name}
									onChange={handleCompanyInfoChange}
								/>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="code"
								>
									Code
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									type="text"
									id="code"
									name="code"
									placeholder="Enter Code"
									value={setup.code}
									onChange={handleCompanyInfoChange}
								/>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="code"
								>
									Rate %
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									type="text"
									id="rate"
									name="rate"
									placeholder="Enter Rate"
									value={setup.rate}
									onChange={handleCompanyInfoChange}
								/>
							</div>
						</div>
						<div className={style.buttonContainer}>
							<button
								className={["primary__btn", style.btn].join(
									" "
								)}
								disabled={
									!setup.name || !setup.code || !setup.rate
								}
								onClick={createSetUp}
							>
								{Isloading ? "Adding..." : "Add Good"}
							</button>
						</div>
					</>
				)}
				{setup.type === "services" && (
					<>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="name"
								>
									Name of Category
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									type="text"
									name="name"
									id="name"
									placeholder="enter Category"
									value={setup.name}
									onChange={handleCompanyInfoChange}
								/>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="code"
								>
									Code
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									type="text"
									id="code"
									name="code"
									placeholder="Enter Code"
									value={setup.code}
									onChange={handleCompanyInfoChange}
								/>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="code"
								>
									Rate %
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									type="text"
									id="rate"
									name="rate"
									placeholder="Enter Rate"
									value={setup.rate}
									onChange={handleCompanyInfoChange}
								/>
							</div>
						</div>
						<div className={style.buttonContainer}>
							<button
								className={["primary__btn", style.btn].join(
									" "
								)}
								onClick={createSetUp}
								disabled={
									!setup.name || !setup.code || !setup.rate
								}
							>
								{Isloading ? "Adding..." : "Add Service"}
							</button>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default PT_Add_setup;
