import { useLocation } from "react-router-dom";
import { VIEWER } from "../withholdingConstants";
import { EditEmployerInformation } from "../shared/editEmployerInformation";

const AdminEmployerInformationEdit = () => {
	const { state: locationPassedData } = useLocation();

	return (
		<EditEmployerInformation
			viewer={VIEWER.ADMIN}
			passedEmployeeData={locationPassedData}
		/>
	);
};
export default AdminEmployerInformationEdit;
