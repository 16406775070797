import axios from "axios";
import constants from "../../../constants";
import styles from "../style.module.css";
import removeImg from "../../../assets/x_red.svg";
import React, { useState } from "react";
import docsImg from "../../../assets/docxImg.svg";
import pdfImg from "../../../assets/pdfImg.svg";

export const EmployerContractDocumentComponent = ({
	employerFiles,
	setFilesForUpload
}) => {
	const accessToken = localStorage.getItem("access_token");
	const [fileLists, setFileLists] = useState([
		...Object.values(employerFiles)
	]);

	const deleteDocument = async (fileId) => {
		try {
			await axios.delete(
				`${constants.BASE_URL_2}/withholding/document/${fileId}`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			);
		} catch (error) {
			console.log("Having issue in  deleting this file");
		}
	};

	const handleRemoveFile = async (fileId, indexToRemove) => {
		await deleteDocument(fileId);

		const updatedFileLists = fileLists.filter(
			(_, index) => index !== indexToRemove
		);
		setFileLists(updatedFileLists);
		setFilesForUpload((prev) => ({
			...Object.keys(prev)
				.filter((key) => key !== fileId.toString())
				.reduce(
					(acc, curr) => ({ ...acc, ...{ [curr]: prev[curr] } }),
					{}
				)
		}));
	};

	const handlerFileAttaching = (e) => {
		let lastFileListCount = fileLists.length;
		const newFileList = [];
		const newFileListToUpload = {};
		for (const file of e.target.files) {
			const newId = lastFileListCount++;
			newFileList.push({ id: newId, Name: file.name });
			newFileListToUpload[newId] = file;
		}
		setFileLists((prevFiles) => [...prevFiles, ...newFileList]);
		setFilesForUpload((prevFiles) => ({
			...prevFiles,
			...newFileListToUpload
		}));
	};

	return (
		<>
			<section>
				<input
					type="file"
					id="fileInput"
					multiple
					style={{ display: "none" }}
					onChange={handlerFileAttaching}
				/>
				<label
					htmlFor="fileInput"
					className={styles.invisibleFileLabel}
				>
					<p>
						+
						<span
							style={{
								marginLeft: "5px",
								textDecoration: "underline"
							}}
						>
							Attach contract document
						</span>
					</p>
				</label>
			</section>
			<div>
				{fileLists.map((file, index) => {
					const fileExtension = file.Name.split(".")
						.pop()
						.toLowerCase();

					const logo = fileExtension === "pdf" ? pdfImg : docsImg;
					return (
						<div key={index} className={styles.fileLists}>
							<div>
								<img src={logo} alt="Document Logo" />
								<span key={index}>{file.Name}</span>
							</div>
							<img
								src={removeImg}
								alt="Remove Logo"
								onClick={() => handleRemoveFile(file.id, index)}
								style={{ cursor: "pointer" }}
							/>
						</div>
					);
				})}
			</div>
		</>
	);
};
