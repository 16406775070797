import React, { createRef, useEffect, useState } from "react";
import { useHistory } from "react-router";
import constants from "../../constants";
import { exportExcel, formatDate, getStatusText, remapObj } from "../../utils";
import axios from "axios";
import styles from "../../Dashboard/MDAs/style.module.css";
import Alert from "../../components/Alert";
import Table from "../../components/Table";
import style from "./style.module.css";

const EnnsaaAuthoriser = () => {
	const filterRef = createRef();
	const history = useHistory();
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [data, setData] = useState([]);
	const [paymentStats, setPaymentStat] = useState("");
	const [minAmount, setMinAmount] = useState(0);
	const [maxAmount, setMaxAmount] = useState(0);
	const [filtersCleared, setFiltersCleared] = useState(false);
	const [total, setTotal] = useState(0);
	const [billNumber, setBillNumber] = useState("");
	const [category, setCategory] = useState("");
	const [modeofbill, setModeOfBill] = useState("");
	const [name, setName] = useState("");
	const [srn, setSRN] = useState("");
	const [fileType, setFileType] = useState("");
	const [loading, setLoading] = useState(false);
	const [esbn, setEsbn] = useState("");
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const header = [
		["ESBN", "ESBN"],
		["Bill Number", "BillNumber"],
		["Total Amount Billed", "TotalAmtBilled"],
		["Application Type", "Category"],
		["Name", "Name"],
		["Signage Number", "SRN"],
		["Payment Status", "PaymentStatus"],
		["Status", "ApproveFlg", getStatusText],
		["Date Issued", "createdAt", formatDate]
	];
	const _getAllEnsaaSearch = ({ currentPage = 1, pageSize = 20 }) => {
		let query = `page=${currentPage}&limit=${pageSize}&`;
		if (paymentStats) {
			query += `payment_status=${paymentStats}&`;
		}
		if (maxAmount) {
			query += `maxAmount=${maxAmount}&`;
		}
		if (minAmount) {
			query += `minAmount=${minAmount}&`; // Adds minAmount parameter to query string if minAmount is set
		}
		if (billNumber) {
			query += `billnumber=${billNumber}&`;
		}
		if (fileType) {
			query += `fileType=${fileType}&`;
		}
		if (esbn) {
			query += `esbn=${esbn}&`;
		}
		if (category) {
			query += `category=${category}&`;
		}
		if (modeofbill) {
			query += `modeofbill=${modeofbill}&`;
		}
		if (srn) {
			query += `srn=${srn}&`;
		}
		if (name) {
			query += `name=${name}&`;
		}
		if (startDate) {
			query += `startDate=${startDate}&`;
		}
		if (endDate) {
			query += `endDate=${endDate}&`;
		}

		return axios
			.get(
				`${constants.BASE_URL_2}/enssaa/search?${query}&type=authorizer`
			)
			.then((res) => res.data.data || {});
	};
	useEffect(() => {
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	const clearFilters = () => {
		setPaymentStat("");
		setMinAmount("");
		setMaxAmount("");
		setStartDate("");
		setEndDate("");
		setBillNumber("");
		setModeOfBill("");
		setCategory("");
		setSRN("");
		setName("");

		filterRef.current.classList.toggle(styles.no__display);
		setFiltersCleared(true);
	};

	const getAllEnsaaSearch =
		({ currentPage = 1, pageSize = 20 } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);
			// if (!filterRef.current.classList.contains(styles.no__display)) {
			// 	filterRef.current.classList.toggle(styles.no__display);
			// }

			try {
				const info = await _getAllEnsaaSearch({
					currentPage,
					pageSize
				});

				if (info) {
					setData(info.data);
					setTotal(info.totalRecords);
					setAlert({
						showing: true,
						type: "success",
						message: "Records retrieved successfully"
					});
				}
			} catch (error) {
				console.error(error);
				setAlert({
					showing: true,
					type: "error",
					message:
						error.response?.data?.message || "An error occurred"
				});
			} finally {
				setLoading(false);
			}
		};

	useEffect(() => {
		getAllEnsaaSearch()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		getAllEnsaaSearch()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const exportAnalytics = async () => {
		const info = await _getAllEnsaaSearch({
			currentPage: 1,
			pageSize: total
		});
		if (!info.data) return;
		exportExcel(
			remapObj(info.data, header),
			header,
			`Purchasing-Tax Report.xlsx`
		);
	};

	return (
		<div>
			<div>
				{alert.showing && (
					<Alert text={alert.message} type={alert.type} />
				)}
				<div className={styles.actions__div}>
					<button
						className={[
							"primary__btn",
							styles.download__button
						].join(" ")}
						onClick={() => exportAnalytics()}
					>
						Download Report
					</button>

					<div className={styles.filter}>
						<button
							onClick={() => {
								filterRef.current.classList.toggle(
									styles.no__display
								);
							}}
							className={styles.filter__button}
						>
							Filter
						</button>
						<div
							ref={filterRef}
							className={[
								styles.filter__div,
								styles.no__display
							].join(" ")}
						>
							<div className={styles.filter__header}>
								<h6>Filter</h6>
								<button
									aria-labelledby="close filter button"
									onClick={() => {
										filterRef.current.classList.toggle(
											styles.no__display
										);
									}}
								>
									X
								</button>
							</div>
							<button
								className={styles.clear__filter_fields}
								onClick={clearFilters}
								disabled={
									!minAmount &&
									!maxAmount &&
									!startDate &&
									!endDate &&
									!paymentStats
								}
							>
								Clear Filter fields
							</button>
							<p>Payment Status</p>
							<form>
								<div>
									<label htmlFor="paymentStats">
										payment Status
									</label>
									<input
										name="paymentStats"
										id="paymentStats"
										type="text"
										value={paymentStats}
										onChange={(e) =>
											setPaymentStat(e.target.value)
										}
									/>
								</div>
								<div>
									<label htmlFor="Esbn">ESBN</label>
									<input
										name="Esbn"
										id="Esbn"
										type="text"
										value={esbn}
										onChange={(e) =>
											setEsbn(e.target.value)
										}
									/>
								</div>
							</form>
							<form>
								<div>
									<label htmlFor="category">category</label>
									<input
										name="category"
										id="category"
										type="text"
										value={category}
										onChange={(e) =>
											setCategory(e.target.value)
										}
										placeholder="A-sign"
									/>
								</div>
								<div>
									<label htmlFor="modeofbill">
										Mode Of Bill
									</label>
									<input
										name="modeofbill"
										id="modeofbill"
										type="text"
										value={modeofbill}
										onChange={(e) =>
											setModeOfBill(e.target.value)
										}
										placeholder="corporate"
									/>
								</div>
							</form>
							<p>By Amount</p>
							<form>
								<div>
									<label htmlFor="minAmount">
										Min Amount
									</label>
									<input
										name="minAmount"
										id="minAmount"
										type="text"
										value={minAmount}
										onChange={(e) =>
											setMinAmount(e.target.value)
										}
										placeholder="50"
									/>
								</div>
								<div>
									<label htmlFor="maxAmount">
										Max Amount
									</label>
									<input
										name="maxAmount"
										id="maxAmount"
										type="text"
										value={maxAmount}
										onChange={(e) =>
											setMaxAmount(e.target.value)
										}
										placeholder="50000000"
									/>
								</div>
							</form>
							<form>
								<div>
									<label htmlFor="billNumber">
										Bill Number
									</label>
									<input
										name="billNumber"
										id="billNumber"
										type="text"
										value={billNumber}
										onChange={(e) =>
											setBillNumber(e.target.value)
										}
										placeholder="50"
									/>
								</div>
								<div>
									<label htmlFor="maxAmount">File Type</label>
									<input
										name="fileType"
										id="fileType"
										type="text"
										value={fileType}
										onChange={(e) =>
											setFileType(e.target.value)
										}
										placeholder="Application"
									/>
								</div>
							</form>
							<p>By Creation Date</p>
							<form>
								<div>
									<label htmlFor="start_date">
										Start Date
									</label>
									<input
										name="start_date"
										id="start_date"
										type="date"
										value={startDate}
										onChange={(e) =>
											setStartDate(e.target.value)
										}
									/>
								</div>
								<div>
									<label htmlFor="start_date">End Date</label>
									<input
										name="end_date"
										id="end_date"
										type="date"
										value={endDate}
										onChange={(e) =>
											setEndDate(e.target.value)
										}
									/>
								</div>
							</form>
							<form>
								<div>
									<label htmlFor="name">Name</label>
									<input
										name="name"
										id="name"
										type="text"
										value={name}
										onChange={(e) =>
											setName(e.target.value)
										}
										placeholder="chioma obi"
									/>
								</div>
							</form>
							<button
								className={[
									styles.submit__button,
									"primary__btn"
								].join(" ")}
								onClick={getAllEnsaaSearch()}
							>
								Search
							</button>
						</div>
					</div>
				</div>

				{loading && !data.length ? (
					<p style={{ textAlign: "center" }}>Loading...</p>
				) : (
					<div className={style.table_container}>
						<Table
							headers={header}
							data={data}
							full
							total={total}
							onPageChanged={getAllEnsaaSearch}
							pageSize={20}
							// onPay={(data) => {
							// 	setBillNumber(data.ApplicationBillNumber);
							// 	setShowPaymentModal(true);
							// }}
							onView={(data) => {
								history.push(
									`/ensaa/authoriser/view/${data.id}`
								);
							}}
							// onEdit={(data) => {
							// 	history.push(`/ensaa/edit/${data.id}`);
							// }}
						/>
					</div>
				)}
			</div>
			{/* <section>
				{showPaymentModal && (
					<PaymentModal
						showing={showPaymentModal}
						toggle={setShowPaymentModal}
						billNumber={billNumber}
					/>
				)}
			</section> */}
		</div>
	);
};
export default EnnsaaAuthoriser;
