import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import styles from "../../Identity-management-individual/style.module.css";
import styles2 from "../../Direct-assessment/Create-direct-assessment/style.module.css";
import axios from "axios";
import Table from "../../components/Table";
import { useHistory } from "react-router";
import constants from "../../constants";
import { useParams } from "react-router-dom";
import GoBackButton from "../../components/goBackButton";
import Alert from "../../components/Alert";

const EnsaaRecordsEdit = () => {
	const { id } = useParams();
	const history = useHistory();
	const [categories, setCategories] = useState([]);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	useEffect(() => {
		axios
			.get(`${constants.BASE_URL_2}/enssaa/${id}`)
			.then((res) => {
				const response = res.data?.data || {};

				setEnsaaInfo(response?.application);
				setCategories(response?.categories);

				return response;
			})
			.catch((error) => {
				setAlert({
					showing: true,
					type: "error",
					message:
						error?.response.data?.message ||
						error.response.data?.errors?.[0]?.error ||
						error.response.data?.errors?.details[0]?.message
				});

				return null;
			});
	}, [id]);

	//header
	const headers = [
		["S/N", "SN"],
		["Sub-category", "SubCategory"],
		["Bill Location/Signage", "Address"],
		["Type", "Type"],
		["Branch Name", "Branch"],
		["Branch Address", "Address"],
		["Faces", "Faces"],
		["units", "Units"],
		["Application Fee", "ApplicationFee"],
		["Inspection Fee", "InspectionFee"],
		["Registration Fee", "RegistrationFee"],
		["Rate", "Rate"]
	];

	const header = [
		["S/N", "SN"],
		["Receipt no.", "EReceiptNo"],
		["Bank Name", "BankName"],
		["issued to", "CustomerName"],
		["amount Paid (₦)", "Amount"],
		["Balance (₦)", "Amount"],
		["Payment Date", "PaymentDate"],
		["Revenue Source", "RevenueCode"]
	];
	const [ensaaInfo, setEnsaaInfo] = useState({
		Year: "",
		ESBN: "",
		Name: "",
		PhoneNumber: "",
		Email: "",
		Address: " ",
		ModeOfBill: "",
		Discount: 0,
		DiscountReason: "",
		Sector: ""
	});
	const [sectors, setSectors] = useState([]);
	const [receiptNo, setReceiptNo] = useState("");
	const [getprepay2, setGetPrePay2] = useState([]);

	// Handle input change for company info
	const handleensaaInfoChange = (e) => {
		const { name, value } = e.target;

		// Ensure that discount and outstanding are stored as numbers
		if (name === "discount" || name === "outstanding") {
			// Allow only numeric input and handle empty input
			if (value === "" || (!isNaN(value) && Number(value) >= 0)) {
				setEnsaaInfo((prevState) => ({
					...prevState,
					[name]: value === "" ? "" : Number(value) // Convert to number if not empty
				}));
			}
		} else {
			setEnsaaInfo((prevState) => ({
				...prevState,
				[name]: value
			}));
		}
	};

	//getting sector
	useEffect(() => {
		const getSector = async () => {
			try {
				const res = await axios.get(`/settings/economicsector`);
				setSectors(res.data?.data); // Assuming res.data is an array of sectors
			} catch (error) {
				console.error("Error fetching sectors:", error);
			}
		};
		getSector();
	}, []);

	//get prepayment
	useEffect(() => {
		const getPrepayment = async () => {
			const res = await axios.get(
				`${constants.BASE_URL}/gettmspayref/${receiptNo}`
			);

			setGetPrePay2((prev) => [...prev, res.data.data]);
			console.log("getprepay", res.data.data);
		};
		if (receiptNo?.length >= 6) getPrepayment();
	}, [receiptNo]);

	const handleAddCategory = async (e) => {
		e.preventDefault();
		setLoading(true);

		const payload = {
			Year: ensaaInfo.Year,
			ESBN: ensaaInfo.ESBN,
			Name: ensaaInfo.Name,
			PhoneNumber: ensaaInfo.PhoneNumber,
			Email: ensaaInfo.Email,
			Address: ensaaInfo.Address,
			ModeOfBill: ensaaInfo.ModeOfBill,
			Discount: 0,
			DiscountReason: " ",
			Sector: ensaaInfo.Sector,
			Category: ensaaInfo.Category,
			PrePayments: [],
			Categories: categories.map(
				({ id, createdAt, updatedAt, ...rest }) => rest
			)
		};
		try {
			const res = await axios.post(
				`${constants.BASE_URL_2}/enssaa/update/${id}`,
				payload
			);
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res.data.message
			});
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message:
					error?.response.data?.message ||
					error.response.data?.errors?.[0]?.error ||
					error.response.data?.errors?.details[0]?.message
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<div>
			<GoBackButton />
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<form className={styles.columned__form}>
				<h2 className={styles.input__label}>
					Mode Of Bill <span style={{ color: "red" }}>*</span>{" "}
				</h2>
				<div className={styles2.radio__button_div}>
					<div>
						<div>
							<input
								id="YEARLY"
								type="radio"
								value="YEARLY"
								checked={ensaaInfo.ModeOfBill === "YEARLY"}
								onChange={handleensaaInfoChange}
								name="ModeOfBill" // Ensure the name matches
							/>
							<label className={styles.input__label}>
								Yearly
							</label>
						</div>
						<div>
							<input
								id="MONTHLY"
								type="radio"
								value="MONTHLY"
								checked={ensaaInfo.ModeOfBill === "MONTHLY"}
								onChange={handleensaaInfoChange}
								name="ModeOfBill" // Ensure the name matches
							/>
							<label className={styles.input__label}>
								Monthly
							</label>
						</div>
					</div>
				</div>
				{ensaaInfo.ModeOfBill === "YEARLY" && (
					<div className={styles.double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="Year"
							>
								Year (Fiscal Year)
								<span style={{ color: "red" }}> *</span>
							</label>
							<select
								name="Year"
								value={ensaaInfo.Year}
								onChange={handleensaaInfoChange}
								required
							>
								<option value="">Select a year</option>
								{Array.from({ length: 100 }, (_, i) => {
									const year =
										new Date().getFullYear() + 1 - i;
									return (
										<option key={year} value={year}>
											{year}
										</option>
									);
								})}
							</select>
						</div>
					</div>
				)}
				{ensaaInfo.ModeOfBill === "MONTHLY" && (
					<div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="Year"
								>
									Bill Year (Fiscal Year)
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									name="Year"
									value={ensaaInfo.Year}
									onChange={handleensaaInfoChange}
									required
								>
									<option value="">Select a year</option>
									{Array.from({ length: 100 }, (_, i) => {
										const year =
											new Date().getFullYear() + 1 - i;
										return (
											<option key={year} value={year}>
												{year}
											</option>
										);
									})}
								</select>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="billMonth"
								>
									Month of Assessment
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									name="billMonth"
									value={ensaaInfo.billMonth}
									onChange={handleensaaInfoChange}
									required
								>
									<option value="">Select a month</option>
									{[
										"January",
										"February",
										"March",
										"April",
										"May",
										"June",
										"July",
										"August",
										"September",
										"October",
										"November",
										"December"
									].map((month) => (
										<option key={month} value={month}>
											{month}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
				)}
				<hr />
				<div className={styles.double__inputs}>
					<div>
						<label className={styles.input__label} htmlFor="ESBN">
							Applicant ESBN
							<span style={{ color: "red" }}> *</span>
						</label>
						<input
							type="text"
							id="ESBN"
							name="ESBN"
							placeholder="Enter ESBN"
							value={ensaaInfo.ESBN}
							onChange={handleensaaInfoChange}
						/>
					</div>
					<div>
						<label className={styles.input__label} htmlFor="Name">
							Name
							<span style={{ color: "red" }}> *</span>
						</label>
						<input
							type="text"
							s
							id="Name"
							name="Name"
							placeholder="Enter Name"
							value={ensaaInfo.Name}
							onChange={handleensaaInfoChange}
						/>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="PhoneNumber"
						>
							Phone Number
							<span style={{ color: "red" }}> *</span>
						</label>
						<input
							type="text"
							name="PhoneNumber"
							id="PhoneNumber"
							placeholder="Enter your phone Number"
							value={ensaaInfo.PhoneNumber}
							onChange={handleensaaInfoChange}
						/>
					</div>
					<div>
						<label className={styles.input__label} htmlFor="Email">
							Email
							<span style={{ color: "red" }}> *</span>
						</label>
						<input
							type="text"
							name="Email"
							id="Email"
							placeholder="Enter Email"
							value={ensaaInfo.Email}
							onChange={handleensaaInfoChange}
						/>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<label className={styles.input__label} htmlFor="Sector">
							Sector
							<span style={{ color: "red" }}> *</span>
						</label>
						<select
							name="Sector"
							value={ensaaInfo.Sector}
							onChange={handleensaaInfoChange} // Update ensaaInfo.sector on selection
							required
						>
							<option value="">Select a sector</option>
							{sectors &&
								sectors.map((sector) => (
									<option
										key={sector.Code}
										value={sector.Code}
									>
										{" "}
										{/* Add key here */}
										{sector.Description}
									</option>
								))}
						</select>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="Address"
						>
							Address
							<span style={{ color: "red" }}> *</span>
						</label>
						<input
							id="Address"
							name="Address"
							placeholder="Enter Address"
							value={ensaaInfo.Address}
							onChange={handleensaaInfoChange}
						/>
					</div>
				</div>
				<hr />
			</form>

			{/* table */}
			<div>
				<h3 className={style.title}>Categories</h3>
			</div>

			<Table
				headers={headers}
				data={categories}
				onEdit={(data) => {
					history.push(`/ensaa/edit/category/${data.id}`, {
						category: data
					});
				}}
				full
			/>

			<hr />
			<div className={styles.columned__form}>
				<h3 className={style.title}>Prepayments</h3>
				<div className={styles.double__inputs}>
					<div>
						<input
							id="receiptNo"
							name="receiptNo"
							placeholder="Enter Receipt Number"
							value={receiptNo}
							onChange={(e) => setReceiptNo(e.target.value)}
						/>
					</div>
				</div>
			</div>
			{(getprepay2 || []).length > 0 && (
				<Table headers={header} data={getprepay2} full />
			)}

			{/* other details */}
			<form className={styles.columned__form}>
				<h3 className={style.title}>Other Details</h3>

				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="Discount"
						>
							Discount Amount (₦)
							<span style={{ color: "red" }}> *</span>
						</label>
						<input
							id="Discount"
							name="Discount"
							placeholder="Enter Discount Amount"
							value={ensaaInfo.Discount}
							onChange={handleensaaInfoChange}
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="DiscountReason"
						>
							Discount Description
							<span style={{ color: "red" }}> *</span>
						</label>
						<input
							id="DiscountReason"
							name="DiscountReason"
							placeholder="Enter Discount Description"
							value={ensaaInfo.DiscountReason}
							onChange={handleensaaInfoChange}
						/>
					</div>
				</div>

				<div className={style.buttonContainer}>
					<button
						className={["primary__btn", style.stylebtn2].join(" ")}
						onClick={handleAddCategory}
					>
						{loading ? "loading..." : "Update"}
					</button>
				</div>
			</form>
			<hr />
		</div>
	);
};

export default EnsaaRecordsEdit;
