import React, { useState } from "react";
import constants from "../../../constants";
import axios from "axios";
import styles3 from "../../../Identity-management-individual/style.module.css";
import styles from "../style.module.css";
import anssidStyles from "../../StateTIN/style.module.css";

export const EditEmployerContactInfo = ({
	contractInfo,
	setAlert,
	tariffTypes,
	setIsEditing,
	recalculateContractorTaxDeal,
	checkingESBNRecord,
	setCheckingESBNRecord
}) => {
	const [contractInfoForUpdate, setContractInfoForUpdate] =
		useState(contractInfo);

	const handleChange = (e) => {
		const { id, value } = e.target;
		setContractInfoForUpdate((prev) => ({
			...prev,
			[id]: value
		}));
	};

	const fetchESBNdetails = async (ESBN, esbnType) => {
		setCheckingESBNRecord(true);
		try {
			const response = await axios.get(
				`${constants.BASE_URL_2}/users/anssid?anssid=${ESBN}`
			);
			const {
				firstname,
				middlename,
				surname,
				accounttype,
				name,
				phoneNumber,
				phonenumber,
				email,
				stateOfficeAddress,
				residentialaddress
			} = response.data.data;
			const fullName = `${firstname} ${middlename} ${surname}`;

			if (esbnType === "contractor") {
				setContractInfoForUpdate((prevValues) => ({
					...prevValues,
					Contractor_Name:
						accounttype === "corporate" ? name : fullName,
					Contractor_PhoneNumber:
						accounttype === "individual"
							? phonenumber
							: phoneNumber,
					Contractor_Email: email || prevValues.Contractor_Email,
					Contractor_Address:
						accounttype === "corporate"
							? stateOfficeAddress
							: residentialaddress
				}));
			}
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		} finally {
			setCheckingESBNRecord(false);
		}
	};

	const updateContractorsTable = () => {
		recalculateContractorTaxDeal(contractInfoForUpdate);
		setContractInfoForUpdate({
			...contractInfoForUpdate,
			Contractor_ESBN: "",
			Contractor_Name: "",
			Contractor_PhoneNumber: "",
			Contractor_Email: "",
			Contractor_Address: "",
			Sum: "",
			Rate: "",
			RevenueCode: "",
			StartDate: "",
			EndDate: "",
			PaymentDate: "",
			Contract_Type: ""
		});
		setIsEditing(false);
		setTimeout(() => {
			document.getElementById("table").scrollIntoView({
				behavior: "smooth",
				block: "start"
			});
		});
	};

	return (
		<form id="contractorsForm" className={styles3.columned__form}>
			<section id="contractorsForm">
				<h3 className={styles.headerInfo}>Contractor’s Information</h3>
				<div className={styles3.double__inputs}>
					<div>
						<label htmlFor="ContractorESBN">
							Contractor ESBN{" "}
							<span style={{ color: "red" }}> *</span>
						</label>
						<input
							id="ContractorESBN"
							value={contractInfoForUpdate.Contractor_ESBN}
							onChange={(e) => {
								setContractInfoForUpdate((prevValues) => ({
									...prevValues,
									Contractor_ESBN: e.target.value
								}));
								if (e.target.value >= 10) {
									fetchESBNdetails(
										e.target.value,
										"contractor"
									);
								}
							}}
							placeholder="eg. 54826648633"
						/>
						{checkingESBNRecord && (
							<p
								style={{
									fontSize: "12px",
									margin: "-15px 0 10px",
									color: "#9e9e9e"
								}}
							>
								checking record...
							</p>
						)}
					</div>
					<div>
						<label htmlFor="contractorName">
							Contractor Name{" "}
							<span style={{ color: "red" }}> *</span>
						</label>
						<input
							id="contractorName"
							type="text"
							value={contractInfoForUpdate.Contractor_Name}
							onChange={handleChange}
							placeholder="Don Joe"
							disabled
						/>
					</div>
				</div>
				<div className={styles3.double__inputs}>
					<div>
						<label htmlFor="PhoneNumber">
							Phone Number{" "}
							<span style={{ color: "red" }}> *</span>
						</label>
						<input
							type="text"
							id="Contractor_PhoneNumber"
							value={contractInfoForUpdate.Contractor_PhoneNumber}
							onChange={handleChange}
							placeholder="0814973750"
						/>
					</div>
					<div>
						<label htmlFor="email">
							Email <span style={{ color: "red" }}> *</span>
						</label>
						<input
							type="email"
							id="Contractor_Email"
							value={contractInfoForUpdate.Contractor_Email}
							onChange={handleChange}
							placeholder="joedon@gmail.com"
						/>
					</div>
				</div>
				<label htmlFor="Contractor_Address">
					Address <span style={{ color: "red" }}> *</span>
				</label>
				<input
					type="text"
					id="Contractor_Address"
					long="true"
					value={contractInfoForUpdate.Contractor_Address}
					onChange={handleChange}
					placeholder="igbo-eke"
				/>

				{/* individual contractor  information calc section */}
				<section>
					<div className={styles3.double__inputs}>
						<div className={styles.inputContainer}>
							<label htmlFor="contractualSum">
								Contractual Sum agreed
								<span style={{ color: "red" }}> *</span>
							</label>
							<input
								id="Sum"
								value={contractInfoForUpdate.Sum}
								onChange={handleChange}
								placeholder="enter amount"
							/>
						</div>
						<div>
							<label htmlFor="tariffRate">
								Tariff/Rate{" "}
								<span style={{ color: "red" }}>*</span>
							</label>
							<select
								id="Rate"
								value={contractInfoForUpdate.TariffDescription}
								onChange={handleChange}
							>
								<option>Select Tariff Type</option>
								{tariffTypes.map((option) => (
									<option key={option.id}>
										{option.Name}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className={styles3.double__inputs}>
						<div>
							<label htmlFor="startDate">
								Contract Start Date
								<span style={{ color: "red" }}> *</span>
							</label>
							<input
								type="date"
								id="StartDate"
								value={contractInfoForUpdate.StartDate}
								onChange={handleChange}
							/>
						</div>
						<div>
							<label htmlFor="endDate">
								Contract End Date
								<span style={{ color: "red" }}> *</span>
							</label>
							<input
								type="date"
								id="EndDate"
								value={contractInfoForUpdate.EndDate}
								onChange={handleChange}
							/>
						</div>
					</div>
					<div className={styles3.double__inputs}>
						<div>
							<label htmlFor="contractType">
								Type of contract
								<span style={{ color: "red" }}> *</span>
							</label>
							<input
								type="text"
								long="true"
								id="Contract_Type"
								value={contractInfoForUpdate.Contract_Type}
								onChange={handleChange}
								placeholder="enter the nature of the contract"
							/>
						</div>
						<div>
							<label htmlFor="PaymentDate">
								Payment Date{" "}
								<span style={{ color: "red" }}> *</span>
							</label>
							<input
								type="date"
								id="PaymentDate"
								value={contractInfoForUpdate.PaymentDate}
								onChange={handleChange}
							/>
						</div>
					</div>

					<button
						type="button"
						className={`secondary__btn ${anssidStyles.create__btn}`}
						style={{
							float: "right",
							marginTop: "4px",
							marginBottom: "48px"
						}}
						onClick={() => {
							updateContractorsTable();
						}}
					>
						Update Contract
					</button>
				</section>
			</section>
		</form>
	);
};
