import React, { useEffect, useState } from "react";
import axios from "axios";
import style from "../../../Identity-management-individual/style.module.css";
import styles from "./style.module.css";
import style1 from "../../AnnualReturns/style.module.css";
import Table from "../../../components/Table";
import Alert from "../../../components/Alert";
import trashIcon from "../../../assets/Filing/trashIcon.svg";
import { formatAmount } from "../../../utils";

const dateFiled = new Date().toLocaleDateString();

const headers = [
	["Name", "CustomerName"],
	["Reciept Number", "ReceiptNo"],
	["Ammount (₦)", "Amount", formatAmount],
	["Payment Date", "PaymentDate"]
];

function objectExistsWithKeyValue(array, key, value) {
	for (var i = 0; i < array.length; i++) {
		if (array[i][key] === value.ReceiptNo) {
			return true; // Found a matching object
		}
	}
	return false; // No matching object found
}

export const StepTwo = ({ updateFilingData = () => {}, filingData = null }) => {
	const [loading, setLoading] = useState(false);
	const [authority, setAuthority] = useState({
		signature: filingData?.authority?.signature ?? null,
		signatory: filingData?.authority?.signatory ?? "",
		designation: filingData?.authority?.designation ?? "",

		email: filingData?.authority?.email ?? "",
		phone: filingData?.authority?.phone ?? "",
		dateFiled: filingData?.authority?.dateFiled ?? dateFiled
	});

	const [recieptData, setRecieptData] = useState({
		receiptNo: filingData?.recieptData?.receiptNo ?? "",
		data: filingData?.recieptData?.data ?? []
	});

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const restrictFileSize = (file, field) => {
		if (file) {
			// Check if the file size exceeds 500KB
			const fileSizeInKB = file.size / 1024;

			if (fileSizeInKB > 500) {
				setAlert({
					showing: true,
					type: "error",
					message:
						"File size exceeds the 500KB limit. Please choose a smaller file."
				});
			} else {
				setAuthority((prev) => ({
					...prev,
					[field]: file
				}));
			}
		}
	};

	const fetchRecieptDetails = async () => {
		setLoading(true);

		try {
			const accessToken = localStorage.getItem("access_token");
			let data = await axios
				.get(`gettmspayref/${recieptData?.receiptNo}`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => res.data || { data: {} });

			if (!data || !data?.data) {
				// handle errors
				return null;
			}
			setLoading(false);

			const recieptExists = objectExistsWithKeyValue(
				recieptData?.data,
				"ReceiptNo",
				data.data
			);

			if (!recieptExists) {
				setRecieptData((prev) => ({
					...prev,
					receiptNo: "",
					data: [...prev.data, data.data]
				}));

				updateFilingData({
					...filingData,
					recieptData: {
						receiptNo: recieptData.receiptNo,
						data: [
							...(filingData?.recieptData?.data || []),
							data.data
						]
					}
				});

				setAlert({
					showing: true,
					type: "success",
					message:
						data?.description?.description.toUpperCase() ??
						"Reciept Number fetched successful!"
				});
			} else {
				setAlert({
					showing: true,
					type: "error",
					message: "Reciept Number Already Exists!"
				});

				setRecieptData((prev) => ({
					...prev,
					receiptNo: ""
				}));
			}
		} catch (err) {
			setAlert({
				showing: true,
				type: "error",
				message:
					err?.response?.data?.message ??
					err?.response?.data?.msg ??
					"Reciept Number fetch Unsuccessful!"
			});
		} finally {
			setLoading(false);
		}
	};

	const deleteItem = (item) => {
		if (recieptData?.data) {
			const filteredData = recieptData?.data?.filter(
				(singleBill) => singleBill?.id !== item
			);
			setRecieptData((prev) => ({
				...prev,
				data: filteredData
			}));
			updateFilingData({
				...filingData,
				recieptData: {
					...recieptData,
					data: filteredData
				}
			});
		}
	};

	useEffect(() => {
		if (authority.signature && !Object.values(authority).includes("")) {
			updateFilingData({
				...filingData,
				authority: authority
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authority]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<div className={styles.wrapper}>
				<div className={`row ${styles.authority}`}>
					<div className={`col-md-5`}>
						<p>
							<strong>
								Upload Signature (Authorised Signature)
								<span style={{ color: "red" }}> *</span>
							</strong>
						</p>

						<span className={style1.flex__left}>
							<input
								type="file"
								id="signature-btn"
								style={{ display: "none" }}
								accept="image/png, image/jpeg"
								onChange={(e) => {
									restrictFileSize(
										e.target.files[0],
										"signature"
									);
								}}
							/>
							<label
								htmlFor="signature-btn"
								className={`btn btn-success ${styles.downloads__button}`}
							>
								<p>Choose File</p>
							</label>
							<span className={styles.ml_1}>
								{authority?.signature && (
									<a
										href={URL?.createObjectURL(
											authority?.signature
										)}
										target="_blank"
										rel="noreferrer"
										style={{ color: "green" }}
									>
										{authority?.signature?.name}
									</a>
								)}
							</span>
							{authority?.signature?.name && (
								<span className={styles.ml_1}>
									<img
										src={trashIcon}
										alt="delete"
										className={styles.delete_icon}
										onClick={() => {
											setAuthority((prev) => ({
												...prev,
												signature: null
											}));
										}}
									/>
								</span>
							)}
						</span>
					</div>

					<div className={`col-md-7`}>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={style.input__label}
									htmlFor="signatory"
								>
									Name of Signatory{" "}
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									type="text"
									onChange={(e) => {
										setAuthority((prev) => ({
											...prev,
											signatory: e.target.value
										}));
									}}
									id="signatory"
									placeholder="enter the name of signatory"
									required
									value={authority?.signatory}
								/>
							</div>
							<div>
								<label
									className={style.input__label}
									htmlFor="designation"
								>
									Designation{" "}
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									type="text"
									onChange={(e) => {
										setAuthority((prev) => ({
											...prev,
											designation: e.target.value
										}));
									}}
									id="designation"
									placeholder="enter designation"
									required
									value={authority?.designation}
								/>
							</div>
						</div>
						<br />
						<div className={styles.double__inputs}>
							<div>
								<label
									className={style.input__label}
									htmlFor="email"
								>
									Email{" "}
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									type="text"
									onChange={(e) => {
										setAuthority((prev) => ({
											...prev,
											email: e.target.value
										}));
									}}
									id="email"
									placeholder="enter email"
									required
									value={authority?.email}
								/>
							</div>
							<div>
								<label
									className={style.input__label}
									htmlFor="phone"
								>
									Phone{" "}
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									type="text"
									onChange={(e) => {
										setAuthority((prev) => ({
											...prev,
											phone: e.target.value
										}));
									}}
									id="phone"
									placeholder="enter phone"
									required
									value={authority?.phone}
								/>
							</div>
						</div>
					</div>
				</div>
				<br />
				<div className={`bg-warning ${styles.notice__center}`}>
					<p>
						Enter Reciept Number for each remittance made in{" "}
						{new Date().getFullYear() - 1} (PAYE and direct
						assessments bills numbers only)
					</p>
				</div>
				<br />
				<br />
				<div className={style1.flex__left}>
					<input
						type="text"
						onChange={(e) =>
							setRecieptData((prev) => ({
								...prev,
								receiptNo: e.target.value
							}))
						}
						id="billNumber"
						placeholder="Reciept Number"
						required
						value={recieptData.receiptNo}
					/>
					<button
						className={`btn btn-success ${styles.downloads__button} ${styles.ml_1}`}
						onClick={() => fetchRecieptDetails()}
					>
						<p>{loading ? "Adding..." : "Add"}</p>
					</button>
				</div>
				<br />

				<Table
					headers={headers}
					data={recieptData?.data || []}
					full
					pageSize={20}
					total={recieptData?.data?.length}
					onDelete={(record) => deleteItem(record.id)}
				/>
				<br />
			</div>
		</>
	);
};
