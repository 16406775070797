import React from "react";
import style from "../style.module.css"; // Optional: Add custom styles if needed

const Modal = ({ show, onClose, title, children }) => {
	if (!show) {
		return null; // Don't render anything if modal is not supposed to show
	}

	const handleCloseClick = (e) => {
		e.preventDefault();
		onClose();
	};

	return (
		<div className={style.modaloverlay}>
			<div className={style.modal}>
				<div className={style.modalheader}>
					<h2>{title}</h2>
					<button
						onClick={handleCloseClick}
						className={style.modalclosebutton}
					>
						&times;
					</button>
				</div>
				<div className={style.modalcontent}>
					{children} {/* Content passed into the modal */}
				</div>
			</div>
		</div>
	);
};

export default Modal;
