import styles3 from "../../../Identity-management-individual/style.module.css";
import styles from "../style.module.css";

const VIEWER = {
	ADMIN: "ADMIN",
	CORPORATE: "CORPORATE"
};

export const EmployerOtherDetailsComponent = ({
	employerInformationData,
	setEmployerInformationData,
	// isEditable,
	viewer = VIEWER.ADMIN
}) => {
	return (
		<section className={styles3.columned__form}>
			<hr className={styles.hr} />
			<h3 className={styles.headerInfo}> Other Details </h3>
			<div className={styles3.double__inputs}>
				<div className={styles.inputContainer}>
					<label htmlFor="Discount_Amount">Discount Amount </label>
					<input
						id="Discount_Amount"
						value={employerInformationData.Discount}
						onChange={(e) => {
							setEmployerInformationData((prev) => ({
								...prev,
								Discount: e.target.value
							}));
						}}
						placeholder="enter amount"
						// disabled={isEditable}
					/>
				</div>
				<div>
					<label htmlFor="Discount_Info">Discount Description </label>
					<input
						type="text"
						id="Discount_Info"
						value={employerInformationData.DiscountReason}
						onChange={(e) => {
							setEmployerInformationData((prev) => ({
								...prev,
								DiscountReason: e.target.value
							}));
						}}
						placeholder="enter discount description"
						// disabled={isEditable}
					/>
				</div>
			</div>
			{viewer === VIEWER.ADMIN && (
				<div className={styles3.double__inputs}>
					<div className={styles.inputContainer}>
						<label htmlFor="Outstanding_Amount">
							Outstanding Amount{" "}
						</label>
						<input
							id="Outstanding_Amount"
							value={employerInformationData.Outstanding}
							onChange={(e) => {
								setEmployerInformationData((prev) => ({
									...prev,
									Outstanding: e.target.value
								}));
							}}
							placeholder="enter amount"
							// disabled={isEditable}
						/>
					</div>
					<div>
						<label htmlFor="Outstanding_Info">
							Outstanding Description{" "}
						</label>
						<input
							type="text"
							id="Outstanding_Info"
							value={employerInformationData.outstandingReason}
							onChange={(e) => {
								setEmployerInformationData((prev) => ({
									...prev,
									outstandingReason: e.target.value
								}));
							}}
							placeholder="enter outstanding description"
							// disabled={isEditable}
						/>
					</div>
				</div>
			)}
		</section>
	);
};
