import React, { useEffect, useState } from "react";
import styles from "../../Dashboard/MDAs/style.module.css";
import Table from "../../components/Table";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";
import Alert from "../../components/Alert";
import constants from "../../constants";
import { formatDate } from "../../utils";

axios.defaults.base1URL = constants.BASE1_URL;
const PTSetup = () => {
	const history = useHistory();
	const headers = [
		["Type", "type"],
		["Name Of Category", "name"],
		["Code", "code"],
		["Rate", "rate"],
		["Generated by", "inputter"],
		["Last Edit by", "lastModifiedByTime", formatDate]
	];

	const [getdata, setGetData] = useState([]);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const getAllSetUp = async () => {
		try {
			const res = await axios.get(
				`${constants.BASE1_URL}/purchasingTax/getAllPurchaseTaxSetup`
			);
			setGetData(res.data?.data?.data);
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message:
					error?.response.data?.message ||
					error.response.data?.errors?.[0]?.error ||
					error.response.data?.errors?.details[0]?.message
			});
		}
	};

	useEffect(() => {
		getAllSetUp();
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<div>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<div className={styles.actions__div}>
				<button
					className={["primary__btn", styles.download__button].join(
						" "
					)}
					onClick={() => history.push("/pt/pt-add-setup")}
				>
					Create New Setup
				</button>
			</div>
			<div>
				<Table headers={headers} data={getdata} full />
			</div>
		</div>
	);
};

export default PTSetup;
