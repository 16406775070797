import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import style from "../style.module.css";
import styles from "../../../Identity-management-individual/style.module.css";
// import styles2 from "../../../Direct-assessment/Create-direct-assessment/style.module.css";
import Alert from "../../../components/Alert";
import Modal from "../modal/modal";
import Receipt from "../../Receipt/receipt";
import constants from "../../../constants";
import Table from "../../../components/Table";
import { useHistory } from "react-router";

const storedUser = JSON.parse(localStorage.getItem("user_info"));
const CreateRecords = () => {
	const ref = useRef(null);
	const history = useHistory();
	const token = localStorage.getItem("access_token");
	const header = [
		["S/N", "SN"],
		["category", "Category"],
		["Tariff/Rate", "PT_Rate"],
		["Purchase Amount", "Purchases_Amount"],
		["Transaction Date", "Transaction_Date"],
		["Tax Due", "Bill_Amount"]
	];

	// State for unit filing company info

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const [amount, setAmount] = useState(0);
	const [product, setProduct] = useState("");
	const [tr, setTR] = useState(0); //TR stands for Tarrif&Rate
	const [category, setCategory] = useState("");
	// States for the categories information
	const [transactDate, settransactDate] = useState("");
	const [calculationResult, setCalculationResult] = useState(null);
	const [files, setFiles] = useState([]);
	const [convertedFiles, setConvertedFiles] = useState([]);
	const [imageData, setImageData] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [print, setPrint] = useState("");
	//fetch ecosector/category
	const [getcategories, setgetcategories] = useState([]);
	const [categories, setCategories] = useState([]);
	const [sectors, setSectors] = useState([]);

	const openModal = () => setShowModal(true);
	const closeModal = () => setShowModal(false);

	// State for unit filing company info
	const [companyInfo, setCompanyInfo] = useState({
		esbn: storedUser?.anssid,
		email: storedUser?.email,
		name: storedUser?.corpname,
		phone: storedUser?.phonenumber,
		sector: "",
		address: storedUser?.address,
		filingMode: "",
		billYear: "",
		billMonth: "",
		discount: 0,
		discountReason: "",
		outstanding: 0,
		outstandingReason: "",
		startDate: null,
		endDate: null,
		fileType: "Unit Assessment" // Retains file type
	});

	// Handle input change for company info
	const handleCompanyInfoChange = (e) => {
		const { name, value } = e.target;

		// Ensure that discount and outstanding are stored as numbers
		if (name === "discount" || name === "outstanding") {
			// Allow only numeric input and handle empty input
			if (value === "" || (!isNaN(value) && Number(value) >= 0)) {
				setCompanyInfo((prevState) => ({
					...prevState,
					[name]: value === "" ? "" : Number(value) // Convert to number if not empty
				}));
			}
		} else {
			setCompanyInfo((prevState) => ({
				...prevState,
				[name]: value
			}));
		}
	};

	//getting sector
	useEffect(() => {
		const getSector = async () => {
			try {
				const res = await axios.get(`/settings/economicsector`);
				setSectors(res.data?.data); // Assuming res.data is an array of sectors
			} catch (error) {
				console.error("Error fetching sectors:", error);
			}
		};
		getSector();
	}, []);

	const handleAddCategory = async (e) => {
		e.preventDefault();

		// Create final object with company info and categories array
		const payload = {
			companyInfo,
			categories: [...categories], // Include the new category
			files: [...files]
		};
		console.log("get pay", payload);

		try {
			const response = await axios.post(
				`${constants.BASE1_URL}/purchasingTax/createCompanyInfo`,
				payload
			); // Replace with your API endpoint
			setImageData(response.data);
			setPrint(response.data);
			console.log("Success:", response.data);
			// Clear Table
			setCategories("");

			if (response.status === 200) {
				openModal(); // Open the modal upon successful response
			}
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message:
					error?.response.data?.message ||
					error.response.data?.errors?.[0]?.error ||
					error.response.data?.errors?.details[0]?.message
			});
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);
	const vatAmt = (tr / 100) * amount;
	const handleAddGoods = () => {
		const newCategory = {
			SN: categories.length + 1,
			Product: product,
			Bill_Amount: Number(vatAmt),
			Transaction_Date: transactDate,
			Purchases_Amount: Number(amount),
			Status: "",
			PT_Rate: Number(tr),
			Category: category
		};
		ref.current?.scrollIntoView({
			behavior: "smooth",
			block: "start",
			inline: "nearest",
			offset: { top: -100 }
		});

		setCategories([...categories, newCategory]); // Add the new category to the categories array

		// Clear input fields after adding
		setTR(0);
		setProduct("");
		setAmount("");
		settransactDate("");
		setImageData("");
		setCategory("");
	};

	//delete goods function
	const handleDeleteCategory = (tr) => {
		const updatedCategories = categories.filter(
			(prod) => prod.SN !== tr.SN
		);
		setCategories(updatedCategories);
	};

	const handleFileUpload = async (event) => {
		const uploadedFiles = Array.from(event.target.files);

		// Convert each file to Base64 and store the name along with the Base64 string
		const base64Files = await Promise.all(
			uploadedFiles.map(async (file) => ({
				fileName: file.name,
				fileType: file.type,
				File: await convertToBase64(file)
			}))
		);

		setFiles((prevFiles) => [...prevFiles, ...base64Files]);
	};

	const convertToBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	};

	const handleRemoveFile = (index) => {
		setFiles(files.filter((_, i) => i !== index));
		setConvertedFiles(convertedFiles.filter((_, i) => i !== index));
	};
	//category sector
	const econSect = async () => {
		const res = await axios.get(
			`${constants.BASE1_URL}/purchasingTax/getActivePurchaseTaxSetUps`,
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);
		if (res) {
			const data = res.data.data?.data;
			setgetcategories(data);
		}
	};
	useEffect(() => {
		econSect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Handle tax calculation
	const handleCalculateTax = async (e) => {
		e.preventDefault();
		console.log("cal", categories);

		try {
			// Make the API call to get calculated result (replace with your API endpoint)
			const response = await axios.post(
				`${constants.BASE1_URL}/purchasingTax/calculateTax`,
				{
					discount: companyInfo.discount,
					outstanding: companyInfo.outstanding,
					products: [...categories]
				}
			);

			// Store the result from the API response
			setCalculationResult(response.data);

			console.log("Calculation Result:", response.data);
		} catch (error) {
			console.error(
				"Error calculating:",
				error.response ? error.response.data : error.message
			);
			setAlert({
				showing: true,
				type: "error",
				message:
					error?.response.data?.message ||
					error.response.data?.errors?.[0]?.error ||
					error.response.data?.errors?.details[0]?.message
			});
		}
	};
	const printReceipt = (e) => {
		e.preventDefault();

		const w = window.open();
		w.document.write(print);
		w.document.close();
		w.onload = () => {
			w.focus();
			w.print();
		};

		// calculationResult("");

		w.onafterprint = () => {
			w.close();
		};
	};

	// Scroll to the top of the table when new data is added
	useEffect(() => {
		if (ref.current) {
			ref.current.scrollIntoView({ behavior: "smooth" }); // Scroll smoothly to the table
		}
	}, [categories]); // This will trigger whenever categories array is updated

	// Handle dropdown selection
	const handleSelectChange = (e) => {
		const selectedItem = getcategories.find(
			(item) => item.name === e.target.value
		);
		setCategory(selectedItem?.name || "");
		setTR(selectedItem?.rate || ""); // Automatically populate the `tr` field with the rate
	};

	// Handle rate input change
	const handleRateChange = (e) => {
		const value = e.target.value;
		// Allow only numbers and decimals
		if (value === "" || (!isNaN(value) && Number(value) >= 0)) {
			setTR(value);
		}
	};

	return (
		<div className={styles.columned__form}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{/* good's information */}
			<div>
				<div className={style.radio__button_div}>
					<div>
						<div>
							<input
								id="Yearly"
								type="radio"
								value="Yearly"
								checked={companyInfo.filingMode === "Yearly"}
								onChange={handleCompanyInfoChange}
								name="filingMode" // Ensure the name matches
							/>
							<label className={styles.input__label}>
								Yearly
							</label>
						</div>
						<div>
							<input
								id="Monthly"
								type="radio"
								value="Monthly"
								checked={companyInfo.filingMode === "Monthly"}
								onChange={handleCompanyInfoChange}
								name="filingMode" // Ensure the name matches
							/>
							<label className={styles.input__label}>
								Monthly
							</label>
						</div>
						<div>
							<input
								id="Period"
								type="radio"
								value="Period"
								checked={companyInfo.filingMode === "Period"}
								onChange={handleCompanyInfoChange}
								name="filingMode" // Ensure the name matches
							/>
							<label className={styles.input__label}>
								Period
							</label>
						</div>
					</div>
				</div>
				{companyInfo.filingMode === "Yearly" && (
					<div className={styles.double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="billYear"
							>
								Bill Year (Fiscal Year)
								<span style={{ color: "red" }}>*</span>
							</label>
							<select
								name="billYear"
								value={companyInfo.billYear}
								onChange={handleCompanyInfoChange}
								required
							>
								<option value="">Select a year</option>
								{Array.from({ length: 100 }, (_, i) => {
									const year =
										new Date().getFullYear() + 1 - i;
									return (
										<option key={year} value={year}>
											{year}
										</option>
									);
								})}
							</select>
						</div>
					</div>
				)}
				{companyInfo.filingMode === "Monthly" && (
					<div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="billYear"
								>
									Bill Year (Fiscal Year)
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									name="billYear"
									value={companyInfo.billYear}
									onChange={handleCompanyInfoChange}
									required
								>
									<option value="">Select a year</option>
									{Array.from({ length: 100 }, (_, i) => {
										const year =
											new Date().getFullYear() + 1 - i;
										return (
											<option key={year} value={year}>
												{year}
											</option>
										);
									})}
								</select>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="billMonth"
								>
									Month of Assessment
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									name="billMonth"
									value={companyInfo.billMonth}
									onChange={handleCompanyInfoChange}
									required
								>
									<option value="">Select a month</option>
									{[
										"January",
										"February",
										"March",
										"April",
										"May",
										"June",
										"July",
										"August",
										"September",
										"October",
										"November",
										"December"
									].map((month) => (
										<option key={month} value={month}>
											{month}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
				)}
				{companyInfo.filingMode === "Period" && (
					<div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="billYear"
								>
									Bill Year (Fiscal Year)
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									name="billYear"
									value={companyInfo.billYear}
									onChange={handleCompanyInfoChange}
									required
								>
									<option value="">Select a year</option>
									{Array.from({ length: 100 }, (_, i) => {
										const year =
											new Date().getFullYear() + 1 - i;
										return (
											<option key={year} value={year}>
												{year}
											</option>
										);
									})}
								</select>
							</div>
						</div>
						<div className={styles.double__inputs}>
							{/* Period under consideration Start Date */}
							<div>
								<label>
									Period under consideration Start Date
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									type="date"
									name="startDate"
									value={companyInfo.startDate}
									onChange={handleCompanyInfoChange}
									placeholder="Select date"
									required
								/>
							</div>

							{/* Period under consideration End Date */}
							<div>
								<label>
									Period under consideration End Date
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									type="date"
									name="endDate"
									value={companyInfo.endDate}
									onChange={handleCompanyInfoChange}
									placeholder="Select date"
									required
								/>
							</div>
						</div>
					</div>
				)}
				<hr />
				<div className={styles.double__inputs}>
					<div>
						<label className={styles.input__label} htmlFor="code">
							ESBN
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							type="text"
							id="esbn"
							name="esbn"
							placeholder="enter ESBN"
							value={companyInfo.esbn}
							onChange={handleCompanyInfoChange}
							disabled
						/>
					</div>
					<div>
						<label className={styles.input__label} htmlFor="sector">
							Sector
							<span style={{ color: "red" }}>*</span>
						</label>
						<select
							name="sector"
							value={companyInfo.sector}
							onChange={handleCompanyInfoChange} // Update companyInfo.sector on selection
							required
						>
							<option value="">Select a sector</option>
							{sectors &&
								sectors.map((sector) => (
									<option
										key={sector.Code}
										value={sector.Code}
									>
										{" "}
										{/* Add key here */}
										{sector.Description}
									</option>
								))}
						</select>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<label className={styles.input__label} htmlFor="name">
							Name
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							type="text"
							id="name"
							name="name"
							placeholder="Enter Name"
							value={companyInfo.name}
							onChange={handleCompanyInfoChange}
						/>
					</div>
					<div>
						<label className={styles.input__label} htmlFor="email">
							Email
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							type="text"
							name="email"
							id="email"
							placeholder="enter Email"
							value={companyInfo.email}
							onChange={handleCompanyInfoChange}
						/>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<label className={styles.input__label} htmlFor="phone">
							Phone Number
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							type="text"
							name="phone"
							id="phone"
							placeholder="enter your phone Number"
							value={companyInfo.phone}
							onChange={handleCompanyInfoChange}
						/>
					</div>
				</div>
				<div>
					<label className={styles.input__label} htmlFor="address">
						Address
						<span style={{ color: "red" }}>*</span>
					</label>
					<input
						className={style.input__width}
						id="address"
						name="address"
						placeholder="Enter Address"
						value={companyInfo.address}
						onChange={handleCompanyInfoChange}
					/>
				</div>

				<hr />
				<h3 className={style.space}>Goods Information</h3>

				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="categorysect"
						>
							Category
							<span style={{ color: "red" }}>*</span>
						</label>
						<select
							id="econs"
							value={category}
							onChange={handleSelectChange}
						>
							<option>
								{getcategories
									? "--select --category"
									: "...Loading"}
							</option>
							{getcategories &&
								getcategories.map((getcategories) => (
									<option value={getcategories.name}>
										{getcategories.name}
									</option>
								))}
						</select>
					</div>
					<div>
						<label className={styles.input__label} htmlFor="trate">
							Tarrif/Rate
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							type="number"
							id="trate"
							name="trate"
							placeholder="Enter Tarrif/RAte"
							value={tr}
							onChange={handleRateChange}
							disabled
						/>
					</div>
				</div>

				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="product"
						>
							Product
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							type="text"
							id="product"
							name="product"
							placeholder="Enter product name"
							value={product}
							onChange={(e) => setProduct(e.target.value)}
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="purchase"
						>
							Purchase Amount
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							type="number"
							name="purchase"
							id="purchase"
							placeholder="Enter Purchase amount"
							value={amount}
							onChange={(e) => setAmount(e.target.value)}
						/>
					</div>
				</div>
				<div className={styles.double__inputs}>
					{/* Period under consideration Start Date */}
					<div>
						<label>
							Transaction Date
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							type="date"
							name="transactDate"
							value={transactDate}
							onChange={(e) => settransactDate(e.target.value)}
							placeholder="Select date"
							required
						/>
					</div>
				</div>
				<div className={style.buttonContainer}>
					<button
						className={["secondary__btn", style.btn].join(" ")}
						disabled={
							!transactDate ||
							!category ||
							!amount ||
							!product ||
							!tr
						}
						onClick={handleAddGoods}
					>
						Add Good
					</button>
				</div>
			</div>

			{categories.length > 0 && (
				<>
					<div
						style={{
							maxHeight: "300px",
							overflowY: "auto"
						}} // Set table container with scrollable area
						ref={ref}
					>
						<Table
							headers={header}
							data={categories}
							full
							onDelete={(tr) => handleDeleteCategory(tr)}
						/>
					</div>

					<hr />
					{/* Other details */}

					<div className={styles.double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="discount"
							>
								Discount Amount
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="num ber"
								name="discount"
								id="discount"
								placeholder="Enter Discount Amount"
								value={companyInfo.discount}
								onChange={handleCompanyInfoChange}
								disabled={
									companyInfo.fileType === "Bulk Assessment"
								}
							/>
						</div>
						<div>
							<label
								className={styles.input__label}
								htmlFor="discountReason"
							>
								discount description
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								name="discountReason"
								id="discountReason"
								placeholder="Discount Description"
								value={companyInfo.discountReason}
								onChange={handleCompanyInfoChange}
								disabled={
									companyInfo.fileType === "Bulk Assessment"
								}
							/>
						</div>
					</div>
					<div className={styles.double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="outstanding"
							>
								outstanding Amount
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="number"
								name="outstanding"
								id="outstanding"
								placeholder="enter Outstanding Amount"
								value={companyInfo.outstanding}
								onChange={handleCompanyInfoChange}
								disabled={
									companyInfo.fileType === "Bulk Assessment"
								}
							/>
						</div>
						<div>
							<label
								className={styles.input__label}
								htmlFor="outstandingReason"
							>
								Outstanding Description
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								name="outstandingReason"
								id="outstandingReason"
								placeholder="select outstanding Description"
								value={companyInfo.outstandingReason}
								onChange={handleCompanyInfoChange}
								disabled={
									companyInfo.fileType === "Bulk Assessment"
								}
							/>
						</div>
					</div>

					<div className={style.buttonContainer}>
						<button
							className={["primary__btn", style.btn].join(" ")}
							onClick={handleCalculateTax}
						>
							Calculate PT
						</button>
					</div>

					<div>
						<label
							style={{
								cursor: "pointer",
								color: "#0066cc",
								textDecoration: "underline"
							}}
						>
							+ Attach Invoice Documents
							<input
								type="file"
								accept=".pdf, image/*"
								style={{ display: "none" }}
								onChange={handleFileUpload}
							/>
						</label>

						<ul>
							{files.map((file, index) => (
								<li
									key={index}
									style={{
										color: "green",
										marginBottom: "8px"
									}}
								>
									<span>{file.fileName}</span>
									<button
										onClick={() => handleRemoveFile(index)}
										style={{
											backgroundColor: "transparent",
											color: "red",
											border: "red",
											padding: "4px 8px",
											marginLeft: "12px",
											gap: "15px",
											cursor: "pointer"
										}}
									>
										X
									</button>
								</li>
							))}
						</ul>
					</div>
				</>
			)}
			{calculationResult && (
				<div>
					<h3>
						Total Purchase Tax Amount: ₦{" "}
						{calculationResult.data.totalCalculatedTax}
					</h3>
					<div className={style.buttonContainer}>
						<button
							className={["primary__btn", style.btn].join(" ")}
							onClick={handleAddCategory}
						>
							generate bill
						</button>
					</div>
					{/* Modal that pops up based on the API response */}
					<Modal
						show={showModal}
						onClose={() => {
							closeModal();
							history.push("/pt/pt-search"); // Redirects to PT records page after closing modal
						}}
					>
						{print && (
							<button
								className={["secondary__btn", style.btn].join(
									" "
								)}
								onClick={printReceipt}
							>
								Print
							</button>
						)}
						<Receipt data={imageData} />
					</Modal>
				</div>
			)}
		</div>
	);
};

export default CreateRecords;
