import React, { useEffect, useState } from "react";
import styles from "../../Identity-management-individual/style.module.css";
import style from "./style.module.css";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";
import constants from "../../constants";
import GoBackButton from "../../components/goBackButton";
import Alert from "../../components/Alert";
const EditCat = () => {
	const { id } = useParams();
	const token = localStorage.getItem("access_token");
	const [loading, setLoading] = useState(false);
	const [getcategories, setgetcategories] = useState([]);
	const [setup, setSetup] = useState({
		Product: "",
		Category: "",
		Transaction_Date: "",
		PT_Rate: ""
	});

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const econSect = async () => {
		const res = await axios.get(
			`${constants.BASE1_URL}/purchasingTax/getActivePurchaseTaxSetUps`,
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);
		if (res) {
			const data = res.data.data?.data;
			setgetcategories(data);
		}
	};
	useEffect(() => {
		econSect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCompanyInfoChange = (e) => {
		const { name, value } = e.target;
		setSetup((prevState) => ({
			...prevState,
			[name]: value
		}));
	};

	const getAllPTCompanyInfo = async () => {
		const res = await axios.get(
			`${constants.BASE1_URL}/purchasingTax/getCategoryInfo/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);
		const data = res.data.data;
		setSetup(data);

		// setTableData(data?.PurchaseTaxCategoryInfo);
		console.log("data that", data);
	};
	useEffect(() => {
		getAllPTCompanyInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateCategory = async () => {
		setLoading(true);
		try {
			const res = await axios.put(
				`${constants.BASE1_URL}/purchasingTax/updatePurchaseTaxCategoryInfo/${id}`,
				{
					Category: setup.Category,
					Product: setup.Product,
					Transaction_Date: setup.Transaction_Date,
					PT_Rate: setup.PT_Rate
				},
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			);
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res.data.message
			});

			if (res) {
				setLoading(false);
				getAllPTCompanyInfo();
			}
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message:
					error?.response.data?.message ||
					error.response.data?.errors?.[0]?.error ||
					error.response.data?.errors?.details[0]?.message
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<div className={styles.columned__form}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<GoBackButton />
			<div className={styles.double__inputs}>
				<div>
					<label className={styles.input__label} htmlFor="Category">
						Category
						<span style={{ color: "red" }}> *</span>
					</label>
					<select
						id="Category"
						name="Category"
						value={setup.Category}
						onChange={handleCompanyInfoChange}
					>
						<option>
							{getcategories
								? "--Select Category--"
								: "...Loading"}
						</option>
						{getcategories &&
							getcategories.map((getcategories) => (
								<option value={getcategories.name}>
									{getcategories.name}
								</option>
							))}
					</select>
				</div>
				<div>
					<label className={styles.input__label} htmlFor="PT_Rate">
						PT Rate
						<span style={{ color: "red" }}> *</span>
					</label>
					<input
						type="text"
						id="PT_Rate"
						name="PT_Rate"
						placeholder=""
						value={setup.PT_Rate}
						onChange={handleCompanyInfoChange}
					/>
				</div>
			</div>
			<div className={styles.double__inputs}>
				<div>
					<label className={styles.input__label} htmlFor="Product">
						Product
						<span style={{ color: "red" }}> *</span>
					</label>
					<input
						type="text"
						name="Product"
						id="Product"
						placeholder=""
						value={setup.Product}
						onChange={handleCompanyInfoChange}
					/>
				</div>
				<div>
					<label
						className={styles.input__label}
						htmlFor="Transaction_Date"
					>
						Transaction Date
						<span style={{ color: "red" }}> *</span>
					</label>
					<input
						type="Date"
						id="Transaction_Date"
						name="Transaction_Date"
						placeholder=""
						value={setup.Transaction_Date}
						onChange={handleCompanyInfoChange}
					/>
				</div>
			</div>
			<div>
				<div className={style.buttonContainer}>
					<button
						className={["primary__btn", style.btn].join(" ")}
						onClick={updateCategory}
					>
						{loading ? "Updating..." : "Update"}
					</button>
				</div>
			</div>
		</div>
	);
};

export default EditCat;
