import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import newsreelStyles from "../style.module.css";
import constants from "../../../constants";
import Modal from "../../../components/Modal/regular";
import GoBackButton from "../../../components/goBackButton";
import EditIcon from "../../../assets/NewsReel/EditPen.svg";
import DeleteIcon from "../../../assets/NewsReel/DeleteIcon.svg";
import Alert from "../../../components/Alert";

const GenerateNews = () => {
	const history = useHistory();

	const [news, setNews] = useState([]);
	const [newNews, setNewNews] = useState("");
	const [editIndex, setEditIndex] = useState(null);
	const [newsModalShowing, setNewsModalShowing] = useState(false);
	const [deleteNewsModalShowing, setDeleteNewsModalShowing] = useState(false);
	const [publishProgress, setPublishProgress] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const textAreaRef = useRef(null);
	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const handleAddNews = () => {
		if (newNews.trim() === "") return;

		if (editIndex !== null) {
			const updatedNews = [...news];
			updatedNews[editIndex] = {
				...updatedNews[editIndex],
				newsHeadline: newNews
			};
			setNews(updatedNews);
			setEditIndex(null);
		} else {
			setNews([
				...news,
				{ id: Date.now().toString(), newsHeadline: newNews }
			]);
		}

		setNewNews("");
		if (textAreaRef.current) {
			textAreaRef.current.focus();
		}
	};

	const handleEditNews = (id) => {
		const item = news.find((newsItem) => newsItem.id === id);
		setNewNews(item.newsHeadline);
		setEditIndex(news.indexOf(item));
		if (textAreaRef.current) {
			textAreaRef.current.focus();
		}
	};

	const handleDeleteNews = (id) => {
		const filteredNews = news.filter((item) => item.id !== id);
		setNews(filteredNews);
	};
	const handleCancel = () => {
		setNews([]);
		setDeleteNewsModalShowing(false);
		if (textAreaRef.current) {
			textAreaRef.current.focus();
		}
	};
	const handleDeleteAllNewsHeadlines = () => {
		setNews([]);
		setDeleteNewsModalShowing(false);
		if (textAreaRef.current) {
			textAreaRef.current.focus();
		}
	};

	const handlePublish = async () => {
		if (news.length === 0) {
			return;
		}
		const newsToSend = news.map(({ id, ...rest }) => rest);
		setPublishProgress(true);
		try {
			const res = await axios.post(
				`${constants.BASE_URL_3}/newsheadline/createnews`,
				{
					newsBody: newsToSend
				}
			);
			setNews([]);

			setPublishProgress(false);
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res?.data?.message
			});
			setTimeout(() => history.push("/news-reel"), 3000);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	const handleCancelUpdate = () => {
		setNewNews("");
		setEditIndex(null);
		if (textAreaRef.current) {
			textAreaRef.current.focus();
		}
	};

	const setModalStatus = () => {
		setNewsModalShowing(true);
	};
	const setModalDeleteStatus = () => {
		setDeleteNewsModalShowing(true);
	};

	return (
		<section>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<GoBackButton />
			{news.length > 0 && (
				<>
					<table className={newsreelStyles.table}>
						<thead>
							<tr>
								<th>S/N</th>
								<th className={newsreelStyles.description}>
									News Headlines
								</th>
								<th>
									<img
										className={
											newsreelStyles.action__Img_flexend
										}
										onClick={setModalDeleteStatus}
										src={DeleteIcon}
										alt="DeleteIcon Holder"
									/>
								</th>
							</tr>
						</thead>
						<tbody>
							{news.map((item) => (
								<tr key={item.id}>
									<td>{news.indexOf(item) + 1}.</td>
									<td className={newsreelStyles.description}>
										{item.newsHeadline}
									</td>
									<td>
										<div
											className={
												newsreelStyles.button__flexend
											}
										>
											<button
												className={
													newsreelStyles.editBtn
												}
												onClick={() =>
													handleEditNews(item.id)
												}
											>
												<img
													className={
														newsreelStyles.action__Img_flexend
													}
													src={EditIcon}
													alt="EditIcon Holder"
												/>
											</button>
											<button
												className={
													newsreelStyles.deleteBtn
												}
												onClick={() =>
													handleDeleteNews(item.id)
												}
											>
												<img
													className={
														newsreelStyles.actionImg
													}
													src={DeleteIcon}
													alt="DeleteIcon Holder"
												/>
											</button>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>

					<div className={newsreelStyles.button__container}>
						<button
							className={[
								"secondary__btn",
								newsreelStyles.cancel__btn
							].join(" ")}
							onClick={handleCancel}
						>
							Cancel
						</button>
						<button
							className={[
								"primary__btn",
								newsreelStyles.create__btn
							].join(" ")}
							onClick={setModalStatus}
							disabled={news.length < 1}
						>
							Publish Set of News
						</button>
					</div>
				</>
			)}

			<form
				className={newsreelStyles.form_new}
				onSubmit={(e) => {
					e.preventDefault();
					handleAddNews();
				}}
			>
				<label htmlFor="newsDescription">
					News Headline <span style={{ color: "red" }}>*</span>
				</label>

				<textarea
					id="newsDescription"
					className={newsreelStyles.input}
					value={newNews}
					onChange={(e) => setNewNews(e.target.value)}
					placeholder="Write news headline"
					ref={textAreaRef}
				/>

				<div className={newsreelStyles.button__flexend}>
					<button
						className={[
							"tertiary__btn",
							newsreelStyles.add__btn
						].join(" ")}
						onClick={handleCancelUpdate}
					>
						Cancel
					</button>

					<button
						type="submit"
						className={[
							"secondary__btn",
							newsreelStyles.add__btn
						].join(" ")}
						disabled={newNews.trim() === ""}
					>
						{editIndex ? "Update News" : "Add News"}
					</button>
				</div>
			</form>
			{newsModalShowing && (
				<Modal toggle={setNewsModalShowing} showing={newsModalShowing}>
					<div className={newsreelStyles.modal_container}>
						<p className={newsreelStyles.modal_text}>
							Publishing this set of news will overwrite all
							previously published news on the website. If you
							wish to proceed, click "Continue."
						</p>

						<div className={newsreelStyles.modal__button}>
							<button
								onClick={() => setNewsModalShowing(false)}
								className={[
									"secondary__btn",
									newsreelStyles.cancelsmall__btn
								].join(" ")}
							>
								Cancel
							</button>
							<button
								className={[
									"primary__btn",
									newsreelStyles.ctn__btn
								].join(" ")}
								onClick={handlePublish}
								style={{ padding: "0 16px" }}
								disabled={publishProgress}
							>
								{publishProgress ? "Publishing..." : "Continue"}
							</button>
						</div>
					</div>
				</Modal>
			)}

			{/* Delete Confirmation Modal */}
			{deleteNewsModalShowing && (
				<Modal
					toggle={setDeleteNewsModalShowing}
					showing={deleteNewsModalShowing}
				>
					<div className={newsreelStyles.delete__modal_Cont}>
						<p className={newsreelStyles.delete__content}>
							Are you sure you want to delete all news headlines?
						</p>
						<div className={newsreelStyles.delmodalbtn}>
							<button
								className={[newsreelStyles.delctn__btn].join(
									" "
								)}
								onClick={() => setDeleteNewsModalShowing(false)}
							>
								No
							</button>
							<button
								className={[
									newsreelStyles.delctn__btn_yes
								].join(" ")}
								onClick={handleDeleteAllNewsHeadlines}
								style={{ padding: "0 16px" }}
							>
								Yes
							</button>
						</div>
					</div>
				</Modal>
			)}
		</section>
	);
};

export default GenerateNews;
