import { useState, useEffect } from "react";
import axios from "axios";
import { formatAmount, formatDate } from "../../utils";
import Alert from "../../components/Alert";
import Table from "../../components/Table";
import styles from "./style.module.css";
import detailsStyles from "../Coupon/style.module.css";

const PaymentHistory = () => {
	const [billNumber, setBillNumber] = useState("");
	const [fetching, setFetching] = useState(false);
	const [billDetails, setBillDetails] = useState(null);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const headers = [
		["S/N", "SN"],
		["Bill Number", "billno"],
		["Amount Paid", "Amount", (amount) => `₦ ${formatAmount(amount)}`],
		["Payment Date", "PaymentDate", formatDate]
	];

	async function getBillDetails() {
		setFetching(true);
		try {
			const response = await axios
				.get(`/account/billv2/${billNumber}`, {
					headers: { secureddata: "VGhpc2lzTm9ybGljcw==" }
				})
				.then((res) => res.data.data);

			setBillDetails({
				...response,
				paymentDetails: response.paymentDetails.map((item, index) => ({
					...item,
					SN: index + 1
				}))
			});
			setBillNumber("");
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setFetching(false);
		}
	}

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<section>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<form
				onSubmit={(e) => {
					e.preventDefault();
					getBillDetails();
				}}
				className={styles.form}
			>
				<label htmlFor="billNo">
					Bill Number <span className="required">*</span>
				</label>
				<input
					type="text"
					name="billNo"
					id="billNo"
					value={billNumber}
					onChange={(e) => setBillNumber(e.target.value)}
					placeholder="Enter Bill Number"
				/>
				<button
					type="submit"
					className="primary__btn"
					onClick={getBillDetails}
					disabled={fetching || !billNumber}
				>
					{fetching ? "Fetching..." : "Get Payment History"}
				</button>
			</form>
			{billDetails && !fetching && (
				<>
					<div className={detailsStyles.coupon__details}>
						<div>
							<span className={detailsStyles.key}>
								Bill Number:{" "}
							</span>
							<span className={detailsStyles.value}>
								{billDetails?.billDetails.BillNumber}
							</span>
						</div>
						<div>
							<span className={detailsStyles.key}>
								ESBN/PID:{" "}
							</span>
							<span className={detailsStyles.value}>
								{billDetails?.billDetails?.ESBN_PID_on_Bill}
							</span>
						</div>
						<div>
							<span className={detailsStyles.key}>
								Name on Bill:{" "}
							</span>
							<span className={detailsStyles.value}>
								{billDetails?.billDetails?.Name_on_Bill}
							</span>
						</div>
						<div>
							<span className={detailsStyles.key}>
								Bill From:{" "}
							</span>
							<span className={detailsStyles.value}>
								{
									billDetails?.billDetails
										?.Bill_from_which_Module
								}
							</span>
						</div>
						<div>
							<span className={detailsStyles.key}>
								Total Amount:{" "}
							</span>
							<span className={`${detailsStyles.value}`}>
								₦{" "}
								{formatAmount(
									billDetails?.billDetails
										?.Total_Grand_Bill_Amount
								)}
							</span>
						</div>
					</div>
					<h2 className={detailsStyles.table__header}>
						Payment History
					</h2>
				</>
			)}
			<Table
				data={billDetails?.paymentDetails || []}
				headers={headers}
				loading={fetching}
				noDataMessage={"No previous payment"}
			/>
			{billDetails?.totPayment && !fetching && (
				<p className={styles.totalPaid}>
					Total Paid:{" "}
					<span>₦ {formatAmount(billDetails?.totPayment)}</span>
				</p>
			)}
		</section>
	);
};

export default PaymentHistory;
