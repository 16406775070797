import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import constants from "../../../constants";
import AssessmentMode from "../components/assessmentMode";
import Table from "../../../components/Table";
import { formatAmount } from "../../../utils";
import GoBackButton from "../../../components/goBackButton";
import ESBNComponent from "../components/ESBNcomp";
import EditPropertyDetails from "../components/editPropertyDetails";
import EditOtherDetailsComponent from "../components/editOtherDetails";
import Alert from "../../../components/Alert";
import docsImg from "../../../assets/docxImg.svg";
import pdfImg from "../../../assets/pdfImg.svg";
import removeImg from "../../../assets/x_red.svg";
import styles1 from "../../MDAs/style.module.css";
import style from "../../WHT/style.module.css";
import anssidStyles from "../../StateTIN/style.module.css";
import styles3 from "../../../Identity-management-individual/style.module.css";
import EditSuccessModal from "../components/editSuccessModal";

const headers = [
	["S/N", "serialNumber"],
	["Type Of CGT ", "TypeOfCGT"],
	["Type Of Property ", "TypeOfProperty"],
	[
		" Aquisition Amount ",
		"AquisitionAmount",
		(value) => `₦${formatAmount(value)}`
	],
	[
		"Current Market Value",
		"CurrentMarketValue",
		(value) => `₦${formatAmount(value)}`
	],
	["Sales Amount", "SalesAmount", (value) => `₦${formatAmount(value)}`],
	["CGTRate", "CGTRate", (value) => `${value}%`],
	["Tax Due", "Tax", (value) => `₦${formatAmount(value)}`]
];

const CGTedit = () => {
	const { state: locationState } = useLocation();
	const [CGTPropertyDetails, setCGTPropertyDetails] = useState([]);
	const [uploadedDocuments, setUploadedDocuments] = useState([]);
	const [setupOptions, setSetupOptions] = useState([]);
	const [isCalculated, setIsCalculated] = useState(false);
	const [isCalculateLoading, setIsCalculateLoading] = useState(false);
	const [isGenerateLoading, setIsGenerateLoading] = useState(false);
	const [isBillGenerated, setIsBillGenerated] = useState(false);
	const [loading, setLoading] = useState(false);
	const [sectors, setSectors] = useState([]);
	const [isEditing, setIsEditing] = useState(false);
	const [htmlContent, setHtmlContent] = useState(null);
	const [isSuccessModal, setIsSuccessModal] = useState(false);
	const [isBillModalShowing, setIsBillModalShowing] = useState(false);
	const [calculatedAmount, setCalculatedAmount] = useState(0.0);
	const accessToken = localStorage.getItem("access_token");
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const [isDescriptionRequired, setIsDescriptionRequired] = useState({
		discountReason: false,
		outstandingReason: false
	});
	const userInfo = JSON.parse(localStorage.getItem("user_info"));
	const initialState =
		userInfo.account_type === "corporate"
			? {
					filingType: "Unit Filling",
					filingMode: locationState.Filing_Mode,
					billMonth: locationState.Bill_Month,
					billYear: locationState.Bill_Year,
					startDate: locationState.Start_Date,
					endDate: locationState.End_Date,
					esbn: locationState.ESBN,
					name: locationState.Name,
					email: locationState.Email,
					phone: locationState.Phone,
					address: locationState.Address,
					sector: locationState.Sector,
					discount: locationState.Discount,
					outstanding: locationState.Outstanding,
					discountReason: locationState.DiscountReason,
					outstandingReason: locationState.outstandingReason,
					remark: locationState.Remark,
					uploadedDocuments: []
				}
			: {
					filingType: "Unit Filling",
					filingMode: locationState.filingMode,
					billMonth: locationState.billMonth,
					billYear: locationState.billYear,
					startDate: locationState.startDate,
					endDate: locationState.endDate,
					esbn: locationState.esbn,
					name: locationState.name,
					email: locationState.email,
					phone: locationState.phone,
					address: locationState.address,
					sector: locationState.sector,
					discount: locationState.discount,
					outstanding: locationState.outstanding,
					discountReason: locationState.discountReason,
					outstandingReason: locationState.outstandingReason,
					remark: locationState.remark,
					uploadedDocuments: []
				};

	const [companyDetails, setCompanyDetails] = useState(initialState);
	const [propertyDetails, setPropertyDetails] = useState({
		TypeOfCGT: "",
		CGTRate: "",
		AquisitionAmount: "",
		CurrentMarketValue: "",
		SalesAmount: "",
		Profit: "",
		TypeOfProperty: "",
		PropertyLocation: "",
		PropertyNature: "",
		revenueCode: "",
		TaxDue: CGTPropertyDetails.Tax
	});

	const [defaultCGTSetup, setDefaultCGTSetup] = useState({
		CGTRate: "",
		TypeOfCGT: ""
	});

	const fetchCGTdata = async () => {
		setLoading(true);

		try {
			let response = await axios.get(
				`${constants.BASE1_URL}/getCGTCompanyInfoRecord/${locationState.id}`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			);
			const { data } = response.data;
			const propertiesWithSerialNumber = data.CGTPropertyDetails.map(
				(properties, index) => ({
					...properties,
					serialNumber: index + 1
				})
			);
			setUploadedDocuments(data.CGTCompanyFile);
			setCGTPropertyDetails(propertiesWithSerialNumber);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.message ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchCGTdata();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	useEffect(() => {
		const economicSector = async () => {
			try {
				const sector = await axios
					.get(`/settings/economicsector`)
					.then((res) => res.data.data);
				setSectors(sector);
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.response?.data?.msg
					});
				}
			}
		};
		economicSector();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const getSetUpList = async () => {
			try {
				const setUpList = await axios.get(
					`${constants.BASE1_URL}/getActiveCGTSetUps`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				);
				const setUp = setUpList.data.data.data;
				setSetupOptions(setUp); // Save the options for the dropdown or other UI

				if (setUp.length > 0) {
					const defaultType = setUp[0]; // Assuming the first item is the default
					setDefaultCGTSetup({
						TypeOfCGT: defaultType?.name || "", // Use appropriate key for "name"
						CGTRate: defaultType?.rate || "",
						revenueCode: defaultType?.revenueCode || ""
					});
				}
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.response?.data?.msg
					});
				}
			}
		};
		getSetUpList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const editTable = () => {
		setCGTPropertyDetails((prevDetails) =>
			prevDetails.map((item) =>
				item.id === propertyDetails.id
					? {
							...propertyDetails,
							Tax: calculateTaxDue(
								propertyDetails.CGTRate,
								propertyDetails.SalesAmount
							)
						}
					: item
			)
		);
		setIsEditing(false);
		updatePropertyDetail();
	};

	const calculateTaxDue = (rate, salesAmount) => {
		let baseCharge = 150000;
		let taxDue = (parseFloat(rate) / 100) * parseFloat(salesAmount);
		if (taxDue > baseCharge) {
			return taxDue;
		} else {
			return baseCharge;
		}
	};

	const currentBill = CGTPropertyDetails.reduce(
		(acc, curr) => acc + Number(curr.Tax),
		0
	);

	const updatePropertyDetail = async () => {
		try {
			await axios.put(
				`${constants.BASE1_URL}/cgt/updatePropertyDetail/${propertyDetails.id}`,
				propertyDetails
			);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		}
	};

	const handleDocumentDelete = async (docId) => {
		const fileToDelete = uploadedDocuments.find(
			(file) => file.id === docId
		);

		if (!fileToDelete) return; // If the file doesn't exist, exit

		if (fileToDelete) {
			try {
				await axios.delete(
					`${constants.BASE1_URL}/deleteCGTCompanyFile/${docId}`
				);
				// Remove the file from state
				setUploadedDocuments((prevFiles) =>
					prevFiles.filter((file) => file.id !== docId)
				);
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.response?.data?.msg
					});
				}
			}
		} else {
			// If it's a newly added file (not yet uploaded), just remove it from state
			setUploadedDocuments((prevFiles) =>
				prevFiles.filter((file) => file.id !== docId)
			);
		}

		// Also update companyDetails state
		setCompanyDetails((prevDetails) => ({
			...prevDetails,
			uploadedDocuments: prevDetails.uploadedDocuments.filter(
				(file) => file.id !== docId
			)
		}));
	};

	const handleFileChange = (e) => {
		const selectedFiles = Array.from(e.target.files);

		// Check file sizes
		const invalidFiles = selectedFiles.filter(
			(file) => file.size > 500 * 1024
		); // 500KB in bytes

		if (invalidFiles.length > 0) {
			setAlert({
				...alert,
				showing: true,
				type: "error",
				message:
					"File exceed the 500KB size limit and will not be uploaded"
			});
			return;
		}

		const newFiles = selectedFiles.map((file) => ({
			id: Math.random().toString(36).substr(2, 9), // Temporary ID
			FileName: file.name,
			fileObject: file // Store the actual file for later upload
		}));

		setUploadedDocuments((prevFiles) => [...prevFiles, ...newFiles]);
		setCompanyDetails((prevDetails) => ({
			...prevDetails,
			uploadedDocuments: [...prevDetails.uploadedDocuments, ...newFiles]
		}));
	};

	const calculateCGT = async () => {
		setIsCalculateLoading(true);
		try {
			const payload = CGTPropertyDetails.map((properties) => ({
				...properties,
				AquisitionAmount: Number(properties.AquisitionAmount),
				CurrentMarketValue: Number(properties.CurrentMarketValue),
				SalesAmount: Number(properties.SalesAmount),
				Profit: Number(properties.Profit),
				CGTRate: Number(properties.CGTRate),
				TaxDue: Number(properties.TaxDue)
			}));
			const taxData = payload.map(
				({
					TypeOfCGT,
					TypeOfProperty,
					PropertyLocation,
					PropertyNature,
					revenueCode,
					...rest
				}) => rest
			);

			const calculator = await axios.post(
				`${constants.BASE1_URL}/calculateCGTax`,
				{
					otherDetails: {
						outstanding: Number(companyDetails.outstanding),
						discount: Number(companyDetails.discount)
					},

					taxData
				},
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			);
			const { totalFinalTax } = calculator.data.data.summary;
			setCalculatedAmount(totalFinalTax);
			setIsCalculated(true);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setIsCalculateLoading(false);
		}
	};

	const convertFileToBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
			reader.readAsDataURL(file); // Converts file to Base64
		});
	};

	const updateCGT = async () => {
		setIsGenerateLoading(true);
		try {
			const base64Files = await Promise.all(
				companyDetails.uploadedDocuments.map((file) =>
					convertFileToBase64(file.fileObject).then((base64) => ({
						fileName: file.FileName,
						fileType: file.fileObject.type,
						File: base64.split(",")[1] // Stripping the metadata part
					}))
				)
			);

			const response = await axios.put(
				`${constants.BASE1_URL}/updateCGTCompanyInfo/${locationState.id}`,
				{
					companyInfo: {
						filingType: "Unit Filling",
						filingMode: companyDetails.filingMode,
						billYear: companyDetails.billYear,
						billMonth: companyDetails.billMonth,
						name: companyDetails.name,
						esbn: companyDetails.esbn,
						email: companyDetails.email,
						phone: companyDetails.phone,
						address: companyDetails.address,
						sector: companyDetails.sector,
						startDate: companyDetails.startDate,
						endDate: companyDetails.endDate,
						discountReason: companyDetails.discountReason,
						outstandingReason: companyDetails.outstandingReason,
						remark: companyDetails.remark,
						discount: parseFloat(companyDetails.discount) || 0,
						outstanding: parseFloat(companyDetails.outstanding) || 0
					},
					files: base64Files
				},
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			);
			if (response?.data) {
				const html = response.data;
				setHtmlContent(html);
			}
			setIsBillGenerated(true);
			setIsSuccessModal(true);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setIsGenerateLoading(false);
		}
	};

	return (
		<main>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<GoBackButton />
			<AssessmentMode
				assessmentModeOptions={companyDetails}
				setAssessmentMode={setCompanyDetails}
			/>
			<ESBNComponent
				fillerDetails={companyDetails}
				setFillerDetails={setCompanyDetails}
				sectors={sectors}
			/>
			<div className={styles1.table__div}>
				<Table
					headers={headers}
					data={CGTPropertyDetails}
					loading={loading}
					full
					pageSize={20}
					onEdit={(selectedProperty) => {
						setPropertyDetails((prevv) => ({
							...prevv,
							serialNumber: selectedProperty.serialNumber,
							TypeOfCGT: selectedProperty.TypeOfCGT,
							CGTRate: selectedProperty.CGTRate,
							id: selectedProperty.id,
							AquisitionAmount: selectedProperty.AquisitionAmount,
							CurrentMarketValue:
								selectedProperty.CurrentMarketValue,
							SalesAmount: selectedProperty.SalesAmount,
							Profit: selectedProperty.Profit,
							TypeOfProperty: selectedProperty.TypeOfProperty,
							PropertyLocation: selectedProperty.PropertyLocation,
							PropertyNature: selectedProperty.PropertyNature,
							Tax: calculateTaxDue(
								selectedProperty.CGTRate,
								selectedProperty.SalesAmount
							)
						}));
						setIsEditing(true);
						setTimeout(() => {
							document
								.getElementById("propertyDetails")
								.scrollIntoView({
									behavior: "smooth",
									block: "start"
								});
						});
					}}
				/>
			</div>
			<div className={style.AmountWithheld}>
				<h4>Total Capital Gain Tax (CGT) Due</h4>
				<h4>{`₦${formatAmount(currentBill)}`}</h4>
			</div>
			<hr className={style.hr} />
			{isEditing && propertyDetails && (
				<EditPropertyDetails
					propertyDetails={propertyDetails}
					setPropertyDetails={setPropertyDetails}
					setupOptions={setupOptions}
					editTable={editTable}
					isEditing={isEditing}
					defaultCGTSetup={defaultCGTSetup}
					setDefaultCGTSetup={setDefaultCGTSetup}
				/>
			)}
			<EditOtherDetailsComponent
				companyDetails={companyDetails}
				propertyDetails={propertyDetails}
				setCompanyDetails={setCompanyDetails}
				setPropertyDetails={setPropertyDetails}
				isDescriptionRequired={isDescriptionRequired}
				setIsDescriptionRequired={setIsDescriptionRequired}
			/>
			<section>
				<input
					type="file"
					id="fileInput"
					multiple
					style={{ display: "none" }}
					onChange={handleFileChange}
				/>
				<label htmlFor="fileInput" className={style.invisibleFileLabel}>
					+
					<span
						style={{
							marginLeft: "5px",
							textDecoration: "underline"
						}}
					>
						Attach document e.g. Deed of Assignment, Power of
						Attorney, or Receipts of Purchase/Sales (Max file size:
						500KB)
					</span>
				</label>
				<ul>
					{uploadedDocuments.map((file) => {
						const fileExtension = file.FileName.split(".")
							.pop()
							.toLowerCase();

						const logo = fileExtension === "pdf" ? pdfImg : docsImg;
						return (
							<li key={file.id} className={style.fileLists}>
								<div>
									<img src={logo} alt="Document Logo" />
									<span> {file.FileName}</span>
								</div>
								<button
									onClick={() =>
										handleDocumentDelete(file.id)
									}
									style={{
										cursor: "pointer",
										border: "none",
										background: "none"
									}}
								>
									<img src={removeImg} alt="Remove Logo" />
								</button>
							</li>
						);
					})}
				</ul>
			</section>
			<hr className={style.hr} />
			<div className={style.colationSection}>
				<h4>
					Discount Amount:&nbsp;
					{`₦${formatAmount(companyDetails.discount)}`}
				</h4>
				<h4>
					Outstanding Amount:&nbsp;
					{`₦${formatAmount(companyDetails.outstanding)}`}
				</h4>
			</div>{" "}
			<hr className={style.hr} />
			<form className={styles3.columned__form}>
				{isCalculated && (
					<p style={{ marginTop: "48px", fontSize: "20px" }}>
						Total CGT Amount:
						{` ₦${formatAmount(calculatedAmount)}`}
					</p>
				)}

				{!isCalculated ? (
					<div>
						<button
							type="button"
							className={`primary__btn ${anssidStyles.create__btn}`}
							style={{
								float: "right",
								marginTop: "32px",
								marginBottom: "222px"
							}}
							onClick={calculateCGT}
							disabled={
								isCalculateLoading ||
								(companyDetails.discount &&
									!companyDetails.discountReason) ||
								(companyDetails.outstanding &&
									!companyDetails.outstandingReason)
							}
						>
							{isCalculateLoading
								? "Re-Calculating..."
								: "Re-Calculate Tax"}
						</button>
					</div>
				) : (
					<div>
						<button
							type="button"
							className={`primary__btn ${anssidStyles.create__btn}`}
							style={{
								float: "right",
								marginTop: "32px",
								marginBottom: "222px"
							}}
							onClick={updateCGT}
							disabled={isBillGenerated || isGenerateLoading}
						>
							{isBillGenerated
								? "Updated"
								: isGenerateLoading
									? "Updating Bill..."
									: "Update Bill"}
						</button>
					</div>
				)}
			</form>
			<EditSuccessModal
				isSuccessModal={isSuccessModal}
				setIsSuccessModal={setIsSuccessModal}
				isBillModalShowing={isBillModalShowing}
				setIsBillModalShowing={setIsBillModalShowing}
				htmlContent={htmlContent}
			/>
		</main>
	);
};

export default CGTedit;
