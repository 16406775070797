import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import Avatar from "../../assets/Sidenav/avatar.svg";
import Logo from "../../assets/Header/enuguIRS50.svg";
import styles from "./style.module.css";

let userInfo = JSON.parse(localStorage.getItem("user_info"));

const UserSidenav = ({ navRef, menuRef, toggleSidebar }) => {
	const history = useHistory();

	return (
		<>
			<nav className={styles.nav} ref={navRef}>
				<div className={styles.logo}>
					<img src={Logo} alt="logo" />
				</div>
				<div className={styles.mobile__top}>
					<div>
						<img src={Avatar} alt="avatar" />
						<p>
							{userInfo.fullname}
							<br /> <span>{userInfo.email}</span>
						</p>
					</div>
					<button
						aria-labelledby="close sidebar"
						onClick={toggleSidebar}
					>
						X
					</button>
				</div>
				<ul className={styles.nav__list}>
					<li>
						<div>
							<NavLink
								exact
								to="/"
								activeClassName={styles.active}
								className={styles.nav__button}
								onClick={toggleSidebar}
							>
								<span>Home</span>
							</NavLink>
						</div>
						<hr className={styles.divider} />
					</li>
					<li>
						<div>
							<NavLink
								exact
								to="/capital-gain-tax/self-service/records"
								activeClassName={styles.active}
								className={styles.nav__button}
							>
								<span>Capital Gain Tax</span>
							</NavLink>
						</div>
						<hr className={styles.divider} />
					</li>
					{userInfo?.account_type === "corporate" && (
						<li>
							<div>
								<NavLink
									exact
									to="/hni/tax"
									activeClassName={styles.active}
									className={styles.nav__button}
								>
									<span>HNI</span>
								</NavLink>
							</div>
							<hr className={styles.divider} />
						</li>
					)}
					{userInfo?.account_type === "corporate" && (
						// Fragment is here cos there will be more link
						<>
							<li>
								<div>
									<NavLink
										exact
										to="/PAYE-records"
										activeClassName={styles.active}
										className={styles.nav__button}
										onClick={toggleSidebar}
									>
										<span>PAYE Assessment </span>
									</NavLink>
								</div>
								<hr className={styles.divider} />
							</li>
							<li>
								<div>
									<NavLink
										exact
										to="/withholding/records"
										activeClassName={styles.active}
										className={styles.nav__button}
										onClick={toggleSidebar}
									>
										<span>WHT Assessment </span>
									</NavLink>
								</div>
								<hr className={styles.divider} />
							</li>
							<li>
								<div>
									<NavLink
										exact
										to="/PAYE-filing-corporate"
										activeClassName={styles.active}
										className={styles.nav__button}
										onClick={toggleSidebar}
									>
										<span>PAYE Filing</span>
									</NavLink>
								</div>
								<hr className={styles.divider} />
							</li>
							{/* <li>
								<div>
									<NavLink
										exact
										to="/WHT-filing-corporate"
										activeClassName={styles.active}
										className={styles.nav__button}
									>
										<span>WHT Filing</span>
									</NavLink>
								</div>
								<hr className={styles.divider} />
							</li> */}
							{/* <li>
								<div>
									<NavLink
										exact
										to="/hni/tax"
										activeClassName={styles.active}
										className={styles.nav__button}
										onClick={toggleSidebar}
									>
										<span>HNI</span>
									</NavLink>
								</div>
								<hr className={styles.divider} />
							</li> */}
							{/* Mr Femi asked to remove these */}
						</>
					)}
					<li>
						<div>
							<NavLink
								exact
								to="/details"
								isActive={(_, loc) =>
									/details/i.test(loc.pathname)
								}
								activeClassName={styles.active}
								className={styles.nav__button}
								onClick={toggleSidebar}
							>
								<span>User Details</span>
							</NavLink>
						</div>
						<hr className={styles.divider} />
					</li>
					<li>
						<div>
							<NavLink
								exact
								to="/support"
								activeClassName={styles.active}
								className={styles.nav__button}
								onClick={toggleSidebar}
							>
								<span>Contact Support</span>
							</NavLink>
						</div>
						<hr className={styles.divider} />
					</li>
					<li>
						<div>
							<NavLink
								exact
								to="/password"
								activeClassName={styles.active}
								className={styles.nav__button}
								onClick={toggleSidebar}
							>
								<span>Change Password</span>
							</NavLink>
						</div>
						<hr className={styles.divider} />
					</li>
					<li>
						<div>
							<button
								className={styles.nav__button}
								onClick={() => {
									localStorage.removeItem("user_info");
									localStorage.removeItem("access_token");
									history.push("/login");
								}}
							>
								<span>Logout</span>
							</button>
						</div>
					</li>
				</ul>
			</nav>
			<div className={styles.background} onClick={toggleSidebar} />
		</>
	);
};

export default UserSidenav;
