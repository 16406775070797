export const VIEWER = {
	ADMIN: "ADMIN",
	CORPORATE: "CORPORATE"
};

export const employeeUpdateSchema = {
	Discount: "Discount_Amount",
	DiscountReason: "Discount_Info",
	Outstanding: "Outstanding_Amount",
	outstandingReason: "Outstanding_Info",
	Filling_Type: "Filling_Type",
	Employer_ESBN: "Employer_ESBN",
	Employer_PhoneNumber: "Employer_PhoneNumber",
	Employer_Email: "Employer_Email",
	Employer_Address: "Employer_Address",
	Employer_Name: "Employer_Name",
	Sector: "Sector",
	AssessmentMode: "AssessmentMode",
	AssessmentYear: "AssessmentYear",
	AssessmentMonth: "AssessmentMonth",
	AssessmentStartPeriod: "AssessmentStartPeriod",
	AssessmentEndPeriod: "AssessmentEndPeriod",
	contractors: {
		Contractor_Name: "Contractor_Name",
		Contractor_ESBN: "Contractor_ESBN",
		Contractor_Address: "Contractor_Address",
		Contractor_PhoneNumber: "Contractor_PhoneNumber",
		Contractor_Email: "Contractor_Email",
		Sum: "Sum",
		Rate: "Rate",
		TariffDescription: "TariffDescription",
		RevenueCode: "RevenueCode",
		PaymentDate: "PaymentDate",
		StartDate: "StartDate",
		EndDate: "EndDate",
		Contract_Type: "Contract_Type"
	}
};
