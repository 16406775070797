import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import constants from "../../constants";
import GoBackButton from "../../components/goBackButton";
import Table from "../../components/Table";
import Alert from "../../components/Alert";
import { useHistory } from "react-router-dom";
import ApproveRejectWidget from "../../components/ApproveRejectWidget";
import Receipt from "../Receipt/receipt";
import Modal from "./modal/modal";
import style from "./style.module.css";

const EnnsaaAuthoriseView = () => {
	const history = useHistory();

	const header = [
		["S/N", "SN"],
		["Category", "Category"],
		["Sub Category", "SubCategory"],
		["Faces", "Faces"],
		["Address", "Address"]
	];

	const { id } = useParams();
	const [rejectReason, setRejectReason] = useState("");
	const [rejecting, setRejecting] = useState(false);
	const [approving, setApproving] = useState(false);
	const [billView, setBillView] = useState();
	const [showModal, setShowModal] = useState(false);
	const [imageData, setImageData] = useState("");
	const [loading, setLoading] = useState(false);
	const [print, setPrint] = useState("");

	// const [showModal, setShowModal] = useState(false);
	console.log(id);
	const [data, setData] = useState([]);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	useEffect(() => {
		axios
			.get(`${constants.BASE_URL_2}/enssaa/${id}`)
			.then((res) => {
				const response = res.data?.data || {};
				setData(response);
				setBillView(response?.srn);

				return response;
			})
			.catch((error) => {
				console.error("Error fetching ENSAA data:", error);
				return null;
			});
	}, [id]);

	const SRN = billView;

	const getBillView = async () => {
		setLoading(true);
		try {
			const res = await axios.get(
				`${constants.BASE_URL_2}/enssaa/receipt/${SRN}`
			);
			setImageData(res.data);
			setPrint(res.data);
			openModal();
		} catch (error) {
			console.error("Error fetching ENSAA data:", error);
		} finally {
			setLoading(false);
		}
	};

	const printReceipt = (e) => {
		e.preventDefault();

		const w = window.open();
		w.document.write(print);
		w.document.close();
		w.onload = () => {
			w.focus();
			w.print();
		};

		// calculationResult("");

		w.onafterprint = () => {
			w.close();
		};
	};

	useEffect(() => {
		getBillView();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const openModal = () => setShowModal(true);
	const closeModal = () => setShowModal(false);

	const approve = async () => {
		try {
			setRejecting(true);

			const approve = "3";

			if (!approve) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: "Invalid type provided."
				});
				return;
			}

			const req = await axios
				.put(`${constants.BASE_URL}/processforenssaa`, {
					globId: "3",
					approve,
					reason4Reject: "",
					recId: data?.application?.id,
					name_on_bill: data?.application?.Name,
					esbn_pid_on_bill: data?.application?.ESBN,
					total_current_bill_amount: totalAmount,
					total_outstanding_bill_amount: 0,
					total_discount_bill_amount: 0,
					total_grand_bill_amount: data?.application?.GrandTotal,
					bill_module_id: "1",
					bill_mda: "38",
					bill_lga: "Headquarter",
					bill_zone: "Headquarter",
					bill_taxoffice: "Headquarter",
					centbilldet: data?.categories.map((item) => ({
						mda: "38",
						esbn_pid: "",
						esbn_pid_name: "",
						outstandingamount: 0,
						outstandingreason: "",
						address: data?.application?.Address,
						billdescr: item.SubCategory,
						amount: item.Amount,
						revenuecode: item.Revenue
					}))
				})
				.then((res) => res.data || {});

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: req.msg
			});

			setTimeout(() => history.goBack(), 3000);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setApproving(false);
		}
	};

	const reject = async (e) => {
		e.preventDefault();

		try {
			setRejecting(true);

			const approve = "2";

			if (!approve) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: "Invalid type provided."
				});
				return;
			}

			const req = await axios
				.put(`${constants.BASE_URL}/processforenssaa`, {
					globId: "3",
					approve,
					reason4Reject: rejectReason,
					recId: data?.application?.id
				})
				.then((res) => res.data || {});

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: req.msg
			});

			setTimeout(() => history.goBack(), 3000);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.message ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setRejecting(false);
		}
	};

	const revoke = async (e) => {
		e.preventDefault();

		try {
			setRejecting(true);

			const approve = "4";

			if (!approve) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: "Invalid type provided."
				});
				return;
			}

			const req = await axios
				.put(`${constants.BASE_URL}/processforenssaa`, {
					globId: "3",
					approve,
					reason4Reject: rejectReason,
					recId: data?.application?.id
				})
				.then((res) => res.data || {});

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: req.msg
			});

			setTimeout(() => history.goBack(), 3000);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.message ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setRejecting(false);
		}
	};
	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);
	const categories = data?.categories;

	// Sum up the `Amount` values
	const totalAmount = categories?.reduce(
		(sum, category) => sum + category?.Amount,
		0
	);
	console.log(`Total Amount: ${totalAmount}`);

	return (
		<>
			{loading ? (
				<div>loading</div>
			) : (
				<div>
					{alert.showing && (
						<Alert text={alert.message} type={alert.type} />
					)}
					<GoBackButton />
					{/* Header Section */}
					<div className={styles.header}>
						<h1 className={styles.companyName}>Company Name</h1>
						<button
							className={styles.viewBillButton}
							onClick={getBillView}
						>
							{loading ? "loading..." : "View Bill"}
						</button>
					</div>

					{/* Bill Details Section */}
					<div className={styles.detailsSection}>
						<div className={styles.detailsGroup}>
							<p>
								<strong>Total Amount Billed:</strong>{" "}
								{data?.application?.TotalAmtBilled}
							</p>
							<p>
								<strong>Signage Ref No.:</strong>{" "}
								{data?.application?.SRN}
							</p>
							<p>
								<strong>Bill Number:</strong>{" "}
								{data?.application?.BillNumber}
							</p>
							<p>
								<strong>Bill Type:</strong>{" "}
								{data?.application?.Filling_Type}
							</p>
							<p>
								<strong>Process Status:</strong>{" "}
								<span className={styles.pending}>
									{data?.application?.ProcessStatus}
								</span>
							</p>
							<p>
								<strong>Payment Status:</strong>{" "}
								{data?.application?.PaymentStatus}
							</p>
							<p>
								<strong>Generated By:</strong>{" "}
								{data?.application?.Inputter}
							</p>
							<p>
								<strong>Fiscal Year:</strong>{" "}
								{data?.application?.Year}
							</p>
						</div>

						<div className={styles.detailsGroup}>
							<p>
								<strong>Period Start Date:</strong>{" "}
								{data?.application?.StartDate}
							</p>
							<p>
								<strong>Period End Date:</strong>{" "}
								{data?.application?.EndDate}
							</p>
							<p>
								<strong>Date Issued:</strong>{" "}
								{data?.application?.DateIssued}
							</p>
							<p>
								<strong>Discount:</strong>{" "}
								{data?.application?.Discount}
							</p>
							<p>
								<strong>Discount Description:</strong>{" "}
								{data?.application?.DiscountReason}
							</p>
							<p>
								<strong>Outstanding:</strong>{" "}
								{data?.application?.Outstanding}
							</p>
							<p>
								<strong>Outstanding Description:</strong>{" "}
								{data?.application?.OutstandingReason}
							</p>
						</div>
					</div>

					{/* Company Details Section */}
					<div className={styles.companyDetails}>
						<p>
							<strong>Company ESBN:</strong>{" "}
							{data?.application?.ESBN}
						</p>
						<p>
							<strong>Sector:</strong> {data?.application?.Sector}
						</p>
						<p>
							<strong>Company Name:</strong>{" "}
							{data?.application?.Name}
						</p>
						<p>
							<strong>Address:</strong>{" "}
							{data?.application?.Address}
						</p>
						<p>
							<strong>Phone Number:</strong>{" "}
							{data?.application?.PhoneNumber}
						</p>
						<p>
							<strong>Email:</strong> {data?.application?.Email}
						</p>
					</div>
					<h3>Category Details</h3>
					<Table headers={header} data={data?.categories} />

					{/* getting request for approval */}
					{(data?.application?.ApproveFlg === "5" ||
						data?.application?.ApproveFlg === "3") && (
						<ApproveRejectWidget
							type={+data?.application?.ApproveFlg}
							positiveIdiotProofQuestion={`${data?.application?.BillNumber} serial number`}
							processingPositive={approving}
							positiveFunction={approve}
							processingNegative={rejecting}
							negativeIdiotProofQuestion={`${data?.application?.BillNumber} serial number`}
							negativeReasonMessage={`You will be ${
								data?.application?.ApproveFlg === "5"
									? "rejecting"
									: "revoking"
							} “${data?.application?.BillNumber}
                            ” Batch number, an email would be sent to notify them accordingly.`}
							negativeReason={rejectReason}
							setNegativeReason={setRejectReason}
							negativeFunction={
								data?.application?.ApproveFlg === "5"
									? reject
									: revoke
							}
						/>
					)}
					<Modal
						show={showModal}
						onClose={() => {
							closeModal();
							history.push("/ENNSAA/records");
						}}
					>
						{print && (
							<button
								className={["secondary__btn", style.btn].join(
									" "
								)}
								onClick={printReceipt}
							>
								Print
							</button>
						)}
						<Receipt data={imageData} />
					</Modal>
				</div>
			)}
		</>
	);
};

export default EnnsaaAuthoriseView;
