import { useLocation } from "react-router-dom";
import { VIEWER } from "../withholdingConstants";
import { EditEmployerInformation } from "../shared/editEmployerInformation";
const CorporateEmployerInformationEdit = () => {
	const { state: locationPassedData } = useLocation();

	return (
		<EditEmployerInformation
			viewer={VIEWER.CORPORATE}
			passedEmployeeData={{
				...locationPassedData
				// Outstanding: 0
			}}
		/>
	);
};

export default CorporateEmployerInformationEdit;
