import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import { formatAmount, formatDate, getStatusText } from "../../../utils";
import Table from "../../../components/Table";
import Alert from "../../../components/Alert";
import Modal from "../../../components/Modal/regular";
import constants from "../../../constants";
import ApproveRejectWidget from "../../../components/ApproveRejectWidget";
import GoBackButton from "../../../components/goBackButton";
import WHTstyles from "../../WHT/style.module.css";
import styles1 from "../../MDAs/style.module.css";
import PAYEviewDetails from "../../PAYE/Paye-Record/style.module.css";
import anssidStyles from "../../StateTIN/style.module.css";
import styles2 from "../../../Direct-assessment/Create-direct-assessment/style.module.css";
import closeBlackCircleBtn from "../../../assets/x-circle-black.svg";
import downloadAll from "../../../assets/downloadAll.svg";
import pdfImg from "../../../assets/pdfImg.svg";
import ArrowDownDownloadBtn from "../../../assets/ArrowDownDownloadBtn.svg";
import html2pdf from "html2pdf.js";

const headers = [
	["S/N", "serialNumber"],
	["Type Of CGT ", "TypeOfCGT"],
	["Type Of Property ", "TypeOfProperty"],
	[
		" Aquisition Amount ",
		"AquisitionAmount",
		(value) => `₦${formatAmount(value)}`
	],
	[
		"Current Market Value",
		"CurrentMarketValue",
		(value) => `₦${formatAmount(value)}`
	],
	["Sales Amount", "SalesAmount", (value) => `₦${formatAmount(value)}`],
	[" Profit", "Profit", (value) => `₦${formatAmount(value)}`],
	["CGTRate", "CGTRate", (value) => `${value}%`],
	["Tax Due", "Tax", (value) => `₦${formatAmount(value)}`]
];

const CGTauthoriserView = () => {
	const { state: locationState } = useLocation();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [rejectReason, setRejectReason] = useState("");
	const [rejecting, setRejecting] = useState("");
	const [approving, setApproving] = useState(false);
	const [showingBill, setShowingBill] = useState(false);
	const [isBill, setIsBill] = useState(null);
	const [uploadedDocuments, setUploadedDocuments] = useState([]);
	const [CGTPropertyDetails, setCGTPropertyDetails] = useState([]);

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const accessToken = localStorage.getItem("access_token");

	const getPropertiesDetails =
		({ currentPage = 1 } = {}) =>
		async (e) => {
			e?.preventDefault?.();
			setLoading(true);

			try {
				let { data } = await axios
					.get(
						`${constants.BASE1_URL}/getCGTCompanyInfoRecord/${locationState.id}`,
						{
							headers: {
								Authorization: `Bearer ${accessToken}`
							}
						}
					)
					.then((res) => res.data);
				const CGTPropertyDetailsWithSerialNumber =
					data.CGTPropertyDetails.map((propertDetails, index) => ({
						...propertDetails,
						serialNumber: index + 1
					}));
				setData(data);
				setUploadedDocuments(data.CGTCompanyFile);
				setCGTPropertyDetails(CGTPropertyDetailsWithSerialNumber);
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.message ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.response?.data?.msg
					});
				}
			} finally {
				setLoading(false);
			}
		};

	useEffect(() => {
		getPropertiesDetails()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const viewBill = async () => {
		setLoading(true);
		try {
			const bill = await axios
				.get(`${constants.BASE1_URL}/getCGTReceipt/${locationState.id}`)
				.then((res) => res.data);
			setIsBill(bill);
			setShowingBill(true);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setLoading(false);
		}
	};

	const reject = async (e) => {
		e.preventDefault();
		try {
			setRejecting(true);
			const response = await axios
				.put("/globalprocesswithnobill", {
					globId: "11",
					approve: "2", // 1: Cancel; 2: Declined; 3: Approved; 4: Revoked; 5: Pending Approval
					reason4Reject: rejectReason,
					recId: locationState.id
				})
				.then((res) => res.data);
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: response.msg
			});
			setTimeout(() => history.goBack(), 3000);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: error.response.data?.msg
				});
			}
		} finally {
			setRejecting(false);
		}
	};

	const revoke = async (e) => {
		e.preventDefault();
		setRejecting(true);
		try {
			const response = await axios
				.put("/globalprocesswithnobill", {
					globId: "11",
					approve: "4", // 1: Cancel; 2: Declined; 3: Approved; 4: Revoked; 5: Pending Approval
					reason4Reject: rejectReason,
					recId: locationState.id
				})
				.then((res) => res.data);
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: response.msg
			});
			setTimeout(() => history.goBack(), 3000);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: error.response.data?.msg
				});
			}
		} finally {
			setRejecting(false);
		}
	};

	const approve = async () => {
		setApproving(true);
		const payload = {
			globId: "11",
			approve: "3",
			reason4Reject: "",
			recId: data.id,
			name_on_bill: data.Name,
			esbn_pid_on_bill: data.ESBN,
			total_current_bill_amount: data.TotalAmountBilled,
			total_outstanding_bill_amount: data.Outstanding,
			total_discount_bill_amount: data.Discount,
			total_grand_bill_amount: data.GrandTotal,
			bill_module_id: "1",
			bill_mda: "38",
			bill_lga: "Headquarter",
			bill_zone: "Headquarter",
			bill_taxoffice: "Headquarter",
			centbilldet: data.CGTPropertyDetails.map((item) => ({
				mda: "38",
				esbn_pid: item.ESBN,
				esbn_pid_name: item.ESBNName,
				address: item.Address,
				billdescr: "Capital Gain Tax",
				amount: item.Tax,
				outstandingamount: 0,
				outstandingreason: "",
				revenuecode: item.RevenueCode
			}))
		};

		try {
			const response = await axios
				.put("globalprocessbillOnly", payload)
				.then((res) => res.data || {});
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: response.msg
			});
			setTimeout(() => history.goBack(), 3000);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: error.response.data?.msg
				});
			}
		} finally {
			setApproving(false);
		}
	};

	const handleDocumentView = (documentId) => {
		try {
			// Find the selected file using FileId
			const selectedFile = data?.CGTCompanyFile?.find(
				(file) => file.id === documentId
			);

			let base64WithoutPrefix = selectedFile.File;
			let contentType = selectedFile.FileType;

			// Detect and strip Base64 prefix (if it exists)
			const matches = base64WithoutPrefix.match(
				/^data:(.*?);base64,(.*)$/
			);
			if (matches) {
				contentType = matches[1];
				base64WithoutPrefix = matches[2];
			}

			if (!/^[A-Za-z0-9+/=]+$/.test(base64WithoutPrefix)) {
				window.alert("Invalid Base64 file format.");
				return;
			}

			const byteCharacters = atob(base64WithoutPrefix);
			const byteNumbers = Array.from(byteCharacters, (char) =>
				char.charCodeAt(0)
			);
			const byteArray = new Uint8Array(byteNumbers);
			const blob = new Blob([byteArray], { type: contentType });
			const blobUrl = URL.createObjectURL(blob);

			const newTab = window.open(blobUrl, "_blank");
			if (!newTab) {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: "Please allow popups for this site."
				});
			}

			setTimeout(() => {
				URL.revokeObjectURL(blobUrl);
			}, 5000);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: error.response.data?.msg
				});
			}
		}
	};

	const printBill = (e) => {
		e.preventDefault();

		const w = window.open();
		w.document.write(isBill);
		w.document.close();
		w.onload = () => {
			w.focus();
			w.print();
		};

		w.onafterprint = () => {
			w.close();
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
		};
	};

	const downloadBill = (e) => {
		e.preventDefault();

		const element = document.createElement("div");
		element.innerHTML = isBill;

		const options = {
			margin: 10,
			filename: "bill.pdf",
			image: { type: "jpeg", quality: 0.98 },
			html2canvas: {
				scale: 1.2,
				useCORS: true
			},
			jsPDF: {
				unit: "pt", // Measurement units: 'mm', 'pt', 'cm', 'in'
				format: "a4",
				orientation: "landscape" // 'portrait' or 'landscape'
			}
		};

		// Generate and download the PDF
		html2pdf().set(options).from(element).save();
	};

	return (
		<main className={[styles2.wrapper, WHTstyles.main].join(" ")}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<GoBackButton />
			<div style={{ float: "inline-end" }}>
				<button
					style={{ boxShadow: "none" }}
					className={WHTstyles.billButton}
					onClick={() => viewBill()}
				>
					View Bill
				</button>
			</div>
			<h2 className={WHTstyles.subHeader}>Company Details </h2>{" "}
			<div className={WHTstyles.whtViewDetails}>
				<div className={WHTstyles.detailsSection}>
					<div className={PAYEviewDetails.employeeColumn}>
						<div className={PAYEviewDetails.employeeColumn1}>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									ESBN:
								</div>
								<div className={PAYEviewDetails.detailsValue}>
									{locationState.esbn}
								</div>
							</div>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Name:
								</div>
								<div className={PAYEviewDetails.detailsValue}>
									{locationState.name}
								</div>
							</div>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Sector:{" "}
								</div>
								<div className={PAYEviewDetails.detailsValue}>
									{locationState.sector}
								</div>
							</div>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Email:{" "}
								</div>
								<div className={PAYEviewDetails.detailsValue}>
									{locationState.email}
								</div>
							</div>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Address:{" "}
								</div>
								<div className={PAYEviewDetails.detailsValue}>
									{locationState.address}
								</div>
							</div>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Phone Number:{" "}
								</div>
								<div className={PAYEviewDetails.detailsValue}>
									{locationState.phone}
								</div>
							</div>
						</div>
					</div>
					<hr />
					<div className={PAYEviewDetails.employeeColumn}>
						<div className={PAYEviewDetails.employeeColumn1}>
							<h2 className={WHTstyles.subHeader}>
								Bill Details{" "}
							</h2>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Mode Assessment:
								</div>
								<div className={PAYEviewDetails.detailsValue}>
									{locationState.filingMode}
								</div>
							</div>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Bill Number:{" "}
								</div>
								<div className={PAYEviewDetails.detailsValue}>
									{locationState.billNumber}
								</div>
							</div>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Discount:{" "}
								</div>
								<div className={PAYEviewDetails.detailsValue}>
									{`₦${formatAmount(locationState.discount)}`}{" "}
								</div>
							</div>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Outstanding:{" "}
								</div>
								<div className={PAYEviewDetails.detailsValue}>
									{`₦${formatAmount(
										locationState.outstanding
									)}`}
								</div>
							</div>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Total Amount Billed{" "}
								</div>
								<div className={PAYEviewDetails.detailsValue}>
									{`₦${formatAmount(
										locationState.totalAmountBilled
									)}`}
								</div>
							</div>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Creation Date
								</div>
								<div className={PAYEviewDetails.detailsValue}>
									{formatDate(locationState.inputterTime)}
								</div>
							</div>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Process Status:{" "}
								</div>
								<div
									className={`${
										PAYEviewDetails.detailsValue
									} ${
										getStatusText(
											locationState?.approveFlg
										).toLowerCase() === "pending"
											? "pending"
											: getStatusText(
														locationState?.approveFlg
												  ).toLowerCase() ===
												  "pending approval"
												? "pending__approval"
												: getStatusText(
															locationState?.approveFlg
													  ).toLowerCase() ===
													  "approved"
													? "approved"
													: "denied"
									} `}
								>
									{getStatusText(locationState.approveFlg)}
								</div>
							</div>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Payment Status:{" "}
								</div>
								<div
									className={`${
										PAYEviewDetails.detailsValue
									} ${
										getStatusText(
											locationState?.approveFlg
										).toLowerCase() === "pending"
											? "pending"
											: getStatusText(
													locationState?.approveFlg
												).toLowerCase() === "paid"
									} `}
								>
									{locationState.paymentStatus}
								</div>
							</div>
						</div>
					</div>
				</div>
				{uploadedDocuments?.length > 0 && (
					<div className={WHTstyles.documentSection}>
						<div className={WHTstyles.documentHeader}>
							<h4> Documents</h4>
							<div>
								<div className={WHTstyles.downloadAll}>
									<h4 style={{ color: "#195130" }}>
										Download{" "}
									</h4>
									<img
										src={downloadAll}
										alt="Download Icon"
									/>
								</div>
							</div>
						</div>
						<ul className={WHTstyles.downLoadedFileLists}>
							{uploadedDocuments?.map((doc) => (
								<li
									key={doc.id}
									className={WHTstyles.downLoadedFile}
								>
									<div>
										<img src={pdfImg} alt="Document Logo" />
										<span>{doc.FileName}</span>
									</div>
									<button
										onClick={() =>
											handleDocumentView(doc.id)
										}
										className={WHTstyles.billButton}
										style={{ boxShadow: "none" }}
									>
										View
									</button>
								</li>
							))}
						</ul>
					</div>
				)}
			</div>
			<h2 className={WHTstyles.subHeader}>Properties Details</h2>
			<div className={styles1.table__div}>
				<Table
					headers={headers}
					data={CGTPropertyDetails}
					loading={loading}
					full
					pageSize={20}
					onPageChanged={getPropertiesDetails}
				/>
			</div>
			{(locationState.approveFlg === "5" ||
				locationState.approveFlg === "3") &&
				CGTPropertyDetails.length > 0 && (
					<ApproveRejectWidget
						type={+locationState.approveFlg}
						positiveIdiotProofQuestion={`"${locationState?.billNumber}" CGT Assessment`}
						processingPositive={approving}
						positiveFunction={approve}
						processingNegative={rejecting}
						negativeIdiotProofQuestion={`"${locationState?.billNumber}" CGT Assessment`}
						negativeReasonMessage={`You will be ${
							locationState.approveFlg === "5"
								? "rejecting"
								: "revoking"
						} “${
							locationState?.billNumber
						}”CGT Assessment an email would be sent to notify them accordingly.`}
						negativeReason={rejectReason}
						setNegativeReason={setRejectReason}
						negativeFunction={
							locationState.approveFlg === "5" ? reject : revoke
						}
					/>
				)}
			{showingBill && (
				<Modal toggle={setShowingBill} showing={showingBill}>
					<div className={WHTstyles.generateBill}>
						<div className={WHTstyles.billCloseButton}>
							<button
								style={{ boxShadow: "none" }}
								className={WHTstyles.billButton}
								onClick={() => setShowingBill(false)}
							>
								<img
									src={closeBlackCircleBtn}
									alt="close button"
								/>
							</button>
						</div>
						<div
							dangerouslySetInnerHTML={{
								__html: isBill
							}}
						/>
					</div>
					<div className={WHTstyles.downloadPrintBtn}>
						<button
							type="button"
							className={`secondary__btn ${anssidStyles.create__btn}`}
							onClick={(e) => {
								printBill(e);
							}}
						>
							Print
						</button>
						<button
							type="button"
							className={`primary__btn ${anssidStyles.create__btn}`}
							onClick={(e) => {
								downloadBill(e);
							}}
						>
							Download{" "}
							<img
								src={ArrowDownDownloadBtn}
								alt="Arrow Down Icon"
								style={{ marginLeft: "5px" }}
							/>
						</button>
					</div>
				</Modal>
			)}
		</main>
	);
};

export default CGTauthoriserView;
