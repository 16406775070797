import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import Table from "../../../components/Table4";
import Alert from "../../../components/Alert";
import Modal from "../../../components/Modal/regular";
import GoBackButton from "../../../components/goBackButton";
import { formatAmount, formatDate } from "../../../utils";
import styles from "../Paye-Record/style.module.css";
import styles1 from "../../MDAs/style.module.css";
import XcircleRed from "../../../assets/x-circle-red.svg";
import closeBtn from "../../../assets/x-circle-black.svg";
import ArrowDownDownloadBtn from "../../../assets/ArrowDownDownloadBtn.svg";

const AuthView = () => {
	const history = useHistory();
	const { state: locationState } = useLocation();
	const [declineConfirmation, setDeclineConfirmation] = useState(false);
	const [revokeConfirmation, setRevokeConfirmation] = useState(false);
	const [approveConfirmation, setApproveConfirmation] = useState(false);
	const [isDeclineModal, setIsDeclineModal] = useState(false);
	const [isRevokeModal, setIsRevokeModal] = useState(false);
	const [declineReason, setDeclineReason] = useState("");
	const [loading, setLoading] = useState(false);
	const [declining, setDeclining] = useState(false);
	const [revoking, setRevoking] = useState(false);
	const [billShow, setBillShow] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [sendingMail, setSendingMail] = useState(false);
	const [receiptData, setReceiptData] = useState(null);
	const [data, setData] = useState([]);

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const getBillDetails =
		({ currentPage = 1 } = {}) =>
		async (e) => {
			e?.preventDefault?.();
			setLoading(true);
			try {
				await axios
					.get(
						`/dareports/tmspayedisplaybilldetails/${locationState.BillNumber}`
					)
					.then((res) => {
						const billdataArray = res.data.data || [];
						setData(billdataArray);
					});
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.message ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.response?.data?.msg
					});
				}
			} finally {
				setLoading(false);
			}
		};

	useEffect(() => {
		getBillDetails()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);
		return () => clearTimeout(delay);
		// eslint-disable-next-line
	}, [!!alert.showing]);

	const fetchBill = async () => {
		try {
			const res = await axios
				.get(`/gettmspaye/${locationState.BillNumber}/1`)
				.then((res) => res.data || {});
			setReceiptData(res);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.message ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		}
	};

	useEffect(() => {
		fetchBill();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const downloadBill = async (e) => {
		e.preventDefault();
		setDownloading(true);
		try {
			const response = await axios.get(
				`/gettmspaye/${locationState.BillNumber}/2`,
				{
					responseType: "arraybuffer"
				}
			);

			const blob = new Blob([response.data], {
				type: "application/pdf"
			});

			// For Internet Explorer
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(blob, "downloaded-file.pdf");
			} else {
				// For other browsers
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = url;
				link.download = "PAYE-Bill.pdf";
				document.body.appendChild(link);
				link.click();

				// Clean up
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
			}
			setBillShow(false);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.message ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		} finally {
			setDownloading(false);
		}
	};

	const sendEmail = async () => {
		setSendingMail(true);
		try {
			const res = await axios.get(
				`/gettmspaye/${locationState.BillNumber}/3`
			);
			const responseData = res.data || {};
			setReceiptData(responseData.email);
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: responseData.msg
			});
			setBillShow(false);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.message ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		} finally {
			setSendingMail(false);
		}
	};

	const printReceipt = (e) => {
		e.preventDefault();

		const w = window.open();
		w.document.write(receiptData);
		w.document.close();
		w.onload = () => {
			w.focus();
			w.print();
		};

		w.onafterprint = () => {
			w.close();
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
		};
	};

	const approve = async () => {
		try {
			const { msg } = await axios
				.put("processpaye", {
					billno: locationState.BillNumber,
					approve: "3", // 1: Cancel; 2: Declined; 3: Approved; 4: Revoked; 5: Pending Approval
					reason4Reject: "test",
					recId: locationState.id
				})
				.then((res) => res.data);
			setApproveConfirmation(false);
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: msg || "Bill has successfully been approved"
			});
			setTimeout(() => history.goBack(), 3000);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: error.response.data?.msg
				});
			}
			setApproveConfirmation(false);
		}
	};

	const Decline = async () => {
		setDeclining(true);
		try {
			const { msg } = await axios
				.put("processpaye", {
					billno: locationState.BillNumber,
					approve: "2", // 1: Cancel; 2: Declined; 3: Approved; 4: Revoked; 5: Pending Approval
					reason4Reject: declineReason,
					recId: locationState.id
				})
				.then((res) => res.data);
			setDeclineReason("");
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: msg || "Bill has been successfully declined"
			});
			setTimeout(() => history.goBack(), 3000);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		} finally {
			setDeclining(false);
		}
	};

	const Revoke = async () => {
		setRevoking(true);
		try {
			const { msg } = await axios
				.put("processpaye", {
					billno: locationState.BillNumber,
					approve: "4", // 1: Cancel; 2: Declined; 3: Approved; 4: Revoked; 5: Pending Approval
					reason4Reject: declineReason,
					recId: locationState.id
				})
				.then((res) => res.data);
			setDeclineReason("");
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: msg || "Bill has been successfully revoked"
			});
			setTimeout(() => history.goBack(), 3000);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
			setDeclineReason("");
		} finally {
			setRevoking(false);
		}
	};

	const calMonthDue = locationState.totAmtCharged / 12;

	const headers = [
		["Staff Name ", "StaffName"],
		["Total Annual Income Per Annum ", "AnnualPay", formatAmount],
		["Total Income Per Month ", "MthPay", formatAmount],
		["CRA N200,000.00 + 20% of 3 ", "CRA", formatAmount],
		["Pension Contribution Per Annum 8% of 3  ", "Pension", formatAmount],
		[
			"NHIS Contribution Per Annum 2.5% of 3 or less  ",
			"NHIS",
			formatAmount
		],
		["NHF Contribution Per Annum 2.5% of 3 or less ", "NHF", formatAmount],
		["Life Assurance Contribution Per Annum ", "Life", formatAmount],
		["Total Relief Sum of 5 to 9 ", "TotRelief", formatAmount],
		["Taxable Income 3 - 10 ", "TaxableIncome", formatAmount],
		["Tax Due Per Annum ", "AnnualTaxDue", formatAmount],
		["Tax Due Per Month ", "MthTaxDue", formatAmount],
		["Development Levy N500.00 per Annum ", "DevLevy", formatAmount]
	];

	return (
		<section>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<GoBackButton />
			<div className={styles.downloadSec}>
				<h2 className={styles.companyDetailsHeader}>Company Details</h2>
				<div
					className={styles.voucher}
					onClick={() => {
						setBillShow(true);
					}}
				>
					<h4>View Bill </h4>
				</div>
			</div>
			<div className={styles.employeeDetails}>
				<div>
					<div className={styles.employeeDetail}>
						<h4 className={styles.detailsKey}>Company Name:</h4>
						<h4 className={styles.detailsValue}>
							{locationState.CoyName}
						</h4>
					</div>
					<div className={styles.employeeDetail}>
						<h4 className={styles.detailsKey}>ESBN: </h4>
						<h4 className={styles.detailsValue}>
							{locationState.CoyESBN}
						</h4>
					</div>
					<div className={styles.employeeDetail}>
						<h4 className={styles.detailsKey}>PAYE Bill Number:</h4>
						<h4 className={styles.detailsValue}>
							{locationState.BillNumber}
						</h4>
					</div>
					<div className={styles.employeeDetail}>
						<h4 className={styles.detailsKey}>
							Amount for PAYE Type:
						</h4>
						{locationState.PayeType === "Yearly" ? (
							<h4 className={styles.detailsValue}>
								&#8358;{" "}
								{formatAmount(locationState.totAmtCharged)}
							</h4>
						) : (
							""
						)}
						{locationState.PayeType === "Monthly" ? (
							<h4 className={styles.detailsValue}>
								&#8358;{" "}
								{formatAmount(locationState.MonthlyTypeAmtBill)}
							</h4>
						) : (
							""
						)}

						{locationState.PayeType === "Period" ? (
							<h4 className={styles.detailsValue}>
								&#8358;{" "}
								{formatAmount(locationState.PeriodTypeAmtBill)}
							</h4>
						) : (
							""
						)}
					</div>
					<div className={styles.employeeDetail}>
						<h4 className={styles.detailsKey}>Creation Date </h4>
						<h4 className={styles.detailsValue}>
							{formatDate(locationState.InputterTime)}
						</h4>
					</div>
				</div>
				<div>
					<div className={styles.employeeDetail}>
						<h4 className={styles.detailsKey}>Assessment Type:</h4>
						<h4 className={styles.detailsValue}>
							{locationState.PayeType}
						</h4>
					</div>
					{locationState.PayeType === "Monthly" ? (
						<div className={styles.employeeDetail}>
							<h4 className={styles.detailsKey}>
								Schedule Period:
							</h4>
							<h4 className={styles.detailsValue}>
								{locationState.fiscalMth}&nbsp;
								{locationState.fiscalYear}
							</h4>
						</div>
					) : (
						""
					)}
					{locationState.PayeType === "Yearly" ? (
						<div className={styles.employeeDetail}>
							<h4 className={styles.detailsKey}>
								Schedule Period:
							</h4>
							<h4 className={styles.detailsValue}>
								{locationState.fiscalYear}
							</h4>
						</div>
					) : (
						""
					)}
					{locationState.PayeType === "Period" ? (
						<div className={styles.employeeDetail}>
							<h4 className={styles.detailsKey}>
								Schedule Period:{" "}
							</h4>
							<h4 className={styles.detailsValue}>
								{locationState.PayePeriodStartDate}/
								{locationState.PayePeriodEndDate}
							</h4>
						</div>
					) : (
						""
					)}
					<div className={styles.employeeDetail}>
						<h4 className={styles.detailsKey}>Process Status: </h4>
						<h4
							className={
								styles[`status-${locationState.ApproveFlg}`]
							}
						>
							{locationState.ProcessStatus}
						</h4>
					</div>
					<div className={styles.employeeDetail}>
						{locationState.PayeType === "Yearly" ? (
							<>
								<h4 className={styles.detailsKey}>
									Monthly Due:
								</h4>
								<h4 className={styles.detailsValue}>
									&#8358; {formatAmount(calMonthDue)}
								</h4>
							</>
						) : (
							""
						)}
					</div>
				</div>
				<div></div>
			</div>
			<h2 className={styles.companyDetailsHeader}>Employee Details </h2>
			{loading && !data ? (
				<p style={{ textAlign: "center" }}>Loading...</p>
			) : (
				<>
					<div className={styles1.table__div}>
						<Table
							headers={headers}
							data={data}
							full
							pageSize={20}
							onPageChanged={getBillDetails}
						/>
					</div>
					{locationState.ApproveFlg === "5" && (
						<div className={styles.approveRevoke_btn}>
							<button
								className={[
									"secondary__btn",
									styles.revoke__btn
								].join(" ")}
								onClick={() => {
									setDeclineConfirmation(true);
									setApproveConfirmation(false);
								}}
							>
								Decline
							</button>
							<button
								className={[
									"primary__btn",
									styles.approve__btn
								].join(" ")}
								onClick={() => {
									setApproveConfirmation(true);
									setDeclineConfirmation(false);
								}}
							>
								Approve
							</button>
						</div>
					)}
					{declineConfirmation && (
						<div className={styles.cancelMiniModal}>
							<p>
								Are you sure you want to{" "}
								<strong style={{ color: "#d73e3e" }}>
									Decline
								</strong>
								&nbsp;this PAYE <br />
								Assessment with &nbsp;
								<strong>{locationState.CoyESBN}</strong> ESBN?
							</p>
							<div className={styles.miniModalBtns}>
								<button
									style={{
										backgroundColor: "#ffff",
										color: "#000"
									}}
									onClick={() =>
										setDeclineConfirmation(false)
									}
								>
									No
								</button>
								<button
									style={{ backgroundColor: "#d73e3e" }}
									onClick={() => {
										setIsDeclineModal(true);
										setDeclineConfirmation(false);
									}}
								>
									Yes
								</button>
							</div>
						</div>
					)}
					{approveConfirmation && (
						<div className={styles.cancelMiniModal}>
							<p>
								Are you sure you want to{" "}
								<strong style={{ color: "#34AA63" }}>
									Approve
								</strong>{" "}
								&nbsp;this PAYE <br />
								Assessment with &nbsp;
								<strong>{locationState.CoyESBN}</strong> ESBN?
							</p>
							<div className={styles.miniModalBtns}>
								<button
									style={{
										backgroundColor: "#ffff",
										color: "#000"
									}}
									onClick={() =>
										setApproveConfirmation(false)
									}
								>
									No
								</button>
								<button
									style={{ backgroundColor: "#d73e3e" }}
									onClick={() => {
										approve(true);
										setApproveConfirmation(false);
									}}
								>
									Yes
								</button>
							</div>
						</div>
					)}
					{locationState.ApproveFlg === "3" && (
						<div className={styles.approveRevoke_btn}>
							<button
								className={[
									"secondary__btn",
									styles.revoke__btn
								].join(" ")}
								onClick={() => {
									setRevokeConfirmation(true);
								}}
							>
								Revoke
							</button>
						</div>
					)}
					{revokeConfirmation && (
						<div className={styles.cancelMiniModal}>
							<p>
								Are you sure you want to{" "}
								<strong style={{ color: "#d73e3e" }}>
									Revoke
								</strong>
								&nbsp;this PAYE <br />
								Assessment with &nbsp;
								<strong>{locationState.CoyESBN}</strong> ESBN?
							</p>
							<div className={styles.miniModalBtns}>
								<button
									style={{
										backgroundColor: "#ffff",
										color: "#000"
									}}
									onClick={() => setRevokeConfirmation(false)}
								>
									No
								</button>
								<button
									style={{ backgroundColor: "#d73e3e" }}
									onClick={() => {
										setIsRevokeModal(true);
										setRevokeConfirmation(false);
									}}
								>
									Yes
								</button>
							</div>
						</div>
					)}
					{isDeclineModal && (
						<Modal
							toggle={setIsDeclineModal}
							showing={isDeclineModal}
						>
							<div className={styles.approvalSuccess__badge}>
								<img
									src={XcircleRed}
									alt="close button icon"
									style={{ width: "40px", height: "40px" }}
								/>
								<span>
									<h3>Declined </h3>
								</span>
							</div>
							<div className={styles.content}>
								<p>
									You are about to decline{" "}
									<q>{locationState.CoyName}</q> PAYE Filing,
									an email would <br /> be sent to notify them
									accordingly.
								</p>
								<label htmlFor="reasonForDecline">
									Reason For Declining{" "}
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="reasonForDecline"
									placeholder="write the reason for rejecting this PAYE Assessment"
									type="text"
									value={declineReason}
									onChange={(e) =>
										setDeclineReason(e.target.value)
									}
								/>
								<div className={styles.continueBtn}>
									<button
										className={[
											"primary__btn",
											styles.contBtn
										].join(" ")}
										onClick={() => {
											Decline();
										}}
										disabled={declining}
									>
										{declining
											? "Declining..."
											: "Continue"}
									</button>
								</div>
							</div>
						</Modal>
					)}
					{isRevokeModal && (
						<Modal
							toggle={setIsRevokeModal}
							showing={isRevokeModal}
						>
							<div className={styles.approvalSuccess__badge}>
								<img
									src={XcircleRed}
									alt="close button icon"
									style={{ width: "40px", height: "40px" }}
								/>
								<span>
									<h3>Revoked</h3>
								</span>
							</div>
							<div className={styles.content}>
								<p>
									You are about to revoke &nbsp;{" "}
									<q>{locationState.CoyName}</q>
									PAYE Filing, an email would <br /> be sent
									to notify them accordingly.
								</p>
								<label htmlFor="reasonForRevoking">
									Reason For Revoking{" "}
									<span style={{ color: "red" }}> *</span>
								</label>
								<input
									id="reasonForRevoking"
									placeholder="write the reason for revoking this PAYE Assessment"
									type="text"
									value={declineReason}
									onChange={(e) =>
										setDeclineReason(e.target.value)
									}
								/>
								<div className={styles.continueBtn}>
									<button
										className={[
											"primary__btn",
											styles.contBtn
										].join(" ")}
										onClick={() => {
											Revoke();
										}}
										disabled={revoking}
									>
										{revoking ? "Revoking..." : "Continue"}
									</button>
								</div>
							</div>
						</Modal>
					)}
					{billShow && (
						<Modal toggle={setBillShow} showing={billShow}>
							<div className={styles.closeBillbtn}>
								<img
									src={closeBtn}
									alt="close Button"
									onClick={() => setBillShow(false)}
								/>
							</div>
							{receiptData && (
								<>
									<div
										dangerouslySetInnerHTML={{
											__html: receiptData
										}}
										className={styles.doc__area}
										style={{
											marginTop: "24px"
										}}
									></div>
									{locationState.ApproveFlg === "3" && (
										<div className={styles.actionBtns}>
											<button
												type="button"
												onClick={() => sendEmail()}
											>
												{sendingMail
													? "Sending mail..."
													: "Send by Mail"}
											</button>
											<button
												type="button"
												onClick={(e) => {
													printReceipt(e);
												}}
												style={{
													backgroundColor: "#ffff",
													color: "#34aa63"
												}}
											>
												Print
											</button>
											<button
												type="button"
												onClick={(e) => {
													downloadBill(e);
												}}
											>
												{downloading ? (
													"downloading..."
												) : (
													<div>
														Download PAYE Schedule
														<img
															src={
																ArrowDownDownloadBtn
															}
															alt="Arrow Down Icon"
															style={{
																marginLeft:
																	"5px"
															}}
														/>
													</div>
												)}
											</button>
										</div>
									)}
								</>
							)}
						</Modal>
					)}
				</>
			)}
		</section>
	);
};

export default AuthView;
