import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import html2pdf from "html2pdf.js";
import { formatAmount, getStatusText } from "../../../utils";
import Table from "../../../components/Table";
import Alert from "../../../components/Alert";
import Modal from "../../../components/Modal/regular";
import constants from "../../../constants";
import GoBackButton from "../../../components/goBackButton";
import style from "../style.module.css";
import styles1 from "../../MDAs/style.module.css";
import anssidStyles from "../../StateTIN/style.module.css";
import PAYEviewDetails from "../../PAYE/Paye-Record/style.module.css";
import styles2 from "../../../Direct-assessment/Create-direct-assessment/style.module.css";
import closeBlackCircleBtn from "../../../assets/x-circle-black.svg";
import downloadAll from "../../../assets/downloadAll.svg";
import pdfImg from "../../../assets/pdfImg.svg";
import ApproveRejectWidget from "../../../components/ApproveRejectWidget";
import ArrowDownDownloadBtn from "../../../assets/ArrowDownDownloadBtn.svg";

const headers = [
	["S/N", "serialNumber"],
	["Contractor Name", "Contractor_Name"],
	["Contractor ESBN", "Contractor_ESBN"],
	["Contract Type", "Contract_Type"],
	["Sum ", "Sum", (value) => `₦${formatAmount(value)}`],
	["Rate ", "Rate", (value) => `${value}%`],
	["Tax Due", "Total", (value) => `₦${formatAmount(value)}`],
	["StartDate   ", "StartDate"],
	["EndDate  ", "EndDate"]
];

const WHTCorporateView = () => {
	const { state: locationState } = useLocation();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [cancelReason, setCancelReason] = useState("");
	const [showingBill, setShowingBill] = useState(false);
	const [generatedBill, setGeneratedBill] = useState(null);
	const [uploadedDocuments, setUploadedDocuments] = useState([]);
	const [contractorsDetails, setContractorsDetails] = useState([]);
	const [cancelling, setCancelling] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const accessToken = localStorage.getItem("access_token");

	const getContractorDetails =
		({ currentPage = 1 } = {}) =>
		async (e) => {
			e?.preventDefault?.();
			setLoading(true);

			try {
				const accessToken = localStorage.getItem("access_token");
				let response = await axios.get(
					`${constants.BASE_URL_2}/withholding/${locationState.id}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				);
				const { data } = response.data;
				const contractorsWithSerialNum = data.contractors.map(
					(contactors, index) => ({
						...contactors,
						serialNumber: index + 1
					})
				);
				setUploadedDocuments(data.files);
				setContractorsDetails(contractorsWithSerialNum);
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.message ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.response?.data?.msg
					});
				}
			} finally {
				setLoading(false);
			}
		};

	useEffect(() => {
		getContractorDetails()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const downloadDocument = async (documentId) => {
		try {
			const response = await axios.get(
				`${constants.BASE_URL_2}/withholding/document/${documentId}`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					},
					responseType: "blob" // To handle the binary data as a PDF
				}
			);

			const contentType = response.headers["content-type"];

			// Create a blob URL for the PDF
			const url = window.URL.createObjectURL(
				new Blob([response.data], { type: contentType })
			);

			// Open the PDF in a new browser tab
			window.open(url, "_blank");
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details?.[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: "An error occurred while downloading the document."
				});
			}
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const generateBill = async () => {
		setLoading(true);
		try {
			const bill = await axios
				.get(
					`${constants.BASE_URL_2}/withholding/receipt/${locationState.id}`
				)
				.then((res) => res.data);
			setGeneratedBill(bill);
			setShowingBill(true);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: error.response.data?.msg
				});
			}
		} finally {
			setLoading(false);
		}
	};

	const cancel = async (e) => {
		e.preventDefault();
		try {
			setCancelling(true);
			const response = await axios
				.put("globalprocesswithnobill", {
					globId: "12",
					approve: "1", // 1: Cancel; 2: Declined; 3: Approved; 4: Revoked; 5: Pending Approval
					reason4Reject: cancelReason,
					recId: locationState.id
				})
				.then((res) => res.data);
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: response.msg
			});
			setTimeout(() => history.goBack(), 3000);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: error.response.data?.msg
				});
			}
		} finally {
			setCancelling(false);
		}
	};

	const printBill = (e) => {
		e.preventDefault();

		const w = window.open();
		w.document.write(generatedBill);
		w.document.close();
		w.onload = () => {
			w.focus();
			w.print();
		};

		w.onafterprint = () => {
			w.close();
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
		};
	};

	const downloadBill = (e) => {
		e.preventDefault();

		const element = document.createElement("div");
		element.innerHTML = generatedBill;

		const options = {
			margin: 10,
			filename: "bill.pdf",
			image: { type: "jpeg", quality: 0.98 },
			html2canvas: {
				scale: 1.2,
				useCORS: true
			},
			jsPDF: {
				unit: "pt", // Measurement units: 'mm', 'pt', 'cm', 'in'
				format: "a4",
				orientation: "landscape" // 'portrait' or 'landscape'
			}
		};

		// Generate and download the PDF
		html2pdf().set(options).from(element).save();
	};

	return (
		// <main className={[styles2.wrapper, styles.main].join(" ")}>
		<main className={[styles2.wrapper, style.main].join(" ")}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<GoBackButton />
			<div style={{ float: "inline-end" }}>
				<button
					style={{ boxShadow: "none" }}
					className={style.billButton}
					onClick={() => generateBill()}
				>
					View Bill
				</button>
			</div>
			<h2 className={style.subHeader}>Company Details </h2>
			<div className={style.whtViewDetails}>
				<div className={style.detailsSection}>
					<div className={PAYEviewDetails.employeeColumn}>
						<div className={PAYEviewDetails.employeeColumn1}>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Employer’s ESBN:{" "}
								</div>
								<div className={PAYEviewDetails.detailsValue}>
									{locationState.Employer_ESBN}
								</div>
							</div>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Company Name:{" "}
								</div>
								<div className={PAYEviewDetails.detailsValue}>
									{locationState.Employer_Name}
								</div>
							</div>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Sector:{" "}
								</div>
								<div className={PAYEviewDetails.detailsValue}>
									{locationState.Sector}
								</div>
							</div>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Email:{" "}
								</div>
								<div className={PAYEviewDetails.detailsValue}>
									{locationState.Employer_Email}
								</div>
							</div>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Address:{" "}
								</div>
								<div className={PAYEviewDetails.detailsValue}>
									{locationState.Employer_Address}
								</div>
							</div>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Phone Number:{" "}
								</div>
								<div className={PAYEviewDetails.detailsValue}>
									{locationState.Employer_PhoneNumber}
								</div>
							</div>
						</div>
					</div>
					<hr />
					<div className={PAYEviewDetails.employeeColumn}>
						<div className={PAYEviewDetails.employeeColumn1}>
							<h2 className={style.subHeader}>Bill Details </h2>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Mode Assessment:
								</div>
								<div className={PAYEviewDetails.detailsValue}>
									{locationState.AssessmentMode}
								</div>
							</div>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Bill Number:{" "}
								</div>
								<div className={PAYEviewDetails.detailsValue}>
									{locationState.BillNumber}
								</div>
							</div>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Discount / Rebate:{" "}
								</div>
								<div className={PAYEviewDetails.detailsValue}>
									{locationState.Discount}
								</div>
							</div>

							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Outstanding:{" "}
								</div>
								<div className={PAYEviewDetails.detailsValue}>
									{locationState.Outstanding}
								</div>
							</div>

							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Total Amount Withheld
								</div>
								<div className={PAYEviewDetails.detailsValue}>
									{locationState.TotalAmtBilled}
								</div>
							</div>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Process Status:{" "}
								</div>
								<div
									className={`${
										PAYEviewDetails.detailsValue
									} ${
										getStatusText(
											locationState?.ApproveFlg
										).toLowerCase() === "pending"
											? "pending"
											: getStatusText(
														locationState?.ApproveFlg
												  ).toLowerCase() ===
												  "pending approval"
												? "pending__approval"
												: getStatusText(
															locationState?.ApproveFlg
													  ).toLowerCase() ===
													  "approved"
													? "approved"
													: "denied"
									} `}
								>
									{getStatusText(locationState.ApproveFlg)}
								</div>
							</div>
							<div
								className={
									PAYEviewDetails.employeeColumn1Contents
								}
							>
								<div className={PAYEviewDetails.detailsKey}>
									Payment Status:{" "}
								</div>
								<div
									className={`${
										PAYEviewDetails.detailsValue
									} ${
										getStatusText(
											locationState?.ApproveFlg
										).toLowerCase() === "pending"
											? "pending"
											: getStatusText(
													locationState?.ApproveFlg
												).toLowerCase() === "paid"
									} `}
								>
									{locationState.PaymentStatus}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={style.desktopDocuments}>
					{uploadedDocuments?.length > 0 && (
						<div className={style.documentSection}>
							<div className={style.documentHeader}>
								<span>Contract Documents</span>
								<div className={style.downloadAll}>
									<span style={{ color: "#195130" }}>
										Download
									</span>
									<img
										src={downloadAll}
										alt="Download Icon"
									/>
								</div>
							</div>
							<ul className={style.downLoadedFileLists}>
								{uploadedDocuments.map((doc) => (
									<li
										key={doc.id}
										className={style.downLoadedFile}
									>
										<div>
											<img
												src={pdfImg}
												alt="Document Logo"
											/>
											<span>{doc.Name}</span>
										</div>
										<button
											onClick={() =>
												downloadDocument(doc.id)
											}
											className={style.billButton}
											style={{ boxShadow: "none" }}
										>
											View
										</button>
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			</div>
			<h2 className={style.subHeader}>Contract Details </h2>
			<div className={styles1.table__div}>
				<Table
					headers={headers}
					data={contractorsDetails}
					loading={loading}
					full
					pageSize={20}
					onPageChanged={getContractorDetails}
				/>
			</div>
			{showingBill && (
				<Modal toggle={setShowingBill} showing={showingBill}>
					<div className={style.generateBill}>
						<div
							className={PAYEviewDetails.closeBillbtn}
							style={{ marginBottom: "24px" }}
						>
							<img src={closeBlackCircleBtn} alt="close button" />
						</div>
						<div
							dangerouslySetInnerHTML={{
								__html: generatedBill
							}}
						/>
					</div>
					<div className={style.downloadPrintBtn}>
						<button
							type="button"
							className={`secondary__btn ${anssidStyles.create__btn}`}
							onClick={(e) => {
								printBill(e);
							}}
						>
							Print
						</button>
						<button
							type="button"
							className={`primary__btn ${anssidStyles.create__btn}`}
							onClick={(e) => {
								downloadBill(e);
							}}
						>
							Download{" "}
							<img
								src={ArrowDownDownloadBtn}
								alt="Arrow Down Icon"
								style={{ marginLeft: "5px" }}
							/>
						</button>
					</div>
				</Modal>
			)}

			{locationState.ApproveFlg === "0" &&
				contractorsDetails.length > 0 && (
					<ApproveRejectWidget
						type={1}
						processingNegative={cancelling}
						negativeIdiotProofQuestion={`"${locationState?.BillNumber}" Withholding Assessment`}
						negativeReasonMessage={`You will be cancelling “${locationState?.BillNumber}” Withholding Assessment, an email would be sent to notify them accordingly.`}
						negativeReason={cancelReason}
						setNegativeReason={setCancelReason}
						negativeFunction={cancel}
					/>
				)}

			<div className={style.mobileDocument}>
				{uploadedDocuments?.length > 0 && (
					<div className={style.documentSection}>
						<div className={style.documentHeader}>
							<span>Contract Documents</span>
							<div className={style.downloadAll}>
								<span style={{ color: "#195130" }}>
									Download
								</span>
								<img src={downloadAll} alt="Download Icon" />
							</div>
						</div>
						<ul className={style.downLoadedFileLists}>
							{uploadedDocuments.map((doc) => (
								<li
									key={doc.id}
									className={style.downLoadedFile}
								>
									<div>
										<img src={pdfImg} alt="Document Logo" />
										<span>{doc.Name}</span>
									</div>
									<button
										onClick={() => downloadDocument(doc.id)}
										className={style.billButton}
										style={{ boxShadow: "none" }}
									>
										View
									</button>
								</li>
							))}
						</ul>
					</div>
				)}
			</div>
		</main>
	);
};

export default WHTCorporateView;
