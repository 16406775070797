import React, { createRef, useEffect, useState } from "react";
import { useHistory } from "react-router";
import constants from "../../constants";
import { exportExcel, formatDate, getStatusText, remapObj } from "../../utils";
import axios from "axios";
import styles from "../../Dashboard/MDAs/style.module.css";
import Alert from "../../components/Alert";
import Table from "../../components/Table";
import style from "./style.module.css";

const PTauthoriser = () => {
	const filterRef = createRef();
	const history = useHistory();
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [data, setData] = useState([]);
	const [paymentStats, setPaymentStat] = useState("");
	const [minAmount, setMinAmount] = useState(0);
	const [maxAmount, setMaxAmount] = useState(0);
	const [esbn, setEsbn] = useState("");
	const [name, setName] = useState("");
	const [billNumber, setBillNumber] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);

	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const header = [
		["ESBN", "esbn"],
		["Bill Number", "billNumber"],
		["Sector", "sector"],
		["Total Purchase Amount", "totalPurchasesAmount"],
		["Total P/Tax Amount", "grandTotal"],
		["PT Assessment", "fileType"],
		["Generated By", "generatedBy"],
		["Payment Status", "paymentStatus"],
		["Process Status", "approveFlg", getStatusText],
		["Date Issued", "inputterTime", formatDate]
	];

	const _getAllPTSearch = ({ currentPage = 1, pageSize = 20 }) => {
		let query = `page=${currentPage}&limit=${pageSize}&`;
		if (paymentStats) {
			query += `paymentStatus=${paymentStats}&`;
		}
		if (maxAmount) {
			query += `maxAmount=${maxAmount}&`;
		}
		if (minAmount) {
			query += `minAmount=${minAmount}&`;
		}
		if (name) {
			query += `name=${name}&`;
		}
		if (billNumber) {
			query += `billNumber=${billNumber}&`;
		}
		if (esbn) {
			query += `esbn=${esbn}&`;
		}

		return axios
			.get(`${constants.BASE1_URL}/getPurchaseTaxauthorizerView?${query}`)
			.then((res) => res.data || {});
	};

	useEffect(() => {
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	const clearFilters = () => {
		setPaymentStat("");
		setMinAmount("");
		setMaxAmount("");
		setStartDate("");
		setEndDate("");
		filterRef.current.classList.toggle(styles.no__display);
		setFiltersCleared(true);
	};

	const exportAnalytics = async () => {
		const info = await _getAllPTSearch({
			currentPage: 1,
			pageSize: total
		});
		if (!info.data) return;
		exportExcel(
			remapObj(info.data, header),
			header,
			`Purchasing-Tax Report.xlsx`
		);
	};

	const getAllPTSearch =
		({ currentPage = 1, pageSize = 20 } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);
			try {
				const info = await _getAllPTSearch({
					currentPage,
					pageSize
				});

				if (info) {
					setData(info.data);
					setTotal(info.pagination.totalRecords);
				}
			} catch (error) {
				setAlert({
					showing: true,
					type: "error",
					message:
						error?.response.data?.message ||
						error.response.data?.errors?.[0]?.error ||
						error.response.data?.errors?.details[0]?.message
				});
			} finally {
				setLoading(false);
			}
		};

	useEffect(() => {
		getAllPTSearch()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		getAllPTSearch()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<div>
			<div>
				{alert.showing && (
					<Alert text={alert.message} type={alert.type} />
				)}
				<div className={styles.actions__div}>
					<button
						className={[
							"primary__btn",
							styles.download__button
						].join(" ")}
						onClick={exportAnalytics}
					>
						Download Report
					</button>

					<div className={styles.filter}>
						<button
							onClick={() => {
								filterRef.current.classList.toggle(
									styles.no__display
								);
							}}
							className={styles.filter__button}
						>
							Filter
						</button>
						<div
							ref={filterRef}
							className={[
								styles.filter__div,
								styles.no__display
							].join(" ")}
						>
							<div className={styles.filter__header}>
								<h6>Filter</h6>
								<button
									aria-labelledby="close filter button"
									onClick={() => {
										filterRef.current.classList.toggle(
											styles.no__display
										);
									}}
								>
									X
								</button>
							</div>
							<button
								className={styles.clear__filter_fields}
								onClick={clearFilters}
								disabled={
									!minAmount &&
									!maxAmount &&
									!startDate &&
									!endDate &&
									!paymentStats
								}
							>
								Clear Filter fields
							</button>
							<p>Payment Status</p>
							<form>
								<div>
									<label htmlFor="paymentStats">
										payment Status
									</label>
									<input
										name="paymentStats"
										id="paymentStats"
										type="text"
										value={paymentStats}
										onChange={(e) =>
											setPaymentStat(e.target.value)
										}
									/>
								</div>
								<div>
									<label htmlFor="esbn">ESBN</label>
									<input
										name="esbn"
										id="esbn"
										type="text"
										value={esbn}
										onChange={(e) =>
											setEsbn(e.target.value)
										}
									/>
								</div>
							</form>
							<p>By Name</p>
							<form>
								<div>
									<label htmlFor="name">Name</label>
									<input
										name="name"
										id="name"
										type="text"
										value={name}
										onChange={(e) =>
											setName(e.target.value)
										}
										placeholder="john doe"
									/>
								</div>
								<div>
									<label htmlFor="billNumber">
										Bill Number
									</label>
									<input
										name="billNumber"
										id="billNumber"
										type="text"
										value={billNumber}
										onChange={(e) =>
											setBillNumber(e.target.value)
										}
										placeholder="AB57474784393EN"
									/>
								</div>
							</form>
							<p>By P/Tax Amount</p>
							<form>
								<div>
									<label htmlFor="minAmount">
										Min Amount
									</label>
									<input
										name="minAmount"
										id="minAmount"
										type="text"
										value={minAmount}
										onChange={(e) =>
											setMinAmount(e.target.value)
										}
										placeholder="50"
									/>
								</div>
								<div>
									<label htmlFor="maxAmount">
										Max Amount
									</label>
									<input
										name="maxAmount"
										id="maxAmount"
										type="text"
										value={maxAmount}
										onChange={(e) =>
											setMaxAmount(e.target.value)
										}
										placeholder="50000000"
									/>
								</div>
							</form>
							<p>By Creation Date</p>
							<form>
								<div>
									<label htmlFor="start_date">
										Start Date
									</label>
									<input
										name="start_date"
										id="start_date"
										type="date"
										value={startDate}
										onChange={(e) =>
											setStartDate(e.target.value)
										}
									/>
								</div>
								<div>
									<label htmlFor="start_date">End Date</label>
									<input
										name="end_date"
										id="end_date"
										type="date"
										value={endDate}
										onChange={(e) =>
											setEndDate(e.target.value)
										}
									/>
								</div>
							</form>
							<button
								className={[
									styles.submit__button,
									"primary__btn"
								].join(" ")}
								onClick={getAllPTSearch()}
							>
								Search
							</button>
						</div>
					</div>
				</div>

				{loading && !data ? (
					<p style={{ textAlign: "center" }}>Loading...</p>
				) : (
					<div className={style.table_container}>
						<Table
							headers={header}
							data={data}
							full
							total={total}
							pageSize={20}
							onPageChanged={getAllPTSearch}
							onView={(data) => {
								history.push(
									`/pt/pt-authoriser/view/${data.id}`
								);
							}}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default PTauthoriser;
