import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Alert from "../../../components/Alert";
import constants from "../../../constants";
import Modal from "../../../components/Modal/regular";
import Table from "../../../components/Table";
import GoBackButton from "../../../components/goBackButton";
import PAYEstyle from "../../PAYE/PAYE-Assessment/style.module.css";
import style from "../style.module.css";
import anssidStyles from "../../StateTIN/style.module.css";
import styles1 from "../../MDAs/style.module.css";
import styles3 from "../../../Identity-management-individual/style.module.css";
import successCheck from "../../../assets/Certificate/approvedCheck.svg";
import closeBlackCircleBtn from "../../../assets/x-circle-black.svg";
import { formatAmount } from "../../../utils";
import {
	EditEmployerContactInfo,
	EmployerAssessmentModeComponent,
	EmployerContractDocumentComponent,
	EmployerOtherDetailsComponent
} from "../components";
import { VIEWER, employeeUpdateSchema } from "../withholdingConstants";

const headers = [
	["Contractor Name", "Contractor_Name"],
	["Contractor ESBN", "Contractor_ESBN"],
	["Contractual Sum Agreed (#)", "Sum", (value) => `₦${formatAmount(value)}`],
	["Tariff", "TariffDescription"],
	["Start Date", "StartDate"],
	["End Date", "EndDate"],
	["Payment Date", "PaymentDate"],
	["Tax Due (#)", "Total", (value) => `₦${formatAmount(value)}`]
];

export const EditEmployerInformation = ({
	passedEmployeeData,
	viewer = VIEWER.ADMIN
}) => {
	const accessToken = localStorage.getItem("access_token");
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [setupOptions, setSetupOptions] = useState([]);
	const [calculatedAmount, setCalculatedAmount] = useState(0.0);
	const [isCalculated, setIsCalculated] = useState(false);
	const [calculateLoading, setCalculateLoading] = useState(false);
	const [generateLoading, setGenerateLoading] = useState(false);
	const [isSuccessModal, setIsSuccessModal] = useState(false);
	const [showingBill, setShowingBill] = useState(false);
	const [generatedBill, setGeneratedBill] = useState(null);
	const [generating, setGenerating] = useState(false);
	const [createdFile, setCreatedFile] = useState(false);
	const [sectors, setSectors] = useState([]);
	const [checkingESBNRecord, setCheckingESBNRecord] = useState(false);
	const [tableRecords, setTableRecords] = useState({
		contractors: []
	});
	const [isEditing, setIsEditing] = useState(false);
	// const [canEditEmployerOtherData, setCanEditEmployerOtherData] =
	// 	useState(false);
	const [employerContractDocuments, setEmployerContractDocuments] = useState(
		[]
	);
	const [filesForUpload, setFilesForUpload] = useState({});

	const [employerInformationData, setEmployerInformationData] = useState({
		...JSON.parse(JSON.stringify(passedEmployeeData))
	});
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const getContractorDetails = async () => {
		setLoading(true);
		try {
			let { data } = await axios
				.get(
					`${constants.BASE_URL_2}/withholding/${passedEmployeeData.id}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				.then((res) => res.data);
			setTableRecords(data);
			setEmployerContractDocuments(data.files);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.message ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getContractorDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const reCalculate = async () => {
		setCalculateLoading(true);

		const sumAndRate = tableRecords.contractors.map((prev) => {
			const numericRate =
				typeof prev.Rate === "string"
					? prev.Rate.split("%")[0].trim()
					: prev.Rate;
			return {
				Sum: +prev.Sum,
				Rate: +numericRate
			};
		});
		try {
			const calculator = await axios.post(
				`${constants.BASE_URL_2}/withholding/calculate`,
				{
					Discount_Amount: +employerInformationData.Discount,
					Outstanding_Amount: +employerInformationData.Outstanding,
					data: sumAndRate
				},
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			);
			setCalculatedAmount(calculator.data.data);
			setIsCalculated(true);
		} catch (error) {
			console.error("Error calculating WHT", error);
		} finally {
			setCalculateLoading(false);
		}
	};

	const sendEdit = async () => {
		setGenerateLoading(true);
		const formData = new FormData();

		for (const [key, value] of Object.entries(employeeUpdateSchema)) {
			if (
				key !== "contractors" &&
				employerInformationData[key] !== undefined &&
				employerInformationData[key] !== null
			) {
				formData.append(value, employerInformationData[key]);
				continue;
			}
		}

		if (viewer === VIEWER.ADMIN) {
			formData.append("Service_Type", VIEWER.ADMIN);
		} else {
			formData.append("Service_Type", "SELF");
		}

		const contractorsToSend = tableRecords.contractors.map((contractor) => {
			return Object.entries(employeeUpdateSchema.contractors).reduce(
				(prev, [key, value]) => ({ ...prev, [key]: contractor[value] }),
				{}
			);
		});
		// Append contractors
		if (tableRecords.contractors && tableRecords.contractors.length > 0) {
			formData.append("Contractors", JSON.stringify(contractorsToSend));
		}

		Object.entries(filesForUpload).forEach(([key, file]) => {
			formData.append(`Document${+key + 1}`, file);
		});

		try {
			const response = await axios.post(
				`${constants.BASE_URL_2}/withholding/edit/${passedEmployeeData.id}`,
				formData,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			);

			setCreatedFile(response.data.data);
			setIsSuccessModal(true);
		} catch (error) {
			console.error("Error in edit operation", error);

			const errorMessage = error.response?.data?.errors?.details?.map(
				(detail) => detail.message
			);

			setAlert({
				...alert,
				showing: true,
				type: "error",
				message:
					errorMessage ||
					error.response?.data?.msg ||
					"An error occurred while editing WHT"
			});
		} finally {
			setGenerateLoading(false);
		}
	};

	useEffect(() => {
		const economicSector = async () => {
			try {
				const sector = await axios
					.get(`/settings/economicsector`)
					.then((res) => res.data.data);
				setSectors(sector);
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.response?.data?.msg
					});
				}
			}
		};
		economicSector();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const getSetUpList = async () => {
			try {
				const setUpList = await axios.get(
					`${constants.BASE_URL_2}/withholding/setup/list`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				);
				setSetupOptions(setUpList.data.data);
			} catch (error) {
				console.error("Error while getting the set up list", error);
			}
		};
		getSetUpList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const generateBill = async () => {
		setGenerating(true);
		try {
			const bill = await axios
				.get(
					`${constants.BASE_URL_2}/withholding/receipt/${createdFile.employerInfo.id}`
				)
				.then((res) => res.data);
			setGeneratedBill(bill);
			setShowingBill(true);
		} catch (error) {
		} finally {
			setGenerating(false);
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const handleChange = (e) => {
		const { id, value } = e.target;
		setEmployerInformationData((prev) => ({
			...prev,
			[id]: value
		}));
	};

	const recalculateContractorTaxDeal = (contractorToUpdate) => {
		setTableRecords((prevData) => ({
			...prevData,
			contractors: prevData.contractors.map((contractor) => {
				if (contractor.id === contractorToUpdate.id) {
					const sum = parseInt(contractorToUpdate.Sum);
					const rate = parseInt(
						contractorToUpdate.Rate.toString().split("%")[0]
					);

					return {
						...contractorToUpdate,
						Total: (sum * rate) / 100,
						Rate: rate,
						TariffDescription: contractorToUpdate.Rate.toString()
					};
				}
				return contractor;
			})
		}));
		// setCanEditEmployerOtherData(true);
	};

	const deleteContractorAleadyCreated = (idToRemove) => {
		const filteredArray = tableRecords.contractors.filter(
			(item) => item.id !== idToRemove
		);
		setTableRecords({ ...tableRecords, contractors: filteredArray });
	};
	return (
		<main>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<GoBackButton />
			{/* ///////////Mode of assessment radioBtns////////  */}
			<h3 className={PAYEstyle.header} style={{ marginBottom: "24px" }}>
				Mode of Assessment
				<span style={{ color: "red" }}>*</span>
			</h3>
			<EmployerAssessmentModeComponent
				setEmployerAssementMode={setEmployerInformationData}
				employerAssessmentModeData={{
					AssessmentMode: employerInformationData.AssessmentMode,
					AssessmentYear: employerInformationData.AssessmentYear,
					AssessmentMonth: employerInformationData.AssessmentMonth,
					AssessmentStartPeriod:
						employerInformationData.AssessmentStartPeriod,
					AssessmentEndPeriod:
						employerInformationData.AssessmentEndPeriod
				}}
			/>
			<form className={styles3.columned__form}>
				<section>
					<h3 className={style.headerInfo}>Employer’s Information</h3>
					<div className={styles3.double__inputs}>
						<div>
							<label htmlFor="Employer_ESBN">
								Employer ESBN{" "}
								<span style={{ color: "red" }}> *</span>
							</label>
							<input
								id="Employer_ESBN"
								value={passedEmployeeData.Employer_ESBN}
								onChange={(e) => {
									setEmployerInformationData((prev) => ({
										...prev,
										Employer_ESBN: e.target.value
									}));
								}}
								placeholder="eg. 54826648633"
							/>
						</div>
						<div>
							<label htmlFor="Employer_Name">
								Employer Name{" "}
								<span style={{ color: "red" }}> *</span>
							</label>
							<input
								id="Employer_Name"
								type="text"
								value={passedEmployeeData.Employer_Name}
								onChange={handleChange}
								placeholder="Don Joe"
								disabled
							/>
						</div>
					</div>
					<div className={styles3.double__inputs}>
						<div>
							<label htmlFor="PhoneNumber">
								Phone Number{" "}
								<span style={{ color: "red" }}> *</span>
							</label>
							<input
								type="text"
								id="Employer_PhoneNumber"
								value={
									employerInformationData.Employer_PhoneNumber
								}
								onChange={(e) => {
									setEmployerInformationData((prev) => ({
										...prev,
										Employer_PhoneNumber: e.target.value
									}));
								}}
								placeholder="08140589302"
							/>
						</div>
						<div>
							<label htmlFor="email">
								Email <span style={{ color: "red" }}> *</span>
							</label>
							<input
								type="email"
								id="Employer_Email"
								value={employerInformationData.Employer_Email}
								onChange={(e) => {
									setEmployerInformationData((prev) => ({
										...prev,
										Employer_Email: e.target.value
									}));
								}}
								placeholder="chukwuebuka@gmail.com"
							/>
						</div>
					</div>
					<div className={styles3.double__inputs}>
						<div>
							<label htmlFor="Sector">
								Sector <span style={{ color: "red" }}> *</span>
							</label>
							<select
								id="Sector"
								value={employerInformationData.Sector}
								onChange={(e) => {
									setEmployerInformationData((prev) => ({
										...prev,
										Sector: e.target.value
									}));
								}}
							>
								<option>Select sector</option>
								{sectors.map((sec) => (
									<option key={sec.id} value={sec.Sector}>
										{sec.Description}
									</option>
								))}
							</select>
						</div>
						<div>
							<label htmlFor="address">
								Address <span style={{ color: "red" }}> *</span>
							</label>
							<input
								id="Employer_Address"
								type="text"
								value={employerInformationData.Employer_Address}
								onChange={(e) => {
									setEmployerInformationData((prev) => ({
										...prev,
										Employer_Address: e.target.value
									}));
								}}
								placeholder="Igbo akuku"
								required
							/>
						</div>
					</div>
				</section>
			</form>
			<hr className={style.hr} />
			<div
				id="table"
				className={styles1.table__div}
				style={{ width: "70vw" }}
			>
				{loading && !tableRecords ? (
					<p style={{ textAlign: "center" }}>Loading...</p>
				) : (
					<Table
						headers={headers}
						data={tableRecords.contractors}
						full
						pageSize={20}
						onEdit={(selectedData) => {
							setEmployerInformationData((prev) => ({
								...prev,
								id: selectedData.id,
								Contractor_ESBN: selectedData.Contractor_ESBN,
								Contractor_Name: selectedData.Contractor_Name,
								Contractor_PhoneNumber:
									selectedData.Contractor_PhoneNumber,
								Contractor_Email: selectedData.Contractor_Email,
								Contractor_Address:
									selectedData.Contractor_Address,
								Sum: selectedData.Sum,
								Rate: selectedData.Rate,
								RevenueCode: selectedData.RevenueCode,
								StartDate: selectedData.StartDate,
								EndDate: selectedData.EndDate,
								PaymentDate: selectedData.PaymentDate,
								Contract_Type: selectedData.Contract_Type,
								TaxDue: selectedData.Total
							}));
							setIsEditing(true);
							setTimeout(() => {
								document
									.getElementById("contractorsForm")
									.scrollIntoView({
										behavior: "smooth",
										block: "start"
									});
							}, 100);
						}}
						onDelete={(value) => {
							deleteContractorAleadyCreated(value.id);
						}}
					/>
				)}
			</div>
			{isEditing && (
				<EditEmployerContactInfo
					contractInfo={employerInformationData}
					setAlert={setAlert}
					setIsEditing={setIsEditing}
					tariffTypes={setupOptions}
					recalculateContractorTaxDeal={recalculateContractorTaxDeal}
					checkingESBNRecord={checkingESBNRecord}
					setCheckingESBNRecord={setCheckingESBNRecord}
				/>
			)}
			{/* other details section  */}
			<EmployerOtherDetailsComponent
				employerInformationData={employerInformationData}
				setEmployerInformationData={setEmployerInformationData}
				// isEditable={!canEditEmployerOtherData}
				viewer={viewer}
			/>
			<hr className={style.hr} />
			{/* document upload section */}
			<EmployerContractDocumentComponent
				employerFiles={employerContractDocuments}
				setFilesForUpload={setFilesForUpload}
				key={employerContractDocuments.length}
			/>
			<hr className={style.hr} />
			<div className={style.colationSection}>
				<h4>
					Discount Amount:&nbsp;
					{`₦${employerInformationData.Discount}`}
				</h4>
				<h4>
					Outstanding Amount:&nbsp;
					{`₦${employerInformationData.Outstanding}`}
				</h4>
			</div>
			<hr className={style.hr} />
			<div
				style={{
					marginTop: "48px",
					maxWidth: "736px"
				}}
			>
				<h2>Total Tax: {`₦${calculatedAmount}`}</h2>
				{!isCalculated && (
					<button
						type="button"
						onClick={reCalculate}
						className={`primary__btn ${anssidStyles.create__btn}`}
						style={{
							float: "right",
							marginTop: "32px",
							marginBottom: "222px"
						}}
					>
						{calculateLoading ? "Calculating..." : "Re-Calculate"}
					</button>
				)}

				{isCalculated && (
					<button
						type="button"
						className={`primary__btn ${anssidStyles.create__btn}`}
						style={{
							float: "right",
							marginTop: "32px",
							marginBottom: "222px"
						}}
						onClick={() => sendEdit()}
						disabled={generateLoading}
					>
						{generateLoading ? "Generating Bill..." : "Update Edit"}
					</button>
				)}
			</div>
			{isSuccessModal && (
				<Modal showing={isSuccessModal}>
					<div className={PAYEstyle.approvalSuccess__badge}>
						<div className={PAYEstyle.successSection}>
							<img
								src={successCheck}
								alt="circle with a tick check inside"
							/>
							<h3>SUCCESS!</h3>
						</div>
						<button
							style={{
								background: "none",
								border: "none",
								padding: 0,
								cursor: "pointer"
							}}
							aria-label="Close"
						>
							<img
								src={closeBlackCircleBtn}
								alt="Close button Icon"
							/>
						</button>
					</div>
					<div className={PAYEstyle.modalContents}>
						<p className={PAYEstyle.success_message}>
							You have at this point, successfully Edited
							<br /> your WHT Assessment which will be <br />
							pending till it is approved or denied by the <br />
							Assessment HOD and would be sent via email.
						</p>

						<div className={PAYEstyle.modalBtn}>
							<button
								type="button"
								className={`primary__btn ${PAYEstyle.continue__btn}`}
								onClick={() => {
									setIsSuccessModal(false);
									generateBill();
								}}
							>
								{generating
									? "Generating Bill..."
									: "View Edited Bill"}
							</button>
						</div>
					</div>
				</Modal>
			)}
			{showingBill && (
				<Modal showing={showingBill}>
					<div className={style.generateBill}>
						<div className={style.billCloseButton}>
							<button
								className={style.billButton}
								onClick={() =>
									history.push("/withholding/records")
								}
							>
								<img
									src={closeBlackCircleBtn}
									alt="close button"
								/>
							</button>
						</div>
						<div
							dangerouslySetInnerHTML={{
								__html: generatedBill
							}}
						/>
					</div>
					<button
						style={{ marginTop: "24px", float: "inline-end" }}
						className={`primary__btn ${anssidStyles.create__btn}`}
						onClick={() => history.push("/withholding/records")}
					>
						Finish
					</button>
				</Modal>
			)}
		</main>
	);
};
